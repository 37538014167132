import {fork, put, take, call} from 'redux-saga/effects';
import {http} from 'services/http';

import {
    MessagesListResponseTypes,
    SendMessageRequestTypes,
    MessagesListRequestTypes,
} from 'appRedux/actions/chat/types';

import {GET_MESSAGES_LIST, getMessagesList, SEND_MESSAGE, sendMessage} from 'appRedux/actions/chat';

function* watchSendMessage() {
    while (true) {
        const {
            payload: {id, ...restPayload},
        }: IActionType<SendMessageRequestTypes> = yield take(SEND_MESSAGE.REQUEST);
        try {
            const data: MessagesListResponseTypes = yield call(http, `chat/${id}`, {
                method: 'POST',
                body: JSON.stringify({...restPayload}),
            });
            yield put(sendMessage.success(data));
        } catch (e) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            yield put(sendMessage.error(e.toString()));
        }
    }
}

function* watchGetMessagesList() {
    while (true) {
        const {
            payload: {id},
        }: IActionType<MessagesListRequestTypes> = yield take(GET_MESSAGES_LIST.REQUEST);

        try {
            const data: MessagesListResponseTypes = yield call(http, `chat/${id}`, {
                method: 'GET',
            });
            yield put(getMessagesList.success(data));
        } catch (e) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            yield put(getMessagesList.error(e.toString()));
        }
    }
}

export default [fork(watchSendMessage), fork(watchGetMessagesList)];
