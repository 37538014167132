import get from 'lodash/get';

import {ChatFileType, RequesterAllCaseItemTypes, RequesterCaseTypes} from 'appRedux/actions/requestCase/types';
import {RequesterDocumentResponseType} from 'appRedux/actions/profile/types';

import {CommonFileType, FILE_CASE, FILE_CHAT} from 'pages/agent/requesterPage/partials/helper';

import {decryptStringWithKey} from 'helpers/cryptoApiHelper';

export const getAllUserFiles = async (
    currentCase: RequesterCaseTypes | RequesterAllCaseItemTypes,
    unwrappedCaseKey: CryptoKey | null,
): Promise<CommonFileType[]> => {
    const files: RequesterDocumentResponseType[] = get(currentCase, 'files', []);
    const chatFiles: ChatFileType[] = get(currentCase, 'chatFiles', []);
    const isEncryptInfo = get(currentCase, 'isEncryptInfo', false);
    const results: CommonFileType[] = [];
    let counter = 0;
    for (let i = 0, n = files.length; i < n; i++) {
        const item = files[i];
        const fileNameArray = item.fileName ? item.fileName.split('.') : [];
        if (fileNameArray.length > 1) {
            const extension = fileNameArray[1].toLowerCase();
            results.push({
                ...item,
                index: counter++,
                type: FILE_CASE,
                title: item.description,
                extension: fileNameArray[1].toLowerCase(),
                isImage: ['jpg', 'jpeg', 'png'].includes(extension),
            });
        }
    }

    const chatResults = await getAllChatFiles(chatFiles, isEncryptInfo, unwrappedCaseKey, true);

    return results.concat(chatResults);
};

export const getAllChatFiles = async (
    chatFiles: ChatFileType[],
    isEncryptInfo: boolean,
    unwrappedCaseKey: CryptoKey | null,
    requesterFilesOnly?: boolean,
): Promise<CommonFileType[]> => {
    const results: CommonFileType[] = [];
    let counter = 0;

    for (let i = 0, n = chatFiles.length; i < n; i++) {
        const item = chatFiles[i];
        if (requesterFilesOnly && !item.sendByRequester) continue;

        const fileNameArray = item.document ? item.document.split('.') : [];
        if (fileNameArray.length > 1) {
            const extension = fileNameArray[1].toLowerCase();
            results.push({
                uuid: item.uuid,
                index: counter++,
                type: FILE_CHAT,
                title:
                    isEncryptInfo && unwrappedCaseKey
                        ? await decryptStringWithKey(item.text, unwrappedCaseKey)
                        : item.text,
                encryptPrefix: item.encryptPrefix,
                extension: fileNameArray[1].toLowerCase(),
                isImage: ['jpg', 'jpeg', 'png'].includes(extension),
                thumbLink: item.thumb,
                fileLink: item.fileLink,
            });
        }
    }
    return results;
};

export const getFormFieldFilesForGallery = (fieldFiles: RequesterDocumentResponseType[]): CommonFileType[] => {
    const results: CommonFileType[] = [];
    fieldFiles.forEach((item: RequesterDocumentResponseType, index: number) => {
        const {description, fileName} = item;
        const fileNameArray = fileName ? fileName.split('.') : [];
        const extension = fileNameArray[1].toLowerCase();
        results.push({
            ...item,
            index,
            type: FILE_CASE,
            title: description,
            extension,
            isImage: ['jpg', 'jpeg', 'png'].includes(extension),
        });
    });
    return results;
};

export const getFileData = async (url: string): Promise<string | null> => {
    const response = await fetch(url);
    const blob = await response.blob();

    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = function () {
            if (!reader.result || typeof reader.result === 'string') resolve(reader.result);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
            reject();
        };
    });
};
