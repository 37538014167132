import {requestTypeCreator} from 'helpers/index';

import {
    WorkflowItemTypes,
    WorkflowListRequestTypes,
    CreateWorkflowRequestTypes,
    UpdateWorkflowRequestTypes,
    DeleteWorkflowRequestTypes,
    WorkflowStatusItemTypes,
    WorkflowStatusListRequestTypes,
    WorkflowStatusCurrentRequestTypes,
    CreateWorkflowStatusRequestTypes,
    UpdateWorkflowStatusRequestTypes,
    DeleteWorkflowStatusRequestTypes,
    ChangePriorityWorkflowStatusRequestTypes,
    UpdateWorkflowStatusPermissionsRequestTypes,
    WorkflowStatusEventItemTypes,
    WorkflowStatusEventsListRequestTypes,
    CreateWorkflowStatusEventRequestTypes,
    UpdateWorkflowStatusEventRequestTypes,
    DeleteWorkflowStatusEventRequestTypes,
    ToggleActivityWorkflowStatusEventRequestTypes,
    BoardsListRequestTypes,
    CreateBoardRequestTypes,
    CreateBoardListRequestTypes,
    CreateBoardOverviewRequestTypes,
    UpdateBoardRequestTypes,
    UpdateBoardListRequestTypes,
    UpdateBoardOverviewRequestTypes,
    CreateBoardHasStatusRequestTypes,
    ToggleBoardRequestTypes,
    DeleteBoardRequestTypes,
    BoardItemTypes,
    CreateWorkflowTemplateRequestTypes,
    UpdateWorkflowTemplateRequestTypes,
    CreateWorkflowStatusTranslationRequestTypes,
    UpdateWorkflowStatusTranslationRequestTypes,
    UploadWorkflowAttachmentTypes,
    DeleteWorkflowAttachmentTypes,
    DownloadWorkflowAttachmentRequestTypes,
    WorkflowAttachmentDownloadResponseType,
    CreateWorkflowStatusRuleRequestTypes,
    UpdateWorkflowStatusRuleRequestTypes,
    ToggleWorkflowStatusRuleRequestTypes,
    RemoveWorkflowStatusRuleRequestTypes,
    AttachWorkflowStatusRuleApproverRequestTypes,
    RemoveWorkflowStatusRuleApproverRequestTypes,
    WorkflowGraphRequestTypes,
    WorkflowEventAttachAgentRequestTypes,
    WorkflowEventDeleteAgentRequestTypes,
    CreateWorkflowStatusTaskRequestTypes,
    UpdateWorkflowStatusTaskRequestTypes,
    ActivateWorkflowStatusTaskRequestTypes,
    DeleteWorkflowStatusTaskRequestTypes,
    CreateWorkflowTaskEmailTemplateRequestTypes,
    UpdateWorkflowTaskEmailTemplateRequestTypes,
    WorkflowStatusEmailTemplateAgentAddingRequestType,
    WorkflowStatusEmailTemplateAgentRemovingRequestType,
} from 'appRedux/actions/workflow/types';

export const GET_WORKFLOWS_LIST = requestTypeCreator('GET_WORKFLOWS_LIST');
export const CREATE_WORKFLOW = requestTypeCreator('CREATE_WORKFLOW');
export const UPDATE_WORKFLOW = requestTypeCreator('UPDATE_WORKFLOW');
export const DELETE_WORKFLOW = requestTypeCreator('DELETE_WORKFLOW');

export const GET_WORKFLOW_GRAPH = requestTypeCreator('GET_WORKFLOW_GRAPH');

export const GET_WORKFLOWS_STATUS_LIST = requestTypeCreator('GET_WORKFLOWS_STATUS_LIST');
export const CREATE_WORKFLOW_STATUS = requestTypeCreator('CREATE_WORKFLOW_STATUS');
export const UPDATE_WORKFLOW_STATUS = requestTypeCreator('UPDATE_WORKFLOW_STATUS');
export const DELETE_WORKFLOW_STATUS = requestTypeCreator('DELETE_WORKFLOW_STATUS');
export const CHANGE_WORKFLOW_STATUS_PRIORITY = requestTypeCreator('CHANGE_WORKFLOW_STATUS_PRIORITY');
export const UPDATE_WORKFLOW_STATUS_PERMISSIONS = requestTypeCreator('UPDATE_WORKFLOW_STATUS_PERMISSIONS');

export const GET_CURRENT_WORKFLOW_STATUS = requestTypeCreator('GET_CURRENT_WORKFLOW_STATUS');
export const REFRESH_CURRENT_WORKFLOW_STATUS = requestTypeCreator('REFRESH_CURRENT_WORKFLOW_STATUS');

export const GET_WORKFLOWS_STATUS_EVENTS_LIST = requestTypeCreator('GET_WORKFLOWS_STATUS_EVENTS_LIST');
export const CREATE_WORKFLOW_STATUS_EVENTS = requestTypeCreator('CREATE_WORKFLOW_STATUS_EVENTS');
export const DELETE_WORKFLOW_STATUS_EVENTS = requestTypeCreator('DELETE_WORKFLOW_STATUS_EVENTS');
export const TOGGLE_ACTIVITY_WORKFLOW_STATUS_EVENTS = requestTypeCreator('TOGGLE_ACTIVITY_WORKFLOW_STATUS_EVENTS');

export const GET_BOARDS_LIST = requestTypeCreator('GET_BOARDS_LIST');
export const CREATE_BOARD = requestTypeCreator('CREATE_BOARD');
export const CREATE_BOARD_LIST = requestTypeCreator('CREATE_BOARD_LIST');
export const CREATE_BOARD_OVERVIEW = requestTypeCreator('CREATE_BOARD_OVERVIEW');
export const UPDATE_BOARD = requestTypeCreator('UPDATE_BOARD');
export const UPDATE_BOARD_LIST_STATUS = requestTypeCreator('UPDATE_BOARD_LIST_STATUS');
export const UPDATE_BOARD_OVERVIEW = requestTypeCreator('UPDATE_BOARD_OVERVIEW');
export const DELETE_BOARD = requestTypeCreator('DELETE_BOARD');
export const CHANGE_BOARD_PRIORITY = requestTypeCreator('CHANGE_BOARD_PRIORITY');
export const TOGGLE_BOARD_STATUS = requestTypeCreator('TOGGLE_BOARD_STATUS');

export const CREATE_BOARD_HAS_STATUS = requestTypeCreator('CREATE_BOARD_HAS_STATUS');
export const TOGGLE_BOARD_HAS_STATUS = requestTypeCreator('TOGGLE_BOARD_HAS_STATUS');

export const CREATE_WORKFLOW_TEMPLATE = requestTypeCreator('CREATE_WORKFLOW_TEMPLATE');
export const UPDATE_WORKFLOW_TEMPLATE = requestTypeCreator('UPDATE_WORKFLOW_TEMPLATE');

export const CREATE_WORKFLOW_STATUS_TRANSLATION = requestTypeCreator('CREATE_WORKFLOW_STATUS_TRANSLATION');
export const UPDATE_WORKFLOW_STATUS_TRANSLATION = requestTypeCreator('UPDATE_WORKFLOW_STATUS_TRANSLATION');

export const UPLOAD_WORKFLOW_ATTACHMENT = requestTypeCreator('UPLOAD_WORKFLOW_ATTACHMENT');
export const DELETE_WORKFLOW_ATTACHMENT = requestTypeCreator('DELETE_WORKFLOW_ATTACHMENT');
export const DOWNLOAD_WORKFLOW_ATTACHMENT = requestTypeCreator('DOWNLOAD_WORKFLOW_ATTACHMENT');

export const CREATE_WORKFLOW_STATUS_RULE = requestTypeCreator('CREATE_WORKFLOW_STATUS_RULE');
export const UPDATE_WORKFLOW_STATUS_RULE = requestTypeCreator('UPDATE_WORKFLOW_STATUS_RULE');
export const TOGGLE_WORKFLOW_STATUS_RULE = requestTypeCreator('TOGGLE_WORKFLOW_STATUS_RULE');
export const DELETE_WORKFLOW_STATUS_RULE = requestTypeCreator('DELETE_WORKFLOW_STATUS_RULE');
export const ATTACH_WORKFLOW_RULE_APPROVER = requestTypeCreator('ATTACH_WORKFLOW_RULE_APPROVER');
export const DELETE_WORKFLOW_RULE_APPROVER = requestTypeCreator('DELETE_WORKFLOW_RULE_APPROVER');

export const ATTACH_WORKFLOW_EVENT_AGENT = requestTypeCreator('ATTACH_WORKFLOW_EVENT_AGENT');
export const DELETE_WORKFLOW_EVENT_AGENT = requestTypeCreator('DELETE_WORKFLOW_EVENT_AGENT');

export const CREATE_WORKFLOW_STATUS_TASK = requestTypeCreator('CREATE_WORKFLOW_STATUS_TASK');
export const UPDATE_WORKFLOW_STATUS_TASK = requestTypeCreator('UPDATE_WORKFLOW_STATUS_TASK');
export const ACTIVATE_WORKFLOW_STATUS_TASK = requestTypeCreator('ACTIVATE_WORKFLOW_STATUS_TASK');
export const DELETE_WORKFLOW_STATUS_TASK = requestTypeCreator('DELETE_WORKFLOW_STATUS_TASK');
export const CREATE_WORKFLOW_TASK_EMAIL_TEMPLATE = requestTypeCreator('CREATE_WORKFLOW_TASK_EMAIL_TEMPLATE');
export const UPDATE_WORKFLOW_TASK_EMAIL_TEMPLATE = requestTypeCreator('UPDATE_WORKFLOW_TASK_EMAIL_TEMPLATE');

export const ADD_WORKFLOW_TASK_EMAIL_TEMPLATE_AGENT = requestTypeCreator('ADD_WORKFLOW_TASK_EMAIL_TEMPLATE_AGENT');
export const REMOVE_WORKFLOW_TASK_EMAIL_TEMPLATE_AGENT = requestTypeCreator(
    'REMOVE_WORKFLOW_TASK_EMAIL_TEMPLATE_AGENT',
);
export const SET_CURRENT_BOARD_ID = 'SET_CURRENT_BOARD_ID';

export const getWorkflowLists = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GET_WORKFLOWS_LIST.ERROR,
    }),
    request: (payload: WorkflowListRequestTypes): IActionType<WorkflowListRequestTypes> => ({
        payload,
        type: GET_WORKFLOWS_LIST.REQUEST,
    }),
    success: (payload: WorkflowItemTypes[]): IActionType<WorkflowItemTypes[]> => ({
        payload,
        type: GET_WORKFLOWS_LIST.SUCCESS,
    }),
};

export const createWorkflow = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: CREATE_WORKFLOW.ERROR,
    }),
    request: (payload: CreateWorkflowRequestTypes): IActionType<CreateWorkflowRequestTypes> => ({
        payload,
        type: CREATE_WORKFLOW.REQUEST,
    }),
    success: (payload: WorkflowItemTypes[]): IActionType<WorkflowItemTypes[]> => ({
        payload,
        type: CREATE_WORKFLOW.SUCCESS,
    }),
};

export const updateWorkflow = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: UPDATE_WORKFLOW.ERROR,
    }),
    request: (payload: UpdateWorkflowRequestTypes): IActionType<UpdateWorkflowRequestTypes> => ({
        payload,
        type: UPDATE_WORKFLOW.REQUEST,
    }),
    success: (payload: WorkflowItemTypes[]): IActionType<WorkflowItemTypes[]> => ({
        payload,
        type: UPDATE_WORKFLOW.SUCCESS,
    }),
};

export const deleteWorkflow = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: DELETE_WORKFLOW.ERROR,
    }),
    request: (payload: DeleteWorkflowRequestTypes): IActionType<DeleteWorkflowRequestTypes> => ({
        payload,
        type: DELETE_WORKFLOW.REQUEST,
    }),
    success: (payload: WorkflowItemTypes[]): IActionType<WorkflowItemTypes[]> => ({
        payload,
        type: DELETE_WORKFLOW.SUCCESS,
    }),
};

export const getWorkflowGraph = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GET_WORKFLOW_GRAPH.ERROR,
    }),
    request: (payload: WorkflowGraphRequestTypes): IActionType<WorkflowGraphRequestTypes> => ({
        payload,
        type: GET_WORKFLOW_GRAPH.REQUEST,
    }),
    success: (payload: string): IActionType<string> => ({
        payload,
        type: GET_WORKFLOW_GRAPH.SUCCESS,
    }),
};

export const getWorkflowStatusesLists = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GET_WORKFLOWS_STATUS_LIST.ERROR,
    }),
    request: (payload: WorkflowStatusListRequestTypes): IActionType<WorkflowStatusListRequestTypes> => ({
        payload,
        type: GET_WORKFLOWS_STATUS_LIST.REQUEST,
    }),
    success: (payload: WorkflowStatusItemTypes[]): IActionType<WorkflowStatusItemTypes[]> => ({
        payload,
        type: GET_WORKFLOWS_STATUS_LIST.SUCCESS,
    }),
};

export const getCurrentWorkflowStatus = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GET_CURRENT_WORKFLOW_STATUS.ERROR,
    }),
    request: (payload: WorkflowStatusCurrentRequestTypes): IActionType<WorkflowStatusCurrentRequestTypes> => ({
        payload,
        type: GET_CURRENT_WORKFLOW_STATUS.REQUEST,
    }),
    success: (payload: WorkflowStatusItemTypes): IActionType<WorkflowStatusItemTypes> => ({
        payload,
        type: GET_CURRENT_WORKFLOW_STATUS.SUCCESS,
    }),
};

export const createWorkflowStatus = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: CREATE_WORKFLOW_STATUS.ERROR,
    }),
    request: (payload: CreateWorkflowStatusRequestTypes): IActionType<CreateWorkflowStatusRequestTypes> => ({
        payload,
        type: CREATE_WORKFLOW_STATUS.REQUEST,
    }),
    success: (payload: WorkflowStatusItemTypes[]): IActionType<WorkflowStatusItemTypes[]> => ({
        payload,
        type: CREATE_WORKFLOW_STATUS.SUCCESS,
    }),
};

export const updateWorkflowStatus = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: UPDATE_WORKFLOW_STATUS.ERROR,
    }),
    request: (payload: UpdateWorkflowStatusRequestTypes): IActionType<UpdateWorkflowStatusRequestTypes> => ({
        payload,
        type: UPDATE_WORKFLOW_STATUS.REQUEST,
    }),
    success: (payload: WorkflowStatusItemTypes[]): IActionType<WorkflowStatusItemTypes[]> => ({
        payload,
        type: UPDATE_WORKFLOW_STATUS.SUCCESS,
    }),
};

export const deleteWorkflowStatus = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: DELETE_WORKFLOW_STATUS.ERROR,
    }),
    request: (payload: DeleteWorkflowStatusRequestTypes): IActionType<DeleteWorkflowStatusRequestTypes> => ({
        payload,
        type: DELETE_WORKFLOW_STATUS.REQUEST,
    }),
    success: (payload: WorkflowStatusItemTypes[]): IActionType<WorkflowStatusItemTypes[]> => ({
        payload,
        type: DELETE_WORKFLOW_STATUS.SUCCESS,
    }),
};

export const changeWorkflowStatusPriority = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: CHANGE_WORKFLOW_STATUS_PRIORITY.ERROR,
    }),
    request: (
        payload: ChangePriorityWorkflowStatusRequestTypes,
    ): IActionType<ChangePriorityWorkflowStatusRequestTypes> => ({
        payload,
        type: CHANGE_WORKFLOW_STATUS_PRIORITY.REQUEST,
    }),
    success: (payload: WorkflowStatusItemTypes[]): IActionType<WorkflowStatusItemTypes[]> => ({
        payload,
        type: CHANGE_WORKFLOW_STATUS_PRIORITY.SUCCESS,
    }),
};

export const updateWorkflowStatusPermissions = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: UPDATE_WORKFLOW_STATUS_PERMISSIONS.ERROR,
    }),
    request: (
        payload: UpdateWorkflowStatusPermissionsRequestTypes,
    ): IActionType<UpdateWorkflowStatusPermissionsRequestTypes> => ({
        payload,
        type: UPDATE_WORKFLOW_STATUS_PERMISSIONS.REQUEST,
    }),
    success: (payload: WorkflowStatusItemTypes[]): IActionType<WorkflowStatusItemTypes[]> => ({
        payload,
        type: UPDATE_WORKFLOW_STATUS_PERMISSIONS.SUCCESS,
    }),
};

export const getWorkflowStatusEventsLists = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GET_WORKFLOWS_STATUS_EVENTS_LIST.ERROR,
    }),
    request: (payload: WorkflowStatusEventsListRequestTypes): IActionType<WorkflowStatusEventsListRequestTypes> => ({
        payload,
        type: GET_WORKFLOWS_STATUS_EVENTS_LIST.REQUEST,
    }),
    success: (payload: WorkflowStatusEventItemTypes[]): IActionType<WorkflowStatusEventItemTypes[]> => ({
        payload,
        type: GET_WORKFLOWS_STATUS_EVENTS_LIST.SUCCESS,
    }),
};

export const createWorkflowStatusEvent = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: CREATE_WORKFLOW_STATUS_EVENTS.ERROR,
    }),
    request: (payload: CreateWorkflowStatusEventRequestTypes): IActionType<CreateWorkflowStatusEventRequestTypes> => ({
        payload,
        type: CREATE_WORKFLOW_STATUS_EVENTS.REQUEST,
    }),
    success: (payload: WorkflowStatusEventItemTypes[]): IActionType<WorkflowStatusEventItemTypes[]> => ({
        payload,
        type: CREATE_WORKFLOW_STATUS_EVENTS.SUCCESS,
    }),
};

export const deleteWorkflowStatusEvent = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: DELETE_WORKFLOW_STATUS_EVENTS.ERROR,
    }),
    request: (payload: DeleteWorkflowStatusEventRequestTypes): IActionType<DeleteWorkflowStatusEventRequestTypes> => ({
        payload,
        type: DELETE_WORKFLOW_STATUS_EVENTS.REQUEST,
    }),
    success: (payload: WorkflowStatusEventItemTypes[]): IActionType<WorkflowStatusEventItemTypes[]> => ({
        payload,
        type: DELETE_WORKFLOW_STATUS_EVENTS.SUCCESS,
    }),
};

export const toggleActivityWorkflowStatusEvent = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: TOGGLE_ACTIVITY_WORKFLOW_STATUS_EVENTS.ERROR,
    }),
    request: (
        payload: ToggleActivityWorkflowStatusEventRequestTypes,
    ): IActionType<ToggleActivityWorkflowStatusEventRequestTypes> => ({
        payload,
        type: TOGGLE_ACTIVITY_WORKFLOW_STATUS_EVENTS.REQUEST,
    }),
    success: (payload: WorkflowStatusEventItemTypes[]): IActionType<WorkflowStatusEventItemTypes[]> => ({
        payload,
        type: TOGGLE_ACTIVITY_WORKFLOW_STATUS_EVENTS.SUCCESS,
    }),
};

export const getBoardsLists = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GET_BOARDS_LIST.ERROR,
    }),
    request: (payload: BoardsListRequestTypes): IActionType<BoardsListRequestTypes> => ({
        payload,
        type: GET_BOARDS_LIST.REQUEST,
    }),
    success: (payload: BoardItemTypes[]): IActionType<BoardItemTypes[]> => ({
        payload,
        type: GET_BOARDS_LIST.SUCCESS,
    }),
};

export const createBoard = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: CREATE_BOARD.ERROR,
    }),
    request: (payload: CreateBoardRequestTypes): IActionType<CreateBoardRequestTypes> => ({
        payload,
        type: CREATE_BOARD.REQUEST,
    }),
    success: (payload: BoardItemTypes[]): IActionType<BoardItemTypes[]> => ({
        payload,
        type: CREATE_BOARD.SUCCESS,
    }),
};

export const createBoardList = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: CREATE_BOARD_LIST.ERROR,
    }),
    request: (payload: CreateBoardListRequestTypes): IActionType<CreateBoardListRequestTypes> => ({
        payload,
        type: CREATE_BOARD_LIST.REQUEST,
    }),
    success: (payload: BoardItemTypes[]): IActionType<BoardItemTypes[]> => ({
        payload,
        type: CREATE_BOARD_LIST.SUCCESS,
    }),
};

export const createBoardOverview = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: CREATE_BOARD_OVERVIEW.ERROR,
    }),
    request: (payload: CreateBoardOverviewRequestTypes): IActionType<CreateBoardOverviewRequestTypes> => ({
        payload,
        type: CREATE_BOARD_OVERVIEW.REQUEST,
    }),
    success: (payload: BoardItemTypes[]): IActionType<BoardItemTypes[]> => ({
        payload,
        type: CREATE_BOARD_OVERVIEW.SUCCESS,
    }),
};

export const updateBoardListStatus = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: UPDATE_BOARD_LIST_STATUS.ERROR,
    }),
    request: (payload: UpdateBoardListRequestTypes): IActionType<UpdateBoardListRequestTypes> => ({
        payload,
        type: UPDATE_BOARD_LIST_STATUS.REQUEST,
    }),
    success: (payload: BoardItemTypes[]): IActionType<BoardItemTypes[]> => ({
        payload,
        type: UPDATE_BOARD_LIST_STATUS.SUCCESS,
    }),
};

export const updateBoardOverview = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: UPDATE_BOARD_OVERVIEW.ERROR,
    }),
    request: (payload: UpdateBoardOverviewRequestTypes): IActionType<UpdateBoardOverviewRequestTypes> => ({
        payload,
        type: UPDATE_BOARD_OVERVIEW.REQUEST,
    }),
    success: (payload: BoardItemTypes[]): IActionType<BoardItemTypes[]> => ({
        payload,
        type: UPDATE_BOARD_OVERVIEW.SUCCESS,
    }),
};

export const updateBoard = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: UPDATE_BOARD.ERROR,
    }),
    request: (payload: UpdateBoardRequestTypes): IActionType<UpdateBoardRequestTypes> => ({
        payload,
        type: UPDATE_BOARD.REQUEST,
    }),
    success: (payload: BoardItemTypes[]): IActionType<BoardItemTypes[]> => ({
        payload,
        type: UPDATE_BOARD.SUCCESS,
    }),
};

export const deleteBoard = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: DELETE_BOARD.ERROR,
    }),
    request: (payload: DeleteBoardRequestTypes): IActionType<DeleteBoardRequestTypes> => ({
        payload,
        type: DELETE_BOARD.REQUEST,
    }),
    success: (payload: BoardItemTypes[]): IActionType<BoardItemTypes[]> => ({
        payload,
        type: DELETE_BOARD.SUCCESS,
    }),
};

export const changeBoardPriority = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: CHANGE_BOARD_PRIORITY.ERROR,
    }),
    request: (payload: DeleteBoardRequestTypes): IActionType<DeleteBoardRequestTypes> => ({
        payload,
        type: CHANGE_BOARD_PRIORITY.REQUEST,
    }),
    success: (payload: BoardItemTypes[]): IActionType<BoardItemTypes[]> => ({
        payload,
        type: CHANGE_BOARD_PRIORITY.SUCCESS,
    }),
};

export const toggleBoardStatus = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: TOGGLE_BOARD_STATUS.ERROR,
    }),
    request: (payload: ToggleBoardRequestTypes): IActionType<ToggleBoardRequestTypes> => ({
        payload,
        type: TOGGLE_BOARD_STATUS.REQUEST,
    }),
    success: (payload: BoardItemTypes[]): IActionType<BoardItemTypes[]> => ({
        payload,
        type: TOGGLE_BOARD_STATUS.SUCCESS,
    }),
};

export const createBoardHasStatus = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: CREATE_BOARD_HAS_STATUS.ERROR,
    }),
    request: (payload: CreateBoardHasStatusRequestTypes): IActionType<CreateBoardHasStatusRequestTypes> => ({
        payload,
        type: CREATE_BOARD_HAS_STATUS.REQUEST,
    }),
    success: (payload: BoardItemTypes[]): IActionType<BoardItemTypes[]> => ({
        payload,
        type: CREATE_BOARD_HAS_STATUS.SUCCESS,
    }),
};

export const toggleBoardHasStatus = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: TOGGLE_BOARD_HAS_STATUS.ERROR,
    }),
    request: (payload: ToggleBoardRequestTypes): IActionType<ToggleBoardRequestTypes> => ({
        payload,
        type: TOGGLE_BOARD_HAS_STATUS.REQUEST,
    }),
    success: (payload: BoardItemTypes[]): IActionType<BoardItemTypes[]> => ({
        payload,
        type: TOGGLE_BOARD_HAS_STATUS.SUCCESS,
    }),
};

export const createWorkflowTemplate = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: CREATE_WORKFLOW_TEMPLATE.ERROR,
    }),
    request: (payload: CreateWorkflowTemplateRequestTypes): IActionType<CreateWorkflowTemplateRequestTypes> => ({
        payload,
        type: CREATE_WORKFLOW_TEMPLATE.REQUEST,
    }),
    success: (payload: WorkflowStatusEventItemTypes[]): IActionType<WorkflowStatusEventItemTypes[]> => ({
        payload,
        type: CREATE_WORKFLOW_TEMPLATE.SUCCESS,
    }),
};

export const updateWorkflowTemplate = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: UPDATE_WORKFLOW_TEMPLATE.ERROR,
    }),
    request: (payload: UpdateWorkflowTemplateRequestTypes): IActionType<UpdateWorkflowTemplateRequestTypes> => ({
        payload,
        type: UPDATE_WORKFLOW_TEMPLATE.REQUEST,
    }),
    success: (payload: WorkflowStatusEventItemTypes[]): IActionType<WorkflowStatusEventItemTypes[]> => ({
        payload,
        type: UPDATE_WORKFLOW_TEMPLATE.SUCCESS,
    }),
};

export const createWorkflowStatusTranslation = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: CREATE_WORKFLOW_STATUS_TRANSLATION.ERROR,
    }),
    request: (
        payload: CreateWorkflowStatusTranslationRequestTypes,
    ): IActionType<CreateWorkflowStatusTranslationRequestTypes> => ({
        payload,
        type: CREATE_WORKFLOW_STATUS_TRANSLATION.REQUEST,
    }),
    success: (payload: WorkflowStatusItemTypes[]): IActionType<WorkflowStatusItemTypes[]> => ({
        payload,
        type: CREATE_WORKFLOW_STATUS_TRANSLATION.SUCCESS,
    }),
};

export const updateWorkflowStatusTranslation = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: UPDATE_WORKFLOW_STATUS_TRANSLATION.ERROR,
    }),
    request: (
        payload: UpdateWorkflowStatusTranslationRequestTypes,
    ): IActionType<UpdateWorkflowStatusTranslationRequestTypes> => ({
        payload,
        type: UPDATE_WORKFLOW_STATUS_TRANSLATION.REQUEST,
    }),
    success: (payload: WorkflowStatusItemTypes[]): IActionType<WorkflowStatusItemTypes[]> => ({
        payload,
        type: UPDATE_WORKFLOW_STATUS_TRANSLATION.SUCCESS,
    }),
};

export const uploadWorkflowAttachment = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: UPLOAD_WORKFLOW_ATTACHMENT.ERROR,
    }),
    request: (payload: UploadWorkflowAttachmentTypes): IActionType<UploadWorkflowAttachmentTypes> => ({
        payload,
        type: UPLOAD_WORKFLOW_ATTACHMENT.REQUEST,
    }),
    success: (payload: WorkflowStatusEventItemTypes[]): IActionType<WorkflowStatusEventItemTypes[]> => ({
        payload,
        type: UPLOAD_WORKFLOW_ATTACHMENT.SUCCESS,
    }),
};

export const deleteWorkflowAttachment = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: DELETE_WORKFLOW_ATTACHMENT.ERROR,
    }),
    request: (payload: DeleteWorkflowAttachmentTypes): IActionType<DeleteWorkflowAttachmentTypes> => ({
        payload,
        type: DELETE_WORKFLOW_ATTACHMENT.REQUEST,
    }),
    success: (payload: WorkflowStatusEventItemTypes[]): IActionType<WorkflowStatusEventItemTypes[]> => ({
        payload,
        type: DELETE_WORKFLOW_ATTACHMENT.SUCCESS,
    }),
};

export const downloadWorkflowAttachment = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: DOWNLOAD_WORKFLOW_ATTACHMENT.ERROR,
    }),
    request: (
        payload: DownloadWorkflowAttachmentRequestTypes,
    ): IActionType<DownloadWorkflowAttachmentRequestTypes> => ({
        payload,
        type: DOWNLOAD_WORKFLOW_ATTACHMENT.REQUEST,
    }),
    success: (
        payload: WorkflowAttachmentDownloadResponseType,
    ): IActionType<WorkflowAttachmentDownloadResponseType> => ({
        payload,
        type: DOWNLOAD_WORKFLOW_ATTACHMENT.SUCCESS,
    }),
};

export const createWorkflowStatusRule = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: CREATE_WORKFLOW_STATUS_RULE.ERROR,
    }),
    request: (payload: CreateWorkflowStatusRuleRequestTypes): IActionType<CreateWorkflowStatusRuleRequestTypes> => ({
        payload,
        type: CREATE_WORKFLOW_STATUS_RULE.REQUEST,
    }),
    success: (payload: WorkflowStatusItemTypes[]): IActionType<WorkflowStatusItemTypes[]> => ({
        payload,
        type: CREATE_WORKFLOW_STATUS_RULE.SUCCESS,
    }),
};

export const updateWorkflowStatusRule = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: UPDATE_WORKFLOW_STATUS_RULE.ERROR,
    }),
    request: (payload: UpdateWorkflowStatusRuleRequestTypes): IActionType<UpdateWorkflowStatusRuleRequestTypes> => ({
        payload,
        type: UPDATE_WORKFLOW_STATUS_RULE.REQUEST,
    }),
    success: (payload: WorkflowStatusItemTypes[]): IActionType<WorkflowStatusItemTypes[]> => ({
        payload,
        type: UPDATE_WORKFLOW_STATUS_RULE.SUCCESS,
    }),
};

export const toggleWorkflowStatusRule = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: TOGGLE_WORKFLOW_STATUS_RULE.ERROR,
    }),
    request: (payload: ToggleWorkflowStatusRuleRequestTypes): IActionType<ToggleWorkflowStatusRuleRequestTypes> => ({
        payload,
        type: TOGGLE_WORKFLOW_STATUS_RULE.REQUEST,
    }),
    success: (payload: WorkflowStatusItemTypes[]): IActionType<WorkflowStatusItemTypes[]> => ({
        payload,
        type: TOGGLE_WORKFLOW_STATUS_RULE.SUCCESS,
    }),
};

export const deleteWorkflowStatusRule = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: DELETE_WORKFLOW_STATUS_RULE.ERROR,
    }),
    request: (payload: RemoveWorkflowStatusRuleRequestTypes): IActionType<RemoveWorkflowStatusRuleRequestTypes> => ({
        payload,
        type: DELETE_WORKFLOW_STATUS_RULE.REQUEST,
    }),
    success: (payload: WorkflowStatusItemTypes[]): IActionType<WorkflowStatusItemTypes[]> => ({
        payload,
        type: DELETE_WORKFLOW_STATUS_RULE.SUCCESS,
    }),
};

export const attachWorkflowStatusRuleApprover = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: ATTACH_WORKFLOW_RULE_APPROVER.ERROR,
    }),
    request: (
        payload: AttachWorkflowStatusRuleApproverRequestTypes,
    ): IActionType<AttachWorkflowStatusRuleApproverRequestTypes> => ({
        payload,
        type: ATTACH_WORKFLOW_RULE_APPROVER.REQUEST,
    }),
    success: (payload: WorkflowStatusItemTypes[]): IActionType<WorkflowStatusItemTypes[]> => ({
        payload,
        type: ATTACH_WORKFLOW_RULE_APPROVER.SUCCESS,
    }),
};

export const deleteWorkflowStatusRuleApprover = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: DELETE_WORKFLOW_RULE_APPROVER.ERROR,
    }),
    request: (
        payload: RemoveWorkflowStatusRuleApproverRequestTypes,
    ): IActionType<RemoveWorkflowStatusRuleApproverRequestTypes> => ({
        payload,
        type: DELETE_WORKFLOW_RULE_APPROVER.REQUEST,
    }),
    success: (payload: WorkflowStatusItemTypes[]): IActionType<WorkflowStatusItemTypes[]> => ({
        payload,
        type: DELETE_WORKFLOW_RULE_APPROVER.SUCCESS,
    }),
};

export const attachWorkflowEventAgent = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: ATTACH_WORKFLOW_EVENT_AGENT.ERROR,
    }),
    request: (payload: WorkflowEventAttachAgentRequestTypes): IActionType<WorkflowEventAttachAgentRequestTypes> => ({
        payload,
        type: ATTACH_WORKFLOW_EVENT_AGENT.REQUEST,
    }),
    success: (payload: WorkflowStatusEventItemTypes[]): IActionType<WorkflowStatusEventItemTypes[]> => ({
        payload,
        type: ATTACH_WORKFLOW_EVENT_AGENT.SUCCESS,
    }),
};

export const deleteWorkflowEventAgent = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: DELETE_WORKFLOW_EVENT_AGENT.ERROR,
    }),
    request: (payload: WorkflowEventDeleteAgentRequestTypes): IActionType<WorkflowEventDeleteAgentRequestTypes> => ({
        payload,
        type: DELETE_WORKFLOW_EVENT_AGENT.REQUEST,
    }),
    success: (payload: WorkflowStatusEventItemTypes[]): IActionType<WorkflowStatusEventItemTypes[]> => ({
        payload,
        type: DELETE_WORKFLOW_EVENT_AGENT.SUCCESS,
    }),
};

export const createWorkflowStatusTask = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: CREATE_WORKFLOW_STATUS_TASK.ERROR,
    }),
    request: (payload: CreateWorkflowStatusTaskRequestTypes): IActionType<CreateWorkflowStatusTaskRequestTypes> => ({
        payload,
        type: CREATE_WORKFLOW_STATUS_TASK.REQUEST,
    }),
    success: (payload: WorkflowStatusItemTypes[]): IActionType<WorkflowStatusItemTypes[]> => ({
        payload,
        type: CREATE_WORKFLOW_STATUS_TASK.SUCCESS,
    }),
};

export const updateWorkflowStatusTask = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: UPDATE_WORKFLOW_STATUS_TASK.ERROR,
    }),
    request: (payload: UpdateWorkflowStatusTaskRequestTypes): IActionType<UpdateWorkflowStatusTaskRequestTypes> => ({
        payload,
        type: UPDATE_WORKFLOW_STATUS_TASK.REQUEST,
    }),
    success: (payload: WorkflowStatusItemTypes[]): IActionType<WorkflowStatusItemTypes[]> => ({
        payload,
        type: UPDATE_WORKFLOW_STATUS_TASK.SUCCESS,
    }),
};

export const activateWorkflowStatusTask = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: ACTIVATE_WORKFLOW_STATUS_TASK.ERROR,
    }),
    request: (
        payload: ActivateWorkflowStatusTaskRequestTypes,
    ): IActionType<ActivateWorkflowStatusTaskRequestTypes> => ({
        payload,
        type: ACTIVATE_WORKFLOW_STATUS_TASK.REQUEST,
    }),
    success: (payload: WorkflowStatusItemTypes[]): IActionType<WorkflowStatusItemTypes[]> => ({
        payload,
        type: ACTIVATE_WORKFLOW_STATUS_TASK.SUCCESS,
    }),
};

export const deleteWorkflowStatusTask = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: DELETE_WORKFLOW_STATUS_TASK.ERROR,
    }),
    request: (payload: DeleteWorkflowStatusTaskRequestTypes): IActionType<DeleteWorkflowStatusTaskRequestTypes> => ({
        payload,
        type: DELETE_WORKFLOW_STATUS_TASK.REQUEST,
    }),
    success: (payload: WorkflowStatusItemTypes[]): IActionType<WorkflowStatusItemTypes[]> => ({
        payload,
        type: DELETE_WORKFLOW_STATUS_TASK.SUCCESS,
    }),
};

export const createWorkflowTaskEmailTemplate = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: CREATE_WORKFLOW_TASK_EMAIL_TEMPLATE.ERROR,
    }),
    request: (
        payload: CreateWorkflowTaskEmailTemplateRequestTypes,
    ): IActionType<CreateWorkflowTaskEmailTemplateRequestTypes> => ({
        payload,
        type: CREATE_WORKFLOW_TASK_EMAIL_TEMPLATE.REQUEST,
    }),
    success: (payload: WorkflowStatusItemTypes[]): IActionType<WorkflowStatusItemTypes[]> => ({
        payload,
        type: CREATE_WORKFLOW_TASK_EMAIL_TEMPLATE.SUCCESS,
    }),
};

export const updateWorkflowTaskEmailTemplate = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: UPDATE_WORKFLOW_TASK_EMAIL_TEMPLATE.ERROR,
    }),
    request: (
        payload: UpdateWorkflowTaskEmailTemplateRequestTypes,
    ): IActionType<UpdateWorkflowTaskEmailTemplateRequestTypes> => ({
        payload,
        type: UPDATE_WORKFLOW_TASK_EMAIL_TEMPLATE.REQUEST,
    }),
    success: (payload: WorkflowStatusItemTypes[]): IActionType<WorkflowStatusItemTypes[]> => ({
        payload,
        type: UPDATE_WORKFLOW_TASK_EMAIL_TEMPLATE.SUCCESS,
    }),
};

export const addWorkflowStatusEmailTemplateAgent = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: ADD_WORKFLOW_TASK_EMAIL_TEMPLATE_AGENT.ERROR,
    }),
    request: (
        payload: WorkflowStatusEmailTemplateAgentAddingRequestType,
    ): IActionType<WorkflowStatusEmailTemplateAgentAddingRequestType> => ({
        payload,
        type: ADD_WORKFLOW_TASK_EMAIL_TEMPLATE_AGENT.REQUEST,
    }),
    success: (payload: WorkflowStatusItemTypes[]): IActionType<WorkflowStatusItemTypes[]> => ({
        payload,
        type: ADD_WORKFLOW_TASK_EMAIL_TEMPLATE_AGENT.SUCCESS,
    }),
};

export const removeWorkflowStatusEmailTemplateAgent = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: REMOVE_WORKFLOW_TASK_EMAIL_TEMPLATE_AGENT.ERROR,
    }),
    request: (
        payload: WorkflowStatusEmailTemplateAgentRemovingRequestType,
    ): IActionType<WorkflowStatusEmailTemplateAgentRemovingRequestType> => ({
        payload,
        type: REMOVE_WORKFLOW_TASK_EMAIL_TEMPLATE_AGENT.REQUEST,
    }),
    success: (payload: WorkflowStatusItemTypes[]): IActionType<WorkflowStatusItemTypes[]> => ({
        payload,
        type: REMOVE_WORKFLOW_TASK_EMAIL_TEMPLATE_AGENT.SUCCESS,
    }),
};
