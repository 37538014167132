import React, {FC, useContext} from 'react';
import {View, Text} from '@react-pdf/renderer';

import {FormFieldTypes} from 'appRedux/actions/forms/types';
import {RequesterDocumentResponseType} from 'appRedux/actions/profile/types';

import {pdfStyles} from 'pages/agent/pdfExport/pdfPartials/PdfDocumentStyles';
import {ImageComponent} from 'pages/agent/pdfExport/pdfPartials/AttachmentPdfContent';
import {PdfDocumentContext} from 'pages/agent/pdfExport/pdfPartials/pdfDocumentContext';

import {getFieldKeyword, getFormTranslatedLabel} from 'helpers/translationsHelper';

interface FileFieldPdfContentType {
    formId: number;
    field: FormFieldTypes;
}

const FileFieldPdfContent: FC<FileFieldPdfContentType> = ({formId, field}) => {
    const {
        translations,
        preview,
        currentCase: {files},
    } = useContext(PdfDocumentContext);

    const {label, id} = field;

    const keyword = getFieldKeyword(formId, id, 'label');

    const uploadedFiles = files.filter(
        (file: RequesterDocumentResponseType) => file.formField === `form-${formId}-field-${field.id}`,
    );

    return (
        <View style={pdfStyles.field}>
            <View style={pdfStyles.fieldTitle}>
                <Text>{`${getFormTranslatedLabel(translations, keyword, label)}:`}</Text>
            </View>
            <View style={pdfStyles.fieldValue}>
                {uploadedFiles.length ? (
                    uploadedFiles.map(file => {
                        return (
                            <View style={pdfStyles.fieldImage} key={file.uuid}>
                                <ImageComponent preview={preview} src={file.thumbLink || ''} />
                                <Text>{file.description}</Text>
                            </View>
                        );
                    })
                ) : (
                    <Text>{' - '}</Text>
                )}
            </View>
        </View>
    );
};

export default FileFieldPdfContent;
