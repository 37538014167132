import React, {FC, useCallback, useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams, useSearchParams} from 'react-router-dom';
import get from 'lodash/get';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {FORM_BY_CASE_INFORMATION} from 'appRedux/actions/forms';
import {GET_REQUESTER_CASE} from 'appRedux/actions/requestCase';
import {FormPageTypes, FormSectionTypes} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';

import {RouteContext} from 'contexts/route/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';

import PageSwitcher from 'pages/agent/requesterPage/partials/PageSwitcher';
import SectionContent from 'pages/agent/requesterPage/sections/SectionContent';
import {getInitialFieldValue} from 'pages/client/form/helper';
import {
    FORM_FIELD_IMAGE_CHECK,
    FORM_FIELD_IMAGE_RADIO,
    OPTIONS_SEPARATOR,
    RELATED_SECTIONS_SEPARATOR,
} from 'pages/admin/updateForm/partials/FormStructure/helper';

import {PARAMETER_PAGE_ID} from 'config/index';

interface RequesterInfoFormBuilderType {
    toggleIsChatOpened: () => void;
}

const RequesterInfoFormBuilder: FC<RequesterInfoFormBuilderType> = ({toggleIsChatOpened}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const {formId, caseId} = useParams();
    const [searchParams] = useSearchParams();

    const currentPageId = searchParams.get(PARAMETER_PAGE_ID);

    const {formPage, setFormPage} = useContext(RouteContext);

    const [isOutdatedInfo, setIsOutdatedInfo] = useState<boolean>(false);

    const toggleOutdatedInfo = () => {
        setIsOutdatedInfo(previous => !previous);
    };

    const reloadPage = () => {
        window.location.reload();
    };

    const getFormByCaseInformation = useCallback(
        data => dispatch({type: FORM_BY_CASE_INFORMATION.REQUEST, payload: data}),
        [dispatch],
    );

    const getRequestCaseInformation = useCallback(
        data => dispatch({type: GET_REQUESTER_CASE.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        admin: {formInfo},
        requestCase: {
            currentCase: {result},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const pages: FormPageTypes[] = get(formInfo, 'pages', []);
    const page: FormPageTypes | null = get(pages, formPage - 1, null);

    useEffect(() => {
        getFormByCaseInformation({id: caseId});
        getRequestCaseInformation({id: caseId});
    }, []);

    useEffect(() => {
        if (currentPageId) {
            for (let i = 0, n = pages.length; i < n; i++) {
                if (Number(pages[i].id) === Number(currentPageId)) {
                    setFormPage(i + 1);
                    break;
                }
            }
        }
    }, [currentPageId]);

    if (!page) return null;

    const sections: FormSectionTypes[] = get(page, 'sections', []);

    const notSelectedRelatedSectionsIds = (() => {
        const {relatedSections, selectedSections} = sections.reduce(
            (acc: {selectedSections: number[]; relatedSections: Set<number>}, section) => {
                section.fields?.forEach(field => {
                    if (!field.relatedSections) return;

                    const initialValue = getInitialFieldValue(page.id, section.id, field.id, result);
                    const selectedOptions: string[] = initialValue ? initialValue.split(OPTIONS_SEPARATOR) : [];
                    const options = field.fieldOptions
                        ? [FORM_FIELD_IMAGE_CHECK, FORM_FIELD_IMAGE_RADIO].includes(field.type)
                            ? field.fieldOptions.map(option => option.uuid)
                            : field.fieldOptions.map(option => option.title)
                        : [];

                    const selectedIndexes = selectedOptions.map((option: string) => options.indexOf(option));
                    const relatedSections = field.relatedSections
                        .split(RELATED_SECTIONS_SEPARATOR)
                        .map(id => Number(id));

                    selectedIndexes.forEach(i => {
                        relatedSections[i] && acc.selectedSections.push(relatedSections[i]);
                    });

                    relatedSections.forEach(s => {
                        acc.relatedSections.add(s);
                    });
                });

                return acc;
            },
            {selectedSections: [], relatedSections: new Set<number>()},
        );
        const allRelatedSectionsIds: number[] = [];
        relatedSections.forEach(v => allRelatedSectionsIds.push(v));

        return allRelatedSectionsIds.filter(id => !selectedSections.includes(id));
    })();

    return (
        <Box sx={{pt: 3, mb: 2}}>
            <PageSwitcher formId={Number(formId)} pages={pages} />
            {sections
                .filter(section => !notSelectedRelatedSectionsIds.includes(section.id))
                .map((section: FormSectionTypes) => {
                    const pageKeyword = `page-${page.id}`;
                    const sectionKeyword = `section-${section.id}`;
                    const content = get(result, [pageKeyword, sectionKeyword], null);
                    return (
                        <SectionContent
                            key={`wrapper-${pageKeyword}-${sectionKeyword}`}
                            formId={Number(formId)}
                            section={section}
                            content={content}
                            toggleOutdatedInfo={toggleOutdatedInfo}
                            toggleIsChatOpened={toggleIsChatOpened}
                        />
                    );
                })}
            <ModalWrapper
                title={t('messages.warning.outdatedInformation')}
                isShowModal={isOutdatedInfo}
                toggleModal={toggleOutdatedInfo}
            >
                <Typography sx={{mb: 3}}>{t('messages.warning.outdatedInformationExplanation')}</Typography>
                <AgentSaveButton
                    isLoading={false}
                    isSubmit={false}
                    onClick={reloadPage}
                    title={t('common.buttons.reload')}
                />
            </ModalWrapper>
        </Box>
    );
};

export default RequesterInfoFormBuilder;
