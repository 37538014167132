import React, {FC, useCallback, useContext} from 'react';
import {Form, Formik, Field, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';

import {UpdateWorkflowStatusRuleFormTypes, WorkflowStatusRuleItemTypes} from 'appRedux/actions/workflow/types';
import {UPDATE_WORKFLOW_STATUS_RULE} from 'appRedux/actions/workflow';

import {AlertContext} from 'contexts/alert/context';

import {validationSchemaWorkflowRuleOptions} from 'components/Forms/Workflow/validation';
import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

interface WorkflowRuleOptionFormType {
    item: WorkflowStatusRuleItemTypes;
}

const WorkflowRuleOptionForm: FC<WorkflowRuleOptionFormType> = ({item}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const {requiredApproval, requiredRejection, uuid, approvers} = item;

    const onSubmitClicked = useCallback(
        data => dispatch({type: UPDATE_WORKFLOW_STATUS_RULE.REQUEST, payload: data}),
        [dispatch],
    );

    const onSaveClicked = (values: UpdateWorkflowStatusRuleFormTypes) => {
        onSubmitClicked({
            ...values,
            showAlert,
            uuid,
        });
    };

    return (
        <Formik
            initialValues={{
                requiredApproval,
                requiredRejection,
            }}
            validationSchema={() => validationSchemaWorkflowRuleOptions(t, approvers.length)}
            onSubmit={onSaveClicked}
        >
            {(formik: FormikProps<UpdateWorkflowStatusRuleFormTypes>) => {
                return (
                    <Form>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'flex-start',
                                    mt: 1,
                                    mb: 1,
                                }}
                            >
                                <FormControl
                                    fullWidth
                                    sx={{
                                        width: '49%',
                                    }}
                                >
                                    <Field
                                        name="requiredApproval"
                                        label={t('orguser.workflows.rules.requiredApproval')}
                                        type="number"
                                        component={FormikTextInput}
                                    />
                                </FormControl>
                                <FormControl
                                    fullWidth
                                    sx={{
                                        width: '49%',
                                    }}
                                >
                                    <Field
                                        name="requiredRejection"
                                        label={t('orguser.workflows.rules.requiredRejection')}
                                        type="number"
                                        component={FormikTextInput}
                                    />
                                </FormControl>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    mt: 1,
                                }}
                            >
                                <AgentSaveButton isSubmit />
                            </Box>
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default WorkflowRuleOptionForm;
