import React, {FC, useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import Typography from '@mui/material/Typography';
import {SelectChangeEvent} from '@mui/material/Select';
import Alert from '@mui/material/Alert';

import {RoleTypes} from 'appRedux/actions/roles/types';
import {UserHasRolesTypes, OrganizationSsoHasRolesTypes} from 'appRedux/actions/organizationAdmin/types';
import {RootReducer} from 'appRedux/reducers';

import {theme} from 'config/theme';

interface RolesSelectorsType {
    fieldName: string;
    userHasRoles: UserHasRolesTypes[] | OrganizationSsoHasRolesTypes[];
    setUserHasRoles: (values: UserHasRolesTypes[] | OrganizationSsoHasRolesTypes[]) => void;
    isCurrentUserEmail?: boolean;
    title?: string;
}

const RolesSelectors: FC<RolesSelectorsType> = ({
    fieldName,
    title,
    userHasRoles,
    setUserHasRoles,
    isCurrentUserEmail,
}) => {
    const [t] = useTranslation();

    const [, setIsUpdated] = useState<boolean>(false);

    const {
        admin: {roles},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const onRoleChecked = (event: SelectChangeEvent) => {
        const roleId = Number(event.target.value);
        const isChecked = userHasRoles.some(userHasRole => userHasRole.role === roleId);
        if (isChecked) {
            for (let i = 0, n = userHasRoles.length; i < n; i++) {
                const userHasRole = userHasRoles[i];
                if (userHasRole.role === roleId) {
                    userHasRoles.splice(i, 1);
                    setUserHasRoles(userHasRoles);
                    setIsUpdated(previous => !previous);
                    break;
                }
            }
        } else {
            setUserHasRoles([
                ...userHasRoles,
                {
                    role: roleId,
                },
            ]);
        }
    };

    return (
        <Box>
            <Typography sx={{fontWeight: 600, mt: 2, mb: 1}}>{`${t(title ?? 'common.menu.roles')}:`}</Typography>
            {isCurrentUserEmail && (
                <Alert severity="info" sx={{mb: 2}}>
                    <Typography>{t('messages.warning.changeRoleExplanation')}</Typography>
                </Alert>
            )}
            <FormGroup>
                {roles &&
                    roles.map((role: RoleTypes, index: number) => {
                        const isChecked = userHasRoles.some(userHasRole => userHasRole.role === role.id);
                        return (
                            <FormControlLabel
                                key={`role-checkbox-${index}`}
                                control={
                                    <Checkbox
                                        name={fieldName}
                                        value={role.id}
                                        checked={isChecked}
                                        onChange={onRoleChecked}
                                        sx={{
                                            '& svg': {color: theme.palette.info.main},
                                        }}
                                    />
                                }
                                label={role.title}
                            />
                        );
                    })}
            </FormGroup>
        </Box>
    );
};

export default RolesSelectors;
