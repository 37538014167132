import React, {FC} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import {RootReducer} from 'appRedux/reducers';

const StatsTable: FC = () => {
    const [t] = useTranslation();
    const {
        requestCase: {requesterCasesStatistic},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    return (
        <Box>
            <Table>
                <TableHead>
                    <TableRow>
                        {requesterCasesStatistic.map(cases => (
                            <TableCell
                                key={cases.name}
                                sx={{
                                    borderBottom: 'none',
                                    padding: 1,
                                }}
                                align={'center'}
                            >
                                <Typography sx={{fontWeight: 600}}>
                                    {t(`orguser.requesterCase.statusTypes.${cases.name}`)}
                                </Typography>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        {requesterCasesStatistic.map(cases => (
                            <TableCell
                                key={cases.name}
                                sx={{
                                    borderBottom: 'none',
                                    padding: 1,
                                }}
                                align={'center'}
                            >
                                <Typography sx={{fontWeight: 600}}>{cases.quantity}</Typography>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableBody>
            </Table>
        </Box>
    );
};

export default StatsTable;
