import {WorkflowStatusRuleItemTypes} from 'appRedux/actions/workflow/types';

import {WORKFLOW_STATUS_RULE_TYPE_APPROVAL_REQUIRED} from 'components/Forms/Workflow/workflowStatusRule/helper';

export const checkStatusHasNotCompletedRules = (rules: WorkflowStatusRuleItemTypes[]): boolean => {
    const approvingRequiredRule = rules.find(item => item.type === WORKFLOW_STATUS_RULE_TYPE_APPROVAL_REQUIRED);
    if (approvingRequiredRule) {
        const {approvers, requiredApproval} = approvingRequiredRule;
        return approvers.length === 0 || approvers.length < Number(requiredApproval);
    }
    return false;
};
