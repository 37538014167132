import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Field, Form, Formik, FormikProps} from 'formik';

import Box from '@mui/material/Box';
import {IconButton, InputAdornment} from '@mui/material';
import {Visibility, VisibilityOff} from '@mui/icons-material';

import {ExportCryptoKeysTypes} from 'appRedux/actions/auth/types';

import {validationSchema} from 'components/PublicKeysList/validation';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';

interface CryptoPasswordFormType {
    handleSubmit: (value: ExportCryptoKeysTypes) => void;
}

const CryptoPasswordForm: FC<CryptoPasswordFormType> = ({handleSubmit}) => {
    const [t] = useTranslation();

    const [showPassword, setShowPassword] = useState<boolean>(false);
    const toggleShowPassword = () => {
        setShowPassword(previous => !previous);
    };

    return (
        <Box
            sx={{
                width: '100%',
            }}
        >
            <Formik
                initialValues={{
                    keyPassword: '',
                }}
                validationSchema={validationSchema(t)}
                onSubmit={handleSubmit}
            >
                {(formik: FormikProps<ExportCryptoKeysTypes>) => {
                    return (
                        <Form>
                            <Field
                                name="keyPassword"
                                label={t('common.security.password')}
                                component={FormikTextInput}
                                type={showPassword ? 'text' : 'password'}
                                autoComplete="off"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={toggleShowPassword}
                                                onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
                                                    event.preventDefault()
                                                }
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Box
                                sx={{
                                    mt: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <AgentSaveButton isSubmit title={t('common.buttons.downloadKeysFile')} />
                            </Box>
                        </Form>
                    );
                }}
            </Formik>
        </Box>
    );
};

export default CryptoPasswordForm;
