import React, {FC, useCallback, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import get from 'lodash/get';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {Document, Page, View, Text} from '@react-pdf/renderer';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import {RootReducer} from 'appRedux/reducers';
import {RESEND_EMAIL} from 'appRedux/actions/profile';
import {REQUESTER_PREPARED_STATUS} from 'appRedux/actions/requestCase';
import {FormPageTypes} from 'appRedux/actions/forms/types';

import ClientScreenWrapper from 'components/ClientScreenComponents/ClientScreenWrapper/ClientScreenWrapper';

import {pdfStyles} from 'pages/agent/pdfExport/pdfPartials/PdfDocumentStyles';
import PagePdfContent from 'pages/agent/pdfExport/pdfPartials/PagePdfContent';

import useParsedTranslation from 'hooks/useParsedTranslation';

import {theme} from 'config/theme';
import {routes} from 'config/index';
import {PdfDocumentContext} from 'pages/agent/pdfExport/pdfPartials/pdfDocumentContext';

const FinalPage: FC = () => {
    const [t] = useTranslation();
    const navigate = useNavigate();
    const {requestCase, form} = useParams();
    const dispatch = useDispatch();

    const {
        admin: {formInfo},
        profile: {profile, isLoading},
        requestCase: {currentCase},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const isUserVerified = get(profile, 'isVerify', false);

    const text = isUserVerified ? 'requester.finalPage.finalTextVerified' : 'requester.finalPage.finalTextNotVerified';
    const buttonLabel = isUserVerified ? 'requester.finalPage.OK' : 'requester.finalPage.sendAgain';

    const resendEmailAction = useCallback(() => dispatch({type: RESEND_EMAIL.REQUEST}), [dispatch]);

    const updateRequesterStatus = useCallback(
        data => dispatch({type: REQUESTER_PREPARED_STATUS.REQUEST, payload: data}),
        [dispatch],
    );

    const status = get(currentCase, 'status', null);

    const redirectToRequestsList = () => {
        navigate(`${routes.REQUEST}/${requestCase}/form/${form}`);
    };

    const onSendAgainClicked = () => {
        resendEmailAction();
    };

    useEffect(() => {
        if (isUserVerified) {
            updateRequesterStatus({
                id: requestCase,
            });
        }
    }, [isUserVerified, status]);

    return (
        <ClientScreenWrapper>
            <Box sx={{p: 2}}>
                <Typography align="justify" sx={{mb: 2}}>
                    {isLoading ? t('common.pleaseWait') : useParsedTranslation(t, text)}
                </Typography>

                <Box sx={{maxWidth: '700px', mx: 'auto', my: 2}}>
                    {/*<PdfDocumentContext.Provider*/}
                    {/*    value={{translations: formInfo.translations, preview: true, currentCase}}*/}
                    {/*>*/}
                    {/*    <Document title={currentCase.caseLabel}>*/}
                    {/*        {pages.map((page: FormPageTypes, index: number) => (*/}
                    {/*            <Page size="A4" style={pdfStyles.page} key={`page-${index}`}>*/}
                    {/*                <PagePdfContent*/}
                    {/*                    pageNumber={index + 1}*/}
                    {/*                    page={page}*/}
                    {/*                    result={currentCase.result}*/}
                    {/*                    formId={form}*/}
                    {/*                />*/}
                    {/*            </Page>*/}
                    {/*        ))}*/}
                    {/*    </Document>*/}
                    {/*</PdfDocumentContext.Provider>*/}
                </Box>
                <Box sx={{display: 'flex', justifyContent: 'center', mb: 3}}>
                    <Button
                        data-id={`button#form-final`}
                        onClick={isUserVerified ? redirectToRequestsList : onSendAgainClicked}
                        sx={{
                            p: 2,
                            minWidth: 100,
                            height: 40,
                            borderRadius: 20,
                            backgroundColor: theme.palette.info.main,
                            '&:hover': {
                                backgroundColor: theme.palette.info.main,
                            },
                        }}
                    >
                        <Typography
                            sx={{
                                textTransform: 'none',
                                color: theme.palette.background.default,
                            }}
                        >
                            {t(buttonLabel)}
                        </Typography>
                    </Button>
                </Box>
            </Box>
        </ClientScreenWrapper>
    );
};

export default FinalPage;
