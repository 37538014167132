import React, {FC, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import moment from 'moment';

import IconButton from '@mui/material/IconButton';
import {GridColDef} from '@mui/x-data-grid';

import {InviteTypes} from 'appRedux/actions/invites/types';

import {AlertContext} from 'contexts/alert/context';

import CopySvgIcon from 'assets/icons/buttons/CopySvgIcon';

import DeleteInviteButton from 'components/Invites/DeleteInviteButton';
import {getInviteStatusLabel} from 'components/Invites/helper';
import {CustomDataGrid} from 'components/AdminScreenComponents/CustomDataGrid';

import {ALERT_TYPE_SUCCESS, DATE_TIME_FORMAT} from 'config/index';

interface InvitesDataGridType {
    invites: InviteTypes[];
}

const InvitesDataGrid: FC<InvitesDataGridType> = ({invites}) => {
    const [t] = useTranslation();

    const {showAlert} = useContext(AlertContext);

    const onClickCopyToBuffer = (invite: string) => {
        navigator.clipboard.writeText(`${window.location.origin}/invite#${invite}`);
        showAlert(ALERT_TYPE_SUCCESS, t('messages.success.linkCopiedToBuffer'));
    };

    const columns: GridColDef[] = [
        {
            field: 'formTitle',
            type: 'singleSelect',
            valueOptions: [...new Set(invites.map(({formTitle}) => formTitle))],
            headerName: t('orguser.invites.parameters.form'),
            minWidth: 150,
        },
        {
            field: 'agent',
            type: 'singleSelect',
            valueOptions: [...new Set(invites.map(({agent}) => agent))],
            headerName: t('orguser.invites.parameters.orgUser'),
            minWidth: 200,
        },
        {
            field: 'title',
            headerName: t('orguser.invites.parameters.titleOrInvitedEmail'),
            minWidth: 200,
        },
        {
            field: 'invite',
            headerName: t('orguser.invites.parameters.link'),
            minWidth: 150,
        },
        {
            field: 'status',
            type: 'singleSelect',
            valueOptions: [...new Set(invites.map(({status}) => t(getInviteStatusLabel(Number(status)))))],
            headerName: t('orguser.invites.parameters.status'),
            valueGetter: ({value}) => t(getInviteStatusLabel(Number(value))),
        },
        {
            field: 'createdAt',
            headerName: t('orguser.invites.parameters.createdAt'),
            valueGetter: ({value}) => moment.unix(value).format(DATE_TIME_FORMAT),
        },
        {
            field: 'formId',
            headerName: '',
            width: 100,
            renderCell: ({row}) => (
                <>
                    <IconButton
                        sx={{mr: 0.5}}
                        title={t('common.buttons.copyToBuffer')}
                        onClick={() => onClickCopyToBuffer(row.invite)}
                    >
                        <CopySvgIcon isActive={false} />
                    </IconButton>
                    <DeleteInviteButton item={row} formId={row.formId} />
                </>
            ),
        },
    ];

    return (
        <CustomDataGrid
            rows={invites}
            columns={columns}
            columnVisibilityModel={{invite: false}}
            getRowId={row => row.uuid}
            checkboxSelection={false}
            enablePaging
            isLarge
        />
    );
};

export default InvitesDataGrid;
