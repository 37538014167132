import React, {FC, useContext} from 'react';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';

import {FormSectionTypes} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';

import {ClientFormContext} from 'contexts/clientForm/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

import {
    isSaveSectionUnavailable,
    getEmptySectionRequiredField,
    getEmptySectionRequiredPopups,
} from 'pages/client/form/helper';

interface SectionSaveButtonType {
    pageId: number;
    section: FormSectionTypes;
    clientInformation: unknown;
    onSaveClicked: () => void;
    'data-id'?: string;
}

const SectionSaveButton: FC<SectionSaveButtonType> = ({
    pageId,
    onSaveClicked,
    section,
    clientInformation,
    'data-id': dataId,
}) => {
    const {setErrorField, setErrorPopup} = useContext(ClientFormContext);

    const {
        requestCase: {
            currentCase: {status, files, signatures},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const onDisabledSaveClicked = () => {
        const emptyFieldId = getEmptySectionRequiredField(pageId, section, clientInformation, files);
        if (emptyFieldId) {
            setErrorField(emptyFieldId);
        }
        const emptyPopupId = getEmptySectionRequiredPopups(pageId, section, clientInformation);
        if (emptyPopupId) {
            setErrorPopup(emptyPopupId);
        }
    };

    const isDisabled = isSaveSectionUnavailable(pageId, section, clientInformation, status, files, signatures);

    return (
        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center'}}>
            <AgentSaveButton
                isBigButton
                isSubmit={false}
                data-id={dataId}
                onClick={isDisabled ? onDisabledSaveClicked : onSaveClicked}
            />
        </Box>
    );
};

export default SectionSaveButton;
