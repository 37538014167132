import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Typography from '@mui/material/Box';
import Box from '@mui/material/Box';

import PlusSvgIcon from 'assets/icons/buttons/PlusSvgIcon';

import {WorkflowStatusEventItemTypes} from 'appRedux/actions/workflow/types';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import WorkflowEventAttachAgentForm from 'components/Forms/Workflow/workflowEventAgent/WorkflowEventAttachAgentForm';

import AttachedAgentsList from 'pages/admin/updateWorkflowEvents/workflowEventAgent/AttachedAgentsList';

interface WorkflowEventAgentLabelType {
    label: string;
    item?: WorkflowStatusEventItemTypes;
}

const WorkflowEventAgentLabel: FC<WorkflowEventAgentLabelType> = ({label, item}) => {
    const [t] = useTranslation();

    const [showModal, setShowModal] = useState<boolean>(false);

    const toggleModal = () => {
        setShowModal(previous => !previous);
    };

    return (
        <Box>
            <Typography sx={{mb: 1, fontWeight: 700}}>{`${t(label)}:`}</Typography>
            {(!item || (item && item.agents.length === 0)) && <Typography sx={{mb: 2}}>{t('common.none')}</Typography>}
            {item && (
                <>
                    {item.agents.length > 0 && <AttachedAgentsList items={item.agents} />}
                    <AgentSaveButton
                        isSubmit={false}
                        title={t('orguser.workflows.workflowStatusEventParameters.attachAgent')}
                        onClick={toggleModal}
                        icon={<PlusSvgIcon isActive />}
                    />
                    <ModalWrapper
                        isShowModal={showModal}
                        toggleModal={toggleModal}
                        title={t('orguser.workflows.workflowStatusEventParameters.attachAgent')}
                    >
                        <WorkflowEventAttachAgentForm item={item} closeForm={() => setShowModal(false)} />
                    </ModalWrapper>
                </>
            )}
        </Box>
    );
};

export default WorkflowEventAgentLabel;
