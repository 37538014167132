import React, {FC, useCallback, useContext, useState} from 'react';
import {useDispatch} from 'react-redux';

import IconButton from '@mui/material/IconButton';

import DeleteSvgIcon from 'assets/icons/buttons/DeleteSvgIcon';

import {PublicKeyTypes} from 'appRedux/actions/crypto/types';
import {REMOVE_PUBLIC_KEY} from 'appRedux/actions/crypto';

import {AlertContext} from 'contexts/alert/context';

import DeleteAdminConfirmationModal from 'components/AdminScreenComponents/DeleteAdminConfirmationModal';

interface DeletePublicKeyButtonType {
    item: PublicKeyTypes;
    isCurrentBrowserKey: boolean;
}

const DeletePublicKeyButton: FC<DeletePublicKeyButtonType> = ({item, isCurrentBrowserKey}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    const {uuid} = item;

    const removePublicKey = useCallback(data => dispatch({type: REMOVE_PUBLIC_KEY.REQUEST, payload: data}), [dispatch]);

    const onClickDelete = () => {
        removePublicKey({
            uuid,
            isCurrent: true,
            showAlert,
        });
    };

    return (
        <>
            <IconButton onClick={toggleIsOpened} disabled={isCurrentBrowserKey}>
                <DeleteSvgIcon isDisabled={isCurrentBrowserKey} />
            </IconButton>
            <DeleteAdminConfirmationModal
                toggleModal={toggleIsOpened}
                isShowModal={isOpened}
                onClickAction={onClickDelete}
            />
        </>
    );
};

export default DeletePublicKeyButton;
