import React, {FC, useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';

import {FormFieldMoveRequestFormType} from 'appRedux/actions/forms/types';
import {FORM_POPUP_MOVE} from 'appRedux/actions/forms';

import {AlertContext} from 'contexts/alert/context';

import MoveForm from 'components/Forms/FormBuilder/FieldForm/MoveForm';

interface MovePopupFormType {
    pageId: number;
    currentSectionId: number;
    fieldId: number;
    toggleModal: () => void;
}

const MoveFormPopup: FC<MovePopupFormType> = ({pageId, currentSectionId, fieldId, toggleModal}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const formPopupReplace = useCallback(data => dispatch({type: FORM_POPUP_MOVE.REQUEST, payload: data}), [dispatch]);

    const onSubmitClick = (values: FormFieldMoveRequestFormType) => {
        formPopupReplace({
            id: fieldId,
            ...values,
            showAlert,
            callback: toggleModal,
        });
    };

    return <MoveForm pageId={pageId} currentSectionId={currentSectionId} onSubmitClick={onSubmitClick} />;
};

export default MoveFormPopup;
