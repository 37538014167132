import {requestTypeCreator} from 'helpers/index';

import {
    OrganizationItemBasic,
    OrganizationQuotasItemBasic,
    OrganizationQuotasListRequestTypes,
    OrganizationQuotasCreateRequestTypes,
    OrganizationQuotasUpdateRequestTypes,
    OrganizationQuotasDeleteRequestTypes,
    OrganizationQuotasToggleRequestTypes,
    OrganizationsStatisticRequestTypes,
    OrganizationsStatisticSendRequestTypes,
    CreateOrganizationRequestTypes,
    ToggleTwoFactorOrganizationRequestTypes,
    DeleteOrganizationRequestTypes,
    ChangeOrganizationStatusRequestTypes,
    OrganizationMultipleChangeStatusType,
    OrganizationSsoCreateTypes,
    OrganizationSsoUpdateTypes,
    OrganizationSsoUpdateClientSecretTypes,
    ChangeOrganizationDomainRequestType,
    OrganizationTemplateRequestType,
    OrganizationTemplateItemTypes,
    OrganizationTemplateCreateRequestType,
    OrganizationTemplateUpdateRequestType,
    OrganizationPrivacyPolicyRequestTypes,
    OrganizationPrivacyPolicyCreateRequestTypes,
    OrganizationPrivacyPolicyVersionCreateRequestTypes,
    OrganizationPrivacyPolicyUpdateRequestTypes,
    OrganizationPrivacyPolicyToggleDefaultRequestTypes,
    OrganizationPrivacyPolicyItemTypes,
} from 'appRedux/actions/organization/types';
import {
    CommonDeleteLogoTypes,
    CommonGetLogoUrlTypes,
    CommonLogoResponseType,
    CommonUploadLogoTypes,
} from 'appRedux/actions/forms/types';

export const ORGANIZATION_LIST = requestTypeCreator('ORGANIZATION_LIST');
export const CREATE_ORGANIZATION = requestTypeCreator('CREATE_ORGANIZATION');
export const UPDATE_ORGANIZATION = requestTypeCreator('UPDATE_ORGANIZATION');
export const TOGGLE_TWO_FACTOR_ORGANIZATION = requestTypeCreator('TOGGLE_TWO_FACTOR_ORGANIZATION');
export const DELETE_ORGANIZATION = requestTypeCreator('DELETE_ORGANIZATION');
export const CHANGE_ORGANIZATION_STATUS = requestTypeCreator('CHANGE_ORGANIZATION_STATUS');
export const ORGANIZATION_MULTIPLE_STATUS_CHANGING = requestTypeCreator('ORGANIZATION_MULTIPLE_STATUS_CHANGING');
export const ORGANIZATION_CHANGE_DOMAIN = requestTypeCreator('ORGANIZATION_CHANGE_DOMAIN');

export const ORGANIZATION_UPLOAD_LOGO = requestTypeCreator('ORGANIZATION_UPLOAD_LOGO');
export const ORGANIZATION_LOGO_GET = requestTypeCreator('ORGANIZATION_LOGO_GET');
export const ORGANIZATION_LOGO_DELETE = requestTypeCreator('ORGANIZATION_LOGO_DELETE');

export const ORGANIZATION_UPLOAD_FAVICON = requestTypeCreator('ORGANIZATION_UPLOAD_FAVICON');
export const ORGANIZATION_FAVICON_DELETE = requestTypeCreator('ORGANIZATION_FAVICON_DELETE');

export const ORGANIZATION_QUOTAS_LIST = requestTypeCreator('ORGANIZATION_QUOTAS_LIST');
export const CREATE_ORGANIZATION_QUOTA = requestTypeCreator('CREATE_ORGANIZATION_QUOTA');
export const UPDATE_ORGANIZATION_QUOTA = requestTypeCreator('UPDATE_ORGANIZATION_QUOTA');
export const DELETE_ORGANIZATION_QUOTA = requestTypeCreator('DELETE_ORGANIZATION_QUOTA');
export const TOGGLE_ORGANIZATION_QUOTA_HARD = requestTypeCreator('TOGGLE_ORGANIZATION_QUOTA_HARD');

export const GET_ORGANIZATIONS_STATISTIC = requestTypeCreator('GET_ORGANIZATIONS_STATISTIC');
export const SEND_ORGANIZATIONS_STATISTIC = requestTypeCreator('SEND_ORGANIZATIONS_STATISTIC');

export const CREATE_ORGANIZATION_SSO_CREDENTIALS = requestTypeCreator('CREATE_ORGANIZATION_SSO_CREDENTIALS');
export const UPDATE_ORGANIZATION_SSO_CREDENTIALS = requestTypeCreator('UPDATE_ORGANIZATION_SSO_CREDENTIALS');
export const UPDATE_ORGANIZATION_SSO_CLIENT_SECRET_CREDENTIALS = requestTypeCreator(
    'UPDATE_ORGANIZATION_SSO_CLIENT_SECRET_CREDENTIALS',
);

export const ORGANIZATION_TEMPLATES_LIST = requestTypeCreator('ORGANIZATION_TEMPLATES_LIST');
export const ORGANIZATION_TEMPLATE_CREATE = requestTypeCreator('ORGANIZATION_TEMPLATE_CREATE');
export const ORGANIZATION_TEMPLATE_UPDATE = requestTypeCreator('ORGANIZATION_TEMPLATE_UPDATE');

export const ORGANIZATION_PRIVACY_POLICY_LIST = requestTypeCreator('ORGANIZATION_PRIVACY_POLICY_LIST');
export const CREATE_ORGANIZATION_PRIVACY_POLICY = requestTypeCreator('CREATE_ORGANIZATION_PRIVACY_POLICY');
export const UPDATE_ORGANIZATION_PRIVACY_POLICY = requestTypeCreator('UPDATE_ORGANIZATION_PRIVACY_POLICY');
export const TOGGLE_ORGANIZATION_PRIVACY_POLICY_DEFAULT = requestTypeCreator(
    'TOGGLE_ORGANIZATION_PRIVACY_POLICY_DEFAULT',
);
export const CREATE_ORGANIZATION_PRIVACY_POLICY_VERSION = requestTypeCreator(
    'CREATE_ORGANIZATION_PRIVACY_POLICY_VERSION',
);

export const organizationList = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: ORGANIZATION_LIST.ERROR,
    }),
    request: () => ({
        type: ORGANIZATION_LIST.REQUEST,
    }),
    success: (payload: OrganizationItemBasic[]): IActionType<OrganizationItemBasic[]> => ({
        payload,
        type: ORGANIZATION_LIST.SUCCESS,
    }),
};

export const createOrganization = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: CREATE_ORGANIZATION.ERROR,
    }),
    request: (payload: CreateOrganizationRequestTypes): IActionType<CreateOrganizationRequestTypes> => ({
        payload,
        type: CREATE_ORGANIZATION.REQUEST,
    }),
    success: (payload: OrganizationItemBasic): IActionType<OrganizationItemBasic> => ({
        payload,
        type: CREATE_ORGANIZATION.SUCCESS,
    }),
};

export const updateOrganization = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: UPDATE_ORGANIZATION.ERROR,
    }),
    request: (payload: CreateOrganizationRequestTypes): IActionType<CreateOrganizationRequestTypes> => ({
        payload,
        type: UPDATE_ORGANIZATION.REQUEST,
    }),
    success: (payload: OrganizationItemBasic[]): IActionType<OrganizationItemBasic[]> => ({
        payload,
        type: UPDATE_ORGANIZATION.SUCCESS,
    }),
};

export const toggleTwoFactorOrganization = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: TOGGLE_TWO_FACTOR_ORGANIZATION.ERROR,
    }),
    request: (
        payload: ToggleTwoFactorOrganizationRequestTypes,
    ): IActionType<ToggleTwoFactorOrganizationRequestTypes> => ({
        payload,
        type: TOGGLE_TWO_FACTOR_ORGANIZATION.REQUEST,
    }),
    success: (payload: OrganizationItemBasic[]): IActionType<OrganizationItemBasic[]> => ({
        payload,
        type: TOGGLE_TWO_FACTOR_ORGANIZATION.SUCCESS,
    }),
};

export const deleteOrganization = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: DELETE_ORGANIZATION.ERROR,
    }),
    request: (payload: DeleteOrganizationRequestTypes): IActionType<DeleteOrganizationRequestTypes> => ({
        payload,
        type: DELETE_ORGANIZATION.REQUEST,
    }),
    success: (payload: OrganizationItemBasic[]): IActionType<OrganizationItemBasic[]> => ({
        payload,
        type: DELETE_ORGANIZATION.SUCCESS,
    }),
};

export const changeOrganizationStatus = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: CHANGE_ORGANIZATION_STATUS.ERROR,
    }),
    request: (payload: ChangeOrganizationStatusRequestTypes): IActionType<ChangeOrganizationStatusRequestTypes> => ({
        payload,
        type: CHANGE_ORGANIZATION_STATUS.REQUEST,
    }),
    success: (payload: any): IActionType<any> => ({
        payload,
        type: CHANGE_ORGANIZATION_STATUS.SUCCESS,
    }),
};

export const changeOrganizationDomain = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: ORGANIZATION_CHANGE_DOMAIN.ERROR,
    }),
    request: (payload: ChangeOrganizationDomainRequestType): IActionType<ChangeOrganizationDomainRequestType> => ({
        payload,
        type: ORGANIZATION_CHANGE_DOMAIN.REQUEST,
    }),
    success: (payload: OrganizationItemBasic[]): IActionType<OrganizationItemBasic[]> => ({
        payload,
        type: ORGANIZATION_CHANGE_DOMAIN.SUCCESS,
    }),
};

export const organizationMultipleStatusChanging = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: ORGANIZATION_MULTIPLE_STATUS_CHANGING.ERROR,
    }),
    request: (payload: OrganizationMultipleChangeStatusType): IActionType<OrganizationMultipleChangeStatusType> => ({
        payload,
        type: ORGANIZATION_MULTIPLE_STATUS_CHANGING.REQUEST,
    }),
    success: (payload: OrganizationItemBasic[]): IActionType<OrganizationItemBasic[]> => ({
        payload,
        type: ORGANIZATION_MULTIPLE_STATUS_CHANGING.SUCCESS,
    }),
};

export const uploadOrganizationLogo = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: ORGANIZATION_UPLOAD_LOGO.ERROR,
    }),
    request: (payload: CommonUploadLogoTypes): IActionType<CommonUploadLogoTypes> => ({
        payload,
        type: ORGANIZATION_UPLOAD_LOGO.REQUEST,
    }),
    success: (payload: OrganizationItemBasic[]): IActionType<OrganizationItemBasic[]> => ({
        payload,
        type: ORGANIZATION_UPLOAD_LOGO.SUCCESS,
    }),
};

export const uploadOrganizationFavicon = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: ORGANIZATION_UPLOAD_FAVICON.ERROR,
    }),
    request: (payload: CommonUploadLogoTypes): IActionType<CommonUploadLogoTypes> => ({
        payload,
        type: ORGANIZATION_UPLOAD_FAVICON.REQUEST,
    }),
    success: (payload: OrganizationItemBasic[]): IActionType<OrganizationItemBasic[]> => ({
        payload,
        type: ORGANIZATION_UPLOAD_FAVICON.SUCCESS,
    }),
};

export const getOrganizationLogo = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: ORGANIZATION_LOGO_GET.ERROR,
    }),
    request: (payload: CommonGetLogoUrlTypes): IActionType<CommonGetLogoUrlTypes> => ({
        payload,
        type: ORGANIZATION_LOGO_GET.REQUEST,
    }),
    success: (payload: CommonLogoResponseType): IActionType<CommonLogoResponseType> => ({
        payload,
        type: ORGANIZATION_LOGO_GET.SUCCESS,
    }),
};

export const organizationLogoDelete = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: ORGANIZATION_LOGO_DELETE.ERROR,
    }),
    request: (payload: CommonDeleteLogoTypes): IActionType<CommonDeleteLogoTypes> => ({
        payload,
        type: ORGANIZATION_LOGO_DELETE.REQUEST,
    }),
    success: (payload: OrganizationItemBasic[]): IActionType<OrganizationItemBasic[]> => ({
        payload,
        type: ORGANIZATION_LOGO_DELETE.SUCCESS,
    }),
};

export const organizationFaviconDelete = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: ORGANIZATION_FAVICON_DELETE.ERROR,
    }),
    request: (payload: CommonDeleteLogoTypes): IActionType<CommonDeleteLogoTypes> => ({
        payload,
        type: ORGANIZATION_FAVICON_DELETE.REQUEST,
    }),
    success: (payload: OrganizationItemBasic[]): IActionType<OrganizationItemBasic[]> => ({
        payload,
        type: ORGANIZATION_FAVICON_DELETE.SUCCESS,
    }),
};

export const organizationQuotasList = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: ORGANIZATION_QUOTAS_LIST.ERROR,
    }),
    request: (payload: OrganizationQuotasListRequestTypes): IActionType<OrganizationQuotasListRequestTypes> => ({
        payload,
        type: ORGANIZATION_QUOTAS_LIST.REQUEST,
    }),
    success: (payload: OrganizationQuotasItemBasic[]): IActionType<OrganizationQuotasItemBasic[]> => ({
        payload,
        type: ORGANIZATION_QUOTAS_LIST.SUCCESS,
    }),
};

export const createOrganizationQuota = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: CREATE_ORGANIZATION_QUOTA.ERROR,
    }),
    request: (payload: OrganizationQuotasCreateRequestTypes): IActionType<OrganizationQuotasCreateRequestTypes> => ({
        payload,
        type: CREATE_ORGANIZATION_QUOTA.REQUEST,
    }),
    success: (payload: OrganizationQuotasItemBasic[]): IActionType<OrganizationQuotasItemBasic[]> => ({
        payload,
        type: CREATE_ORGANIZATION_QUOTA.SUCCESS,
    }),
};

export const updateOrganizationQuota = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: UPDATE_ORGANIZATION_QUOTA.ERROR,
    }),
    request: (payload: OrganizationQuotasUpdateRequestTypes): IActionType<OrganizationQuotasUpdateRequestTypes> => ({
        payload,
        type: UPDATE_ORGANIZATION_QUOTA.REQUEST,
    }),
    success: (payload: OrganizationQuotasItemBasic[]): IActionType<OrganizationQuotasItemBasic[]> => ({
        payload,
        type: UPDATE_ORGANIZATION_QUOTA.SUCCESS,
    }),
};

export const deleteOrganizationQuota = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: DELETE_ORGANIZATION_QUOTA.ERROR,
    }),
    request: (payload: OrganizationQuotasDeleteRequestTypes): IActionType<OrganizationQuotasDeleteRequestTypes> => ({
        payload,
        type: DELETE_ORGANIZATION_QUOTA.REQUEST,
    }),
    success: (payload: OrganizationQuotasItemBasic[]): IActionType<OrganizationQuotasItemBasic[]> => ({
        payload,
        type: DELETE_ORGANIZATION_QUOTA.SUCCESS,
    }),
};

export const toggleIsHardOrganizationQuota = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: TOGGLE_ORGANIZATION_QUOTA_HARD.ERROR,
    }),
    request: (payload: OrganizationQuotasToggleRequestTypes): IActionType<OrganizationQuotasToggleRequestTypes> => ({
        payload,
        type: TOGGLE_ORGANIZATION_QUOTA_HARD.REQUEST,
    }),
    success: (payload: OrganizationQuotasItemBasic[]): IActionType<OrganizationQuotasItemBasic[]> => ({
        payload,
        type: TOGGLE_ORGANIZATION_QUOTA_HARD.SUCCESS,
    }),
};

export const getOrganizationsStatistic = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GET_ORGANIZATIONS_STATISTIC.ERROR,
    }),
    request: (payload: OrganizationsStatisticRequestTypes): IActionType<OrganizationsStatisticRequestTypes> => ({
        payload,
        type: GET_ORGANIZATIONS_STATISTIC.REQUEST,
    }),
    success: (payload: Blob | string): IActionType<Blob | string> => ({
        payload,
        type: GET_ORGANIZATIONS_STATISTIC.SUCCESS,
    }),
};

export const sendOrganizationsStatistic = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: SEND_ORGANIZATIONS_STATISTIC.ERROR,
    }),
    request: (
        payload: OrganizationsStatisticSendRequestTypes,
    ): IActionType<OrganizationsStatisticSendRequestTypes> => ({
        payload,
        type: SEND_ORGANIZATIONS_STATISTIC.REQUEST,
    }),
    success: () => ({
        type: SEND_ORGANIZATIONS_STATISTIC.SUCCESS,
    }),
};

export const createOrganizationSsoCredentials = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: CREATE_ORGANIZATION_SSO_CREDENTIALS.ERROR,
    }),
    request: (payload: OrganizationSsoCreateTypes): IActionType<OrganizationSsoCreateTypes> => ({
        payload,
        type: CREATE_ORGANIZATION_SSO_CREDENTIALS.REQUEST,
    }),
    success: (payload: OrganizationItemBasic[]): IActionType<OrganizationItemBasic[]> => ({
        payload,
        type: CREATE_ORGANIZATION_SSO_CREDENTIALS.SUCCESS,
    }),
};

export const updateOrganizationSsoCredentials = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: UPDATE_ORGANIZATION_SSO_CREDENTIALS.ERROR,
    }),
    request: (payload: OrganizationSsoUpdateTypes): IActionType<OrganizationSsoUpdateTypes> => ({
        payload,
        type: UPDATE_ORGANIZATION_SSO_CREDENTIALS.REQUEST,
    }),
    success: (payload: OrganizationItemBasic[]): IActionType<OrganizationItemBasic[]> => ({
        payload,
        type: UPDATE_ORGANIZATION_SSO_CREDENTIALS.SUCCESS,
    }),
};

export const updateOrganizationSsoClientSecretCredentials = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: UPDATE_ORGANIZATION_SSO_CLIENT_SECRET_CREDENTIALS.ERROR,
    }),
    request: (
        payload: OrganizationSsoUpdateClientSecretTypes,
    ): IActionType<OrganizationSsoUpdateClientSecretTypes> => ({
        payload,
        type: UPDATE_ORGANIZATION_SSO_CLIENT_SECRET_CREDENTIALS.REQUEST,
    }),
    success: (payload: OrganizationItemBasic[]): IActionType<OrganizationItemBasic[]> => ({
        payload,
        type: UPDATE_ORGANIZATION_SSO_CLIENT_SECRET_CREDENTIALS.SUCCESS,
    }),
};

export const getOrganizationTemplatesList = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: ORGANIZATION_TEMPLATES_LIST.ERROR,
    }),
    request: (payload: OrganizationTemplateRequestType): IActionType<OrganizationTemplateRequestType> => ({
        payload,
        type: ORGANIZATION_TEMPLATES_LIST.REQUEST,
    }),
    success: (payload: OrganizationTemplateItemTypes[]): IActionType<OrganizationTemplateItemTypes[]> => ({
        payload,
        type: ORGANIZATION_TEMPLATES_LIST.SUCCESS,
    }),
};

export const createOrganizationTemplate = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: ORGANIZATION_TEMPLATE_CREATE.ERROR,
    }),
    request: (payload: OrganizationTemplateCreateRequestType): IActionType<OrganizationTemplateCreateRequestType> => ({
        payload,
        type: ORGANIZATION_TEMPLATE_CREATE.REQUEST,
    }),
    success: (payload: OrganizationTemplateItemTypes[]): IActionType<OrganizationTemplateItemTypes[]> => ({
        payload,
        type: ORGANIZATION_TEMPLATE_CREATE.SUCCESS,
    }),
};

export const updateOrganizationTemplate = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: ORGANIZATION_TEMPLATE_UPDATE.ERROR,
    }),
    request: (payload: OrganizationTemplateUpdateRequestType): IActionType<OrganizationTemplateUpdateRequestType> => ({
        payload,
        type: ORGANIZATION_TEMPLATE_UPDATE.REQUEST,
    }),
    success: (payload: OrganizationTemplateItemTypes[]): IActionType<OrganizationTemplateItemTypes[]> => ({
        payload,
        type: ORGANIZATION_TEMPLATE_UPDATE.SUCCESS,
    }),
};

export const getOrganizationPrivacyPolicyList = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: ORGANIZATION_PRIVACY_POLICY_LIST.ERROR,
    }),
    request: (payload: OrganizationPrivacyPolicyRequestTypes): IActionType<OrganizationPrivacyPolicyRequestTypes> => ({
        payload,
        type: ORGANIZATION_PRIVACY_POLICY_LIST.REQUEST,
    }),
    success: (payload: OrganizationPrivacyPolicyItemTypes[]): IActionType<OrganizationPrivacyPolicyItemTypes[]> => ({
        payload,
        type: ORGANIZATION_PRIVACY_POLICY_LIST.SUCCESS,
    }),
};

export const createOrganizationPrivacyPolicy = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: CREATE_ORGANIZATION_PRIVACY_POLICY.ERROR,
    }),
    request: (
        payload: OrganizationPrivacyPolicyCreateRequestTypes,
    ): IActionType<OrganizationPrivacyPolicyCreateRequestTypes> => ({
        payload,
        type: CREATE_ORGANIZATION_PRIVACY_POLICY.REQUEST,
    }),
    success: (payload: OrganizationPrivacyPolicyItemTypes[]): IActionType<OrganizationPrivacyPolicyItemTypes[]> => ({
        payload,
        type: CREATE_ORGANIZATION_PRIVACY_POLICY.SUCCESS,
    }),
};

export const updateOrganizationPrivacyPolicy = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: UPDATE_ORGANIZATION_PRIVACY_POLICY.ERROR,
    }),
    request: (
        payload: OrganizationPrivacyPolicyUpdateRequestTypes,
    ): IActionType<OrganizationPrivacyPolicyUpdateRequestTypes> => ({
        payload,
        type: UPDATE_ORGANIZATION_PRIVACY_POLICY.REQUEST,
    }),
    success: (payload: OrganizationPrivacyPolicyItemTypes[]): IActionType<OrganizationPrivacyPolicyItemTypes[]> => ({
        payload,
        type: UPDATE_ORGANIZATION_PRIVACY_POLICY.SUCCESS,
    }),
};

export const toggleOrganizationPrivacyPolicyDefault = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: TOGGLE_ORGANIZATION_PRIVACY_POLICY_DEFAULT.ERROR,
    }),
    request: (
        payload: OrganizationPrivacyPolicyToggleDefaultRequestTypes,
    ): IActionType<OrganizationPrivacyPolicyToggleDefaultRequestTypes> => ({
        payload,
        type: TOGGLE_ORGANIZATION_PRIVACY_POLICY_DEFAULT.REQUEST,
    }),
    success: (payload: OrganizationPrivacyPolicyItemTypes[]): IActionType<OrganizationPrivacyPolicyItemTypes[]> => ({
        payload,
        type: TOGGLE_ORGANIZATION_PRIVACY_POLICY_DEFAULT.SUCCESS,
    }),
};

export const createOrganizationPrivacyPolicyVersion = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: CREATE_ORGANIZATION_PRIVACY_POLICY_VERSION.ERROR,
    }),
    request: (
        payload: OrganizationPrivacyPolicyVersionCreateRequestTypes,
    ): IActionType<OrganizationPrivacyPolicyVersionCreateRequestTypes> => ({
        payload,
        type: CREATE_ORGANIZATION_PRIVACY_POLICY_VERSION.REQUEST,
    }),
    success: (payload: OrganizationPrivacyPolicyItemTypes[]): IActionType<OrganizationPrivacyPolicyItemTypes[]> => ({
        payload,
        type: CREATE_ORGANIZATION_PRIVACY_POLICY_VERSION.SUCCESS,
    }),
};
