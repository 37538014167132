import {ElementType} from 'react';

import IconType_01 from 'assets/icons/savedFilters/IconType_01';
import IconType_02 from 'assets/icons/savedFilters/IconType_02';
import IconType_03 from 'assets/icons/savedFilters/IconType_03';
import IconType_04 from 'assets/icons/savedFilters/IconType_04';
import IconType_05 from 'assets/icons/savedFilters/IconType_05';
import IconType_06 from 'assets/icons/savedFilters/IconType_06';
import IconType_07 from 'assets/icons/savedFilters/IconType_07';
import IconType_08 from 'assets/icons/savedFilters/IconType_08';
import IconType_09 from 'assets/icons/savedFilters/IconType_09';
import IconType_10 from 'assets/icons/savedFilters/IconType_10';
import IconType_11 from 'assets/icons/savedFilters/IconType_11';
import IconType_12 from 'assets/icons/savedFilters/IconType_12';
import IconType_13 from 'assets/icons/savedFilters/IconType_13';
import IconType_14 from 'assets/icons/savedFilters/IconType_14';
import IconType_15 from 'assets/icons/savedFilters/IconType_15';
import IconType_16 from 'assets/icons/buttons/AllCasesSvgIcon';
import IconType_17 from 'assets/icons/menu/ActiveCasesSvgIcon';
import IconType_18 from 'assets/icons/menu/ForwardedCasesSvgIcon';
import IconType_19 from 'assets/icons/menu/HelpedCasesSvgIcon';
import IconType_20 from 'assets/icons/menu/DeclinedCasesSvgIcon';
import IconType_21 from 'assets/icons/buttons/DeleteSvgIcon';

import {IconImageType} from 'pages/agent/savedFilters/helper';

export const BOARD_TYPE_LIST = 0;
export const BOARD_TYPE_BOARD = 1;
export const BOARD_TYPE_OVERVIEW = 2;

export const iconTypes: IconImageType[] = [
    {type: 1, icon: IconType_01},
    {type: 2, icon: IconType_02},
    {type: 3, icon: IconType_03},
    {type: 4, icon: IconType_04},
    {type: 5, icon: IconType_05},
    {type: 6, icon: IconType_06},
    {type: 7, icon: IconType_07},
    {type: 8, icon: IconType_08},
    {type: 9, icon: IconType_09},
    {type: 10, icon: IconType_10},
    {type: 11, icon: IconType_11},
    {type: 12, icon: IconType_12},
    {type: 13, icon: IconType_13},
    {type: 14, icon: IconType_14},
    {type: 15, icon: IconType_15},
    {type: 16, icon: IconType_16},
    {type: 17, icon: IconType_17},
    {type: 18, icon: IconType_18},
    {type: 19, icon: IconType_19},
    {type: 20, icon: IconType_20},
    {type: 21, icon: IconType_21},
];

export const getBoardIconByType = (type: number | null): ElementType => {
    const result = iconTypes.find(item => item.type === type);
    return result ? result.icon : IconType_01;
};

export const getBoardTypeLabel = (boardType: number): string => {
    switch (boardType) {
        case BOARD_TYPE_LIST:
            return 'common.views.list';
        case BOARD_TYPE_BOARD:
            return 'common.views.board';
        case BOARD_TYPE_OVERVIEW:
            return 'common.views.overview';
    }
    return '';
};
