import React, {ReactNode, FC, useEffect, useContext, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import {RootReducer} from 'appRedux/reducers';
import {GET_REQUESTER_CASE} from 'appRedux/actions/requestCase';

import {RouteContext} from 'contexts/route/context';
import CaseKeyContextWrapper from 'contexts/caseKey';

import {getNotReadMessagesNumber} from 'components/AdminScreenComponents/translationsHelper';

import AgentChatWrapperContent from 'pages/agent/chat/AgentChatWrapperContent';

interface AgentChatWrapperType {
    children: ReactNode;
}

const AgentChatWrapper: FC<AgentChatWrapperType> = ({children}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const {requestCase} = useParams();

    const {setTopBarSubtitle} = useContext(RouteContext);

    const getRequesterCaseInfo = useCallback(
        data => dispatch({type: GET_REQUESTER_CASE.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        requestCase: {
            currentCase: {isEncryptInfo},
        },
        requestChat: {contacts},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    useEffect(() => {
        const notReadMessagesNumber = getNotReadMessagesNumber(contacts);
        if (notReadMessagesNumber > 0) {
            setTopBarSubtitle(`${notReadMessagesNumber} ${t('orguser.chat.messages')}`);
        } else {
            setTopBarSubtitle('');
        }
    }, [contacts, requestCase]);

    useEffect(() => {
        getRequesterCaseInfo({id: requestCase});
    }, [requestCase]);

    // if (isEncryptInfo) {
    return (
        <CaseKeyContextWrapper caseId={isEncryptInfo ? Number(requestCase) : null}>
            <AgentChatWrapperContent>{children}</AgentChatWrapperContent>
        </CaseKeyContextWrapper>
    );
    // }
    // return <AgentChatWrapperContent>{children}</AgentChatWrapperContent>;
};

export default AgentChatWrapper;
