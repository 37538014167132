import React, {FC, useContext, useState} from 'react';
import get from 'lodash/get';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import EditSvgIcon from 'assets/icons/small/EditRowSvgIcon';
import DeleteSvgIcon from 'assets/icons/buttons/DeleteSvgIcon';

import {AlertContext} from 'contexts/alert/context';

import DeleteClientConfirmationModal from 'components/ClientScreenComponents/DeleteClientConfirmationModal';

import {ContentItemType} from 'pages/client/form/partials/types';

import {ALERT_TYPE_SUCCESS, EMPTY_DATA} from 'config/index';

interface PopupContentRowType {
    pageId: number;
    sectionId: number;
    popupId: number;
    currentIndex: number;
    firstFieldId: number;
    disabled: boolean;
    initialItems: ContentItemType[];
    handleChangePopupInformation: (pageId: number, sectionId: number, popupId: number, value: unknown) => void;
    onEditClick: (value: number) => void;
    item: ContentItemType;
    onSaveClicked: (isCallAlert?: boolean) => void;
}

const PopupContentRow: FC<PopupContentRowType> = ({
    pageId,
    sectionId,
    popupId,
    disabled,
    currentIndex,
    item,
    initialItems,
    handleChangePopupInformation,
    onEditClick,
    firstFieldId,
    onSaveClicked,
}) => {
    const [isShowDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);

    const {showAlert} = useContext(AlertContext);

    const toggleDeleteConfirmation = () => {
        setShowDeleteConfirmation(previous => !previous);
    };

    const onClickDelete = () => {
        for (let i = 0, n = initialItems.length; i < n; i++) {
            if (i === currentIndex) {
                initialItems.splice(i, 1);
            }
        }
        handleChangePopupInformation(pageId, sectionId, popupId, initialItems);
        setShowDeleteConfirmation(false);
        onSaveClicked(false);
        showAlert(ALERT_TYPE_SUCCESS);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                mt: 1,
            }}
        >
            <Box
                onClick={() => onEditClick(currentIndex)}
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    cursor: 'pointer',
                }}
            >
                <IconButton>
                    <EditSvgIcon />
                </IconButton>
                <Typography>{get(item, `field-${firstFieldId}`, EMPTY_DATA)}</Typography>
            </Box>
            {!disabled && (
                <>
                    <IconButton onClick={toggleDeleteConfirmation}>
                        <DeleteSvgIcon />
                    </IconButton>
                    <DeleteClientConfirmationModal
                        isShowModal={isShowDeleteConfirmation}
                        toggleModal={toggleDeleteConfirmation}
                        onClickAction={onClickDelete}
                    />
                </>
            )}
        </Box>
    );
};

export default PopupContentRow;
