import React, {FC, ChangeEvent, useRef, useEffect, useContext, useState} from 'react';

import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';

import {FieldOptionType} from 'appRedux/actions/forms/types';

import {ClientFormContext} from 'contexts/clientForm/context';

import FormSelectorWrapper from 'pages/client/form/wrappers/FormSelectorWrapper';
import {FormFieldPropsType} from 'pages/client/form/partials/types';
import {getInitialFieldValue} from 'pages/client/form/helper';
import ImageOption from 'pages/client/form/partials/ImageOption';

const FormImageRadio: FC<FormFieldPropsType> = ({
    formId,
    isPopup,
    pageId,
    sectionId,
    field,
    clientInformation,
    handleChangeInformation,
    onPopupFieldChange,
    popupContent,
    disabled,
    previewMode,
    onSaveClicked,
}) => {
    const ref = useRef<HTMLDivElement | undefined>();

    const {errorField, setErrorField} = useContext(ClientFormContext);

    const [isInitialRender, setIsInitialRender] = useState<boolean>(true);
    const [isUpdated, setIsUpdated] = useState<boolean>(false);

    const initialFileOptions = field.fieldOptions || [];

    const initialValue = getInitialFieldValue(pageId, sectionId, field.id, clientInformation, isPopup, popupContent);

    const onRadioSelect = (e: ChangeEvent<{value: string}>) => {
        setErrorField && setErrorField(null);
        if (handleChangeInformation) {
            handleChangeInformation(pageId, sectionId, field.id, e.target.value);
            setIsInitialRender(false);
            setIsUpdated(previous => !previous);
        }
    };

    const handlePopupFieldChange = (e: ChangeEvent<{value: string}>) => {
        setErrorField && setErrorField(null);
        if (onPopupFieldChange) {
            onPopupFieldChange(field.id, e.target.value);
            setIsInitialRender(false);
            setIsUpdated(previous => !previous);
        }
    };

    useEffect(() => {
        if (field.id === errorField && ref && ref.current) {
            ref.current.scrollIntoView({behavior: 'smooth'});
        }
    }, [errorField]);

    useEffect(() => {
        if (!isInitialRender) {
            onSaveClicked();
        }
    }, [isUpdated]);

    return (
        <FormSelectorWrapper
            data-id={`input#form-selector-${formId}-${pageId}-${sectionId}-${field.id}`}
            wrapperRef={ref}
            field={field}
            formId={formId}
            previewMode={previewMode}
        >
            <RadioGroup value={initialValue}>
                <Box sx={{display: 'flex', direction: 'row', gap: 0.5, flexWrap: 'wrap'}}>
                    {initialFileOptions.map((file: FieldOptionType, index: number) => {
                        const isChecked = initialValue === file.uuid;
                        return (
                            <FormControlLabel
                                key={`radio-${index}`}
                                value={file.uuid}
                                disabled={disabled}
                                sx={{margin: 0}}
                                control={
                                    <Radio
                                        data-id={`input#form-selector-${formId}-${pageId}-${sectionId}-${field.id}-${index}`}
                                        checked={initialValue === file.uuid}
                                        onChange={isPopup ? handlePopupFieldChange : onRadioSelect}
                                        sx={{display: 'none'}}
                                    />
                                }
                                label={<ImageOption file={file} isChecked={isChecked} />}
                            />
                        );
                    })}
                </Box>
            </RadioGroup>
        </FormSelectorWrapper>
    );
};

export default FormImageRadio;
