import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {WorkflowStatusRuleApproverItemTypes} from 'appRedux/actions/workflow/types';

import ApproverListItem from 'pages/admin/updateWorkflow/partials/rules/ApproverListItem';

interface ApproversListType {
    items: WorkflowStatusRuleApproverItemTypes[];
}

const ApproversList: FC<ApproversListType> = ({items}) => {
    const [t] = useTranslation();

    if (items.length === 0) {
        return <Typography>{t('orguser.workflows.rules.noneApprovers')}</Typography>;
    }
    return (
        <Box>
            {items &&
                items.map((item: WorkflowStatusRuleApproverItemTypes) => {
                    return <ApproverListItem key={`approver-${item.uuid}`} item={item} />;
                })}
        </Box>
    );
};

export default ApproversList;
