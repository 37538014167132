import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import {GridColDef} from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import CopySvgIcon from 'assets/icons/buttons/CopySvgIcon';
import EditSvgIcon from 'assets/icons/buttons/EditSvgIcon';
import AttachSvgIcon from 'assets/icons/buttons/AttachSvgIcon';
import PlaySvgIcon from 'assets/icons/buttons/PlaySvgIcon';
import VisibilitySvgIcon from 'assets/icons/buttons/VisibilitySvgIcon';
import SendMailSvgIcon from 'assets/icons/buttons/SendMailSvgIcon';
import LockSvgIcon from 'assets/icons/buttons/LockSvgIcon';
import AddFileSvgIcon from 'assets/icons/buttons/AddFileSvgIcon';
import LangSvgIcon from 'assets/icons/buttons/LangSvgIcon';
import ListSvgIcon from 'assets/icons/buttons/ListSvgIcon';
import WorkflowsSvgIcon from 'assets/icons/buttons/WorkflowSvgIcon';
import NoEditSvgIcon from 'assets/icons/buttons/NoEditSvgIcon';
import AnonymousSvgIcon from 'assets/icons/buttons/UserRoleSvgIcon';

import {FORMS_COPY, FORM_CHANGE_STATUS, FORM_DELETE, FORM_PAUSE_OFF} from 'appRedux/actions/forms';
import {RootReducer} from 'appRedux/reducers';

import {AdminContext} from 'contexts/admin/context';
import {PermissionContext} from 'contexts/permission/context';
import {AlertContext} from 'contexts/alert/context';

import DeleteAdminConfirmationButton from 'components/AdminScreenComponents/DeleteAdminConfirmationButton';
import {CustomFeatureIcon, CustomSwitcher} from 'components/AgentScreenComponents/_form/CustomElements';
import MarkdownWithShowAll from 'components/TextWithShowAll/MarkdownWithShowAll';
import CustomTooltip from 'components/CustomTooltip/CustomTooltip';

import PauseReasonModal from 'pages/admin/formsList/PauseReasonModal';
import {truncate, getFormLanguagesListRow} from 'pages/admin/formsList/helper';
import {isMaximalActiveFormsNumberReached} from 'pages/admin/updateOrganization/partials/OrganizationQuotas/helper';
import ActiveFormsNumberQuotaAlertPopup from 'pages/admin/updateForm/partials/ActiveFormsNumberQuotaAlertPopup';

import {checkIsFormDeletingEnabled, checkIsFormUpdatingEnabled} from 'helpers/permissions';
import {getLanguageById} from 'helpers/translationsHelper';

import {ALERT_TYPE_SUCCESS, DATE_TIME_FORMAT, routes} from 'config/index';

const getFormsColumns = (): GridColDef[] | null => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {myOrganization} = useContext(AdminContext);
    const {
        isFormCreateEnabled,
        isFormDeleteEnabled,
        isFormEditEnabled,
        isFormViewEnabled,
        filtersFormViewAllowed,
        filtersFormEditAllowed,
        filtersFormDeleteAllowed,
        toggleIsTokenUpdated,
    } = useContext(PermissionContext);
    const {showAlert} = useContext(AlertContext);

    const {
        admin: {formsList, languageList},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    if (!myOrganization) return null;

    const {isEncryptionPossible, isInviteRegistration, isMultipleRequestPossible, isShowFormsList, language} =
        myOrganization;

    const organizationLanguage = getLanguageById(languageList, language);
    const organizationLanguageName = organizationLanguage ? organizationLanguage.name : '';

    return [
        {
            field: 'title',
            headerName: t('orguser.forms.title'),
            minWidth: 280,
            flex: 1,
            renderCell: ({value, row}) => {
                const {languages} = row;
                const availableLanguages = languages ? languages.filter(item => item.isAvailableForOrganization) : [];
                return (
                    <Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                            }}
                        >
                            <Tooltip
                                title={t(value)}
                                arrow
                                componentsProps={{
                                    tooltip: {
                                        sx: {
                                            backgroundColor: 'rgb(97, 97, 97)',
                                        },
                                    },
                                }}
                            >
                                <Typography variant="body2" sx={{fontSize: '1rem'}}>
                                    {`${truncate(value, 30)} ${row.isSubForm ? `(${t('orguser.forms.subForm')})` : ''}`}
                                </Typography>
                            </Tooltip>
                            {!row.isSubForm && (
                                <Tooltip title={row.workflowTitle || ''} arrow>
                                    <IconButton sx={{ml: 1, width: 20, height: 20, padding: 0}}>
                                        <WorkflowsSvgIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Box>
                        {availableLanguages.length > 0 && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'start',
                                    gap: 0.5,
                                }}
                            >
                                <LangSvgIcon />
                                <Typography sx={{fontSize: 12}}>
                                    {getFormLanguagesListRow(organizationLanguageName, availableLanguages)}
                                </Typography>
                            </Box>
                        )}
                        <Typography sx={{fontSize: 12}}>{moment(row.updatedAt).format(DATE_TIME_FORMAT)}</Typography>
                    </Box>
                );
            },
        },
        {
            field: 'description',
            headerName: t('orguser.forms.description'),
            minWidth: 300,
            flex: 4,
            renderCell: ({value}) => <MarkdownWithShowAll text={value} isTable />,
        },
        {
            field: 'formFeatures',
            headerName: t('orguser.forms.features'),
            width: 200,
            renderCell: ({row}) => (
                <>
                    {isInviteRegistration && (
                        <CustomTooltip title={t('orguser.forms.registrationByInvite')}>
                            <CustomFeatureIcon>
                                <SendMailSvgIcon isDisabled={!row.isOnlyInvite} />
                            </CustomFeatureIcon>
                        </CustomTooltip>
                    )}
                    {isEncryptionPossible && (
                        <CustomTooltip title={t('orguser.forms.isEncryptCases')}>
                            <CustomFeatureIcon>
                                <LockSvgIcon isDisabled={!row.isEncryptInfo} />
                            </CustomFeatureIcon>
                        </CustomTooltip>
                    )}
                    {isMultipleRequestPossible && (
                        <CustomTooltip title={t('orguser.forms.multipleRequests')}>
                            <CustomFeatureIcon>
                                <AddFileSvgIcon isDisabled={!row.isMultipleRequestPossible} />
                            </CustomFeatureIcon>
                        </CustomTooltip>
                    )}
                    {isShowFormsList && (
                        <CustomTooltip title={t('orguser.forms.isShowInList')}>
                            <CustomFeatureIcon>
                                <ListSvgIcon isDisabled={!row.isShowInList} />
                            </CustomFeatureIcon>
                        </CustomTooltip>
                    )}
                    <CustomTooltip title={t('orguser.forms.requestNotEditableAfterSubmit')}>
                        <CustomFeatureIcon>
                            <NoEditSvgIcon isDisabled={!row.noEditForm} />
                        </CustomFeatureIcon>
                    </CustomTooltip>
                    <CustomTooltip title={t('orguser.forms.isAnonymousRequestPossible')}>
                        <CustomFeatureIcon>
                            <AnonymousSvgIcon isDisabled={!row.isAnonymousRequestPossible} />
                        </CustomFeatureIcon>
                    </CustomTooltip>
                </>
            ),
        },
        {
            field: 'formActions',
            headerName: t('orguser.forms.action'),
            align: 'right',
            width: 210,
            renderCell: ({row}) => {
                const {id, isPaused, url, isActive} = row;
                const [isShowModal, setIsShowModal] = useState<boolean>(false);
                const toggleModal = () => {
                    setIsShowModal(previous => !previous);
                };

                const [isOpened, setIsOpened] = useState<boolean>(false);
                const [isFormActive, setIsFormActive] = useState<boolean>(isActive);

                const toggleIsOpened = () => {
                    setIsOpened(previous => !previous);
                };

                const toggleIsChecked = () => {
                    setIsFormActive(previous => !previous);
                };

                const changeFormStatus = useCallback(
                    data => dispatch({type: FORM_CHANGE_STATUS.REQUEST, payload: data}),
                    [dispatch],
                );

                useEffect(() => {
                    setIsFormActive(isActive);
                }, [isActive]);

                const handleChangeStatus = () => {
                    if (myOrganization) {
                        if (
                            !isFormActive &&
                            isMaximalActiveFormsNumberReached(myOrganization, activeForms.length + 1, false)
                        ) {
                            setIsOpened(true);
                        } else {
                            setIsFormActive(!isFormActive);
                            changeFormStatus({
                                id: row.id,
                                showAlert,
                                callbackError: () => setIsFormActive(false),
                            });
                        }
                    }
                };

                const activeForms = formsList ? formsList.filter(item => item.isActive) : [];

                const isViewEnabled = checkIsFormUpdatingEnabled(isFormViewEnabled, filtersFormViewAllowed, Number(id));
                const isEditEnabled = checkIsFormUpdatingEnabled(isFormEditEnabled, filtersFormEditAllowed, Number(id));
                const isDeleteEnabled = checkIsFormDeletingEnabled(
                    isFormDeleteEnabled,
                    filtersFormDeleteAllowed,
                    Number(id),
                );

                const redirectToFormEdit = () => {
                    navigate(`${routes.FORM}/${id}/edit`);
                };

                const redirectToFormView = () => {
                    navigate(`${routes.FORM}/${id}/view`);
                };

                const copyForm = useCallback(data => dispatch({type: FORMS_COPY.REQUEST, payload: data}), [dispatch]);
                const deleteForm = useCallback(
                    data => dispatch({type: FORM_DELETE.REQUEST, payload: data}),
                    [dispatch],
                );
                const formPauseOff = useCallback(
                    data => dispatch({type: FORM_PAUSE_OFF.REQUEST, payload: data}),
                    [dispatch],
                );

                const onClickDelete = () => {
                    deleteForm({
                        id,
                        callback: toggleModal,
                        showAlert,
                    });
                };

                const onClickCopy = () => {
                    copyForm({
                        ids: [id],
                        showAlert,
                        callback: toggleIsTokenUpdated,
                    });
                };

                const onPlayClicked = () => {
                    formPauseOff({
                        id,
                        showAlert,
                    });
                };

                const onClickCopyToBuffer = () => {
                    navigator.clipboard.writeText(`${window.location.origin}/${url}`);
                    showAlert(ALERT_TYPE_SUCCESS, t('messages.success.linkCopiedToBuffer'));
                };

                return (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            width: '100%',
                            alignItems: 'center',
                        }}
                    >
                        {isEditEnabled && (
                            <>
                                <CustomSwitcher
                                    checked={isFormActive}
                                    onClick={handleChangeStatus}
                                    disabled={
                                        isEditEnabled && myOrganization && formsList && !isActive
                                            ? isMaximalActiveFormsNumberReached(
                                                  myOrganization,
                                                  activeForms.length + 1,
                                                  true,
                                              )
                                            : false
                                    }
                                />

                                <ActiveFormsNumberQuotaAlertPopup
                                    formId={Number(row.id)}
                                    isOpened={isOpened}
                                    setIsOpened={setIsOpened}
                                    toggleIsOpened={toggleIsOpened}
                                    toggleIsChecked={toggleIsChecked}
                                    changeFormStatus={changeFormStatus}
                                />
                            </>
                        )}

                        {!isEditEnabled && isViewEnabled ? (
                            <IconButton
                                title={t('common.buttons.view')}
                                disabled={!isViewEnabled}
                                onClick={redirectToFormView}
                            >
                                <VisibilitySvgIcon isDisabled={!isViewEnabled} />
                            </IconButton>
                        ) : (
                            <IconButton
                                title={t('common.buttons.update')}
                                disabled={!isEditEnabled}
                                onClick={redirectToFormEdit}
                            >
                                <EditSvgIcon isDisabled={!isEditEnabled} />
                            </IconButton>
                        )}
                        {isPaused ? (
                            <IconButton
                                title={t('orguser.forms.pauseOff')}
                                onClick={onPlayClicked}
                                disabled={!isEditEnabled}
                            >
                                <PlaySvgIcon isDisabled={!isEditEnabled} />
                            </IconButton>
                        ) : (
                            <PauseReasonModal form={row} />
                        )}
                        <DeleteAdminConfirmationButton
                            disabled={!isDeleteEnabled || isActive}
                            isShowModal={isShowModal}
                            toggleModal={toggleModal}
                            onClickAction={onClickDelete}
                        />
                        <IconButton
                            title={t('common.buttons.copy')}
                            onClick={onClickCopy}
                            disabled={!isFormCreateEnabled}
                        >
                            <CopySvgIcon isDisabled={!isFormCreateEnabled} />
                        </IconButton>
                        <IconButton title={t('common.buttons.copyToBuffer')} onClick={onClickCopyToBuffer}>
                            <AttachSvgIcon />
                        </IconButton>
                    </Box>
                );
            },
        },
    ];
};

export default getFormsColumns;
