import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {GET_ORGANIZATION_LANGUAGES_LIST} from 'appRedux/actions/organizationLanguage';
import {LanguageItemTypes} from 'appRedux/actions/admin/types';
import {OrganizationLanguageTypes} from 'appRedux/actions/organizationLanguage/types';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import OrganizationLanguageRow from 'pages/admin/updateOrganization/partials/OrganizationLanguages/OrganizationLanguageRow';
import AddLanguageButton from 'pages/admin/updateOrganization/partials/OrganizationLanguages/AddLanguageButton';

import {DEFAULT_LANGUAGE} from 'config/index';

interface ComponentTypes {
    id: number;
    organizationLanguage: LanguageItemTypes;
}

const OrganizationLanguages: FC<ComponentTypes> = ({id, organizationLanguage}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const getOrganizationLanguages = useCallback(
        data => dispatch({type: GET_ORGANIZATION_LANGUAGES_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        admin: {organizationLanguages},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    useEffect(() => {
        getOrganizationLanguages({
            id,
            showAlert,
        });
    }, [id]);

    return (
        <Box>
            <Typography variant="body2" sx={{fontWeight: 300, mb: 1}}>
                {organizationLanguage && organizationLanguage.name !== DEFAULT_LANGUAGE
                    ? `${organizationLanguage.lang} (${t('common.default')})`
                    : t('orguser.forms.englishDefault')}
            </Typography>
            <Box sx={{pt: 1}}>
                {organizationLanguages &&
                    organizationLanguages.map((item: OrganizationLanguageTypes) => {
                        const {languageId, uuid, isLanguageEnabled} = item;
                        if (!isLanguageEnabled || languageId === organizationLanguage.id) {
                            return null;
                        }
                        return <OrganizationLanguageRow key={`organization-language-${uuid}`} item={item} />;
                    })}
                <Box sx={{mt: 2}}>
                    <AddLanguageButton organizationId={id} organizationLanguage={organizationLanguage} />
                </Box>
            </Box>
        </Box>
    );
};

export default OrganizationLanguages;
