import {useCallback, useContext, useEffect, useState} from 'react';
import {getDocument} from 'pdfjs-dist';

import {RequesterDocumentResponseType, RequesterSignatureResponseType} from 'appRedux/actions/profile/types';
import {FormPageTypes} from 'appRedux/actions/forms/types';

import {CaseKeyContext} from 'contexts/caseKey/context';

import {getFileData} from 'components/FilesListWithGallery/helper';

import {getEncryptedFileData} from 'helpers/cryptoApiHelper';

interface PdfRenderType {
    files: RequesterDocumentResponseType[];
    signatures: RequesterSignatureResponseType[];
    pages: FormPageTypes[];
}

const usePdfFileLoader = ({files, signatures, pages}: PdfRenderType) => {
    const {unwrappedCaseKey} = useContext(CaseKeyContext);

    const [loadedFiles, setLoadedFiles] = useState<boolean>(false);
    const [fileImages, setFileImages] = useState<Record<string, string | string[]>>({});

    const loadFiles = useCallback(async () => {
        await Promise.all(
            files.map(async file => {
                const {uuid, fileName, fileLink, encryptPrefix} = file;

                const fileNameArray = fileName ? fileName.split('.') : [];

                let isPdf = false;
                let isImage = false;

                if (fileNameArray.length > 1) {
                    const extension = fileNameArray[1].toLowerCase();
                    isPdf = extension === 'pdf';
                    isImage = ['jpg', 'jpeg', 'png'].includes(extension);
                }

                const renderPage = async data => {
                    const imagesList: string[] = [];
                    const canvas = document.createElement('canvas');
                    canvas.setAttribute('className', 'canv');
                    const pdf = await getDocument({data}).promise;
                    for (let i = 1; i <= pdf.numPages; i++) {
                        const page = await pdf.getPage(i);
                        const viewport = page.getViewport({scale: 1.5});
                        canvas.height = viewport.height;
                        canvas.width = viewport.width;
                        const renderContext = canvas.getContext('2d');
                        if (!renderContext) return;

                        const render_context = {
                            canvasContext: renderContext,
                            viewport: viewport,
                        };

                        await page.render(render_context).promise;
                        const img = canvas.toDataURL('image/jpeg');
                        imagesList.push(img);
                    }
                    setFileImages(prev => ({...prev, [uuid]: imagesList}));
                };

                if (fileLink && (isPdf || isImage)) {
                    let fileSource = '';

                    if (encryptPrefix) {
                        fileSource = (await getEncryptedFileData(fileLink, encryptPrefix, unwrappedCaseKey)) || '';
                    } else {
                        fileSource = (await getFileData(fileLink)) || '';
                    }

                    if (fileSource && isPdf) {
                        const data = atob((fileSource || '').replace(/.*base64,/, ''));
                        await renderPage(data);
                    }
                    if (fileSource && isImage) {
                        setFileImages(prev => ({...prev, [uuid]: fileSource}));
                    }
                }
            }),
        );
        setLoadedFiles(true);
    }, [files]);

    useEffect(() => {
        setLoadedFiles(false);
        loadFiles();
    }, [files]);

    const [loadedSigs, setLoadedSigs] = useState<boolean>(false);
    const [preparedSignatures, setPreparedSignatures] = useState<RequesterSignatureResponseType[]>(signatures);

    const loadSignatures = useCallback(async () => {
        const signaturesLoaded = await Promise.all(
            signatures.map(async sig => {
                const {fileLink} = sig;
                if (!fileLink) return sig;
                const fileSource = (await getFileData(fileLink)) || '';
                if (!fileSource) return sig;
                return {...sig, fileLink: fileSource};
            }),
        );
        setPreparedSignatures(signaturesLoaded);
        setLoadedSigs(true);
    }, [files]);

    useEffect(() => {
        setLoadedSigs(false);
        loadSignatures();
    }, [signatures]);

    const [loadedPages, setLoadedPages] = useState<boolean>(false);
    const [preparedPages, setPreparedPages] = useState<FormPageTypes[]>(pages);

    const loadPageFiles = useCallback(async () => {
        if (!pages?.length) return pages;
        const pagesLoaded = await Promise.all(
            pages.map(async page => {
                if (!page.sections?.length) return page;
                const sections = await Promise.all(
                    page.sections.map(async section => {
                        const fields = !section.fields.length
                            ? []
                            : await Promise.all(
                                  section.fields.map(async field => {
                                      if (!field.fieldOptions?.length) return field;

                                      const fieldFiles = await Promise.all(
                                          field.fieldOptions.map(async file => {
                                              const {fileLink} = file;
                                              if (!fileLink) return file;
                                              const fileSource = await getFileData(fileLink);
                                              if (!fileSource) return file;
                                              return {...file, fileLink: fileSource};
                                          }),
                                      );

                                      return {...field, fieldFiles};
                                  }),
                              );

                        const popups = !section.popups.length
                            ? []
                            : await Promise.all(
                                  section.popups.map(async popup => {
                                      if (!popup.fields?.length) return popup;
                                      const fields = await Promise.all(
                                          popup.fields.map(async field => {
                                              if (!field.fieldOptions?.length) return field;

                                              const fieldFiles = await Promise.all(
                                                  field.fieldOptions.map(async file => {
                                                      const {fileLink} = file;
                                                      if (!fileLink) return file;
                                                      const fileSource = (await getFileData(fileLink)) || '';
                                                      if (!fileSource) return file;
                                                      return {...file, fileLink: fileSource};
                                                  }),
                                              );

                                              return {...field, fieldFiles};
                                          }),
                                      );

                                      return {...popup, fields};
                                  }),
                              );

                        return {...section, fields, popups};
                    }),
                );

                return {...page, sections};
            }),
        );
        console.log('PAGES!', pages, preparedPages);
        setPreparedPages(pagesLoaded);
        setLoadedPages(true);
    }, [pages]);

    useEffect(() => {
        setLoadedPages(false);
        loadPageFiles();
    }, [pages]);

    return {loaded: loadedSigs || loadedFiles || loadedPages, fileImages, preparedSignatures, preparedPages};
};

export default usePdfFileLoader;
