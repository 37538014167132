import {
    GET_RESOURCE_FIELDS_LIST,
    CREATE_RESOURCE_FIELD,
    UPDATE_RESOURCE_FIELD,
    DELETE_RESOURCE_FIELD,
    GET_RESOURCE_FIELD_BUDGET_LIST,
    CREATE_RESOURCE_FIELD_BUDGET,
    UPDATE_RESOURCE_FIELD_BUDGET,
    DELETE_RESOURCE_FIELD_BUDGET,
    GET_RESOURCE_FIELD_SLOT_LIST,
    CREATE_RESOURCE_FIELD_SLOT,
    UPDATE_RESOURCE_FIELD_SLOT,
    DELETE_RESOURCE_FIELD_SLOT,
    GET_RESOURCE_FIELD_CALENDAR_LIST,
    GET_RESOURCE_FIELD_CALENDAR_INFO,
    CREATE_RESOURCE_FIELD_CALENDAR,
    UPDATE_RESOURCE_FIELD_CALENDAR,
    DELETE_RESOURCE_FIELD_CALENDAR,
    CREATE_RESOURCE_FIELD_BOOKFRAME,
    UPDATE_RESOURCE_FIELD_BOOKFRAME,
    DELETE_RESOURCE_FIELD_BOOKFRAME,
    CREATE_RESOURCE_FIELD_EXCEPTION,
    UPDATE_RESOURCE_FIELD_EXCEPTION,
    DELETE_RESOURCE_FIELD_EXCEPTION,
    CREATE_RESOURCE_FIELD_CALENDAR_SLOT,
    UPDATE_RESOURCE_FIELD_CALENDAR_SLOT,
    DELETE_RESOURCE_FIELD_CALENDAR_SLOT,
} from 'appRedux/actions/resourceFields';

import {
    ResourceFieldItemTypes,
    ResourceFieldBudgetItemTypes,
    ResourceFieldSlotItemTypes,
    ResourceFieldCalendarItemTypes,
} from 'appRedux/actions/resourceFields/types';

export interface ResourceFieldsState {
    isLoading: boolean;
    resourceFields: ResourceFieldItemTypes[];
    resourceFieldBudgetItems: ResourceFieldBudgetItemTypes[];
    resourceFieldSlotItems: ResourceFieldSlotItemTypes[];
    resourceFieldCalendarItems: ResourceFieldCalendarItemTypes[];
    currentCalendarItem: ResourceFieldCalendarItemTypes;
    error: IErrors | undefined;
}

export const initialState: ResourceFieldsState = {
    isLoading: false,
    resourceFields: [],
    resourceFieldBudgetItems: [],
    resourceFieldSlotItems: [],
    resourceFieldCalendarItems: [],
    currentCalendarItem: {
        title: '',
        description: '',
        bookframes: [],
        slots: [],
        exceptions: [],
        uuid: '',
    },
    error: undefined,
};

const resourceFields = (
    state = initialState,
    action: IActionType<
        | ResourceFieldItemTypes[]
        | ResourceFieldBudgetItemTypes[]
        | ResourceFieldSlotItemTypes[]
        | ResourceFieldCalendarItemTypes[]
        | ResourceFieldCalendarItemTypes
        | IErrors
    >,
): ResourceFieldsState => {
    switch (action.type) {
        case GET_RESOURCE_FIELDS_LIST.REQUEST:
        case CREATE_RESOURCE_FIELD.REQUEST:
        case UPDATE_RESOURCE_FIELD.REQUEST:
        case DELETE_RESOURCE_FIELD.REQUEST:
        case GET_RESOURCE_FIELD_BUDGET_LIST.REQUEST:
        case CREATE_RESOURCE_FIELD_BUDGET.REQUEST:
        case UPDATE_RESOURCE_FIELD_BUDGET.REQUEST:
        case DELETE_RESOURCE_FIELD_BUDGET.REQUEST:
        case GET_RESOURCE_FIELD_SLOT_LIST.REQUEST:
        case CREATE_RESOURCE_FIELD_SLOT.REQUEST:
        case UPDATE_RESOURCE_FIELD_SLOT.REQUEST:
        case DELETE_RESOURCE_FIELD_SLOT.REQUEST:
        case GET_RESOURCE_FIELD_CALENDAR_LIST.REQUEST:
        case GET_RESOURCE_FIELD_CALENDAR_INFO.REQUEST:
        case CREATE_RESOURCE_FIELD_CALENDAR.REQUEST:
        case UPDATE_RESOURCE_FIELD_CALENDAR.REQUEST:
        case DELETE_RESOURCE_FIELD_CALENDAR.REQUEST:
        case CREATE_RESOURCE_FIELD_BOOKFRAME.REQUEST:
        case UPDATE_RESOURCE_FIELD_BOOKFRAME.REQUEST:
        case DELETE_RESOURCE_FIELD_BOOKFRAME.REQUEST:
        case CREATE_RESOURCE_FIELD_EXCEPTION.REQUEST:
        case UPDATE_RESOURCE_FIELD_EXCEPTION.REQUEST:
        case DELETE_RESOURCE_FIELD_EXCEPTION.REQUEST:
        case CREATE_RESOURCE_FIELD_CALENDAR_SLOT.REQUEST:
        case UPDATE_RESOURCE_FIELD_CALENDAR_SLOT.REQUEST:
        case DELETE_RESOURCE_FIELD_CALENDAR_SLOT.REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: undefined,
            };
        }

        case GET_RESOURCE_FIELDS_LIST.SUCCESS:
        case CREATE_RESOURCE_FIELD.SUCCESS:
        case UPDATE_RESOURCE_FIELD.SUCCESS:
        case DELETE_RESOURCE_FIELD.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                resourceFields: action.payload as ResourceFieldItemTypes[],
            };
        }

        case GET_RESOURCE_FIELD_BUDGET_LIST.SUCCESS:
        case CREATE_RESOURCE_FIELD_BUDGET.SUCCESS:
        case UPDATE_RESOURCE_FIELD_BUDGET.SUCCESS:
        case DELETE_RESOURCE_FIELD_BUDGET.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                resourceFieldBudgetItems: action.payload as ResourceFieldBudgetItemTypes[],
            };
        }

        case GET_RESOURCE_FIELD_SLOT_LIST.SUCCESS:
        case CREATE_RESOURCE_FIELD_SLOT.SUCCESS:
        case UPDATE_RESOURCE_FIELD_SLOT.SUCCESS:
        case DELETE_RESOURCE_FIELD_SLOT.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                resourceFieldSlotItems: action.payload as ResourceFieldSlotItemTypes[],
            };
        }

        case GET_RESOURCE_FIELD_CALENDAR_LIST.SUCCESS:
        case CREATE_RESOURCE_FIELD_CALENDAR.SUCCESS:
        case DELETE_RESOURCE_FIELD_CALENDAR.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                resourceFieldCalendarItems: action.payload as ResourceFieldCalendarItemTypes[],
            };
        }

        case GET_RESOURCE_FIELD_CALENDAR_INFO.SUCCESS:
        case UPDATE_RESOURCE_FIELD_CALENDAR.SUCCESS:
        case CREATE_RESOURCE_FIELD_BOOKFRAME.SUCCESS:
        case UPDATE_RESOURCE_FIELD_BOOKFRAME.SUCCESS:
        case DELETE_RESOURCE_FIELD_BOOKFRAME.SUCCESS:
        case CREATE_RESOURCE_FIELD_EXCEPTION.SUCCESS:
        case UPDATE_RESOURCE_FIELD_EXCEPTION.SUCCESS:
        case DELETE_RESOURCE_FIELD_EXCEPTION.SUCCESS:
        case CREATE_RESOURCE_FIELD_CALENDAR_SLOT.SUCCESS:
        case UPDATE_RESOURCE_FIELD_CALENDAR_SLOT.SUCCESS:
        case DELETE_RESOURCE_FIELD_CALENDAR_SLOT.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                currentCalendarItem: action.payload as ResourceFieldCalendarItemTypes,
            };
        }

        case GET_RESOURCE_FIELDS_LIST.ERROR:
        case CREATE_RESOURCE_FIELD.ERROR:
        case UPDATE_RESOURCE_FIELD.ERROR:
        case DELETE_RESOURCE_FIELD.ERROR:
        case GET_RESOURCE_FIELD_BUDGET_LIST.ERROR:
        case CREATE_RESOURCE_FIELD_BUDGET.ERROR:
        case UPDATE_RESOURCE_FIELD_BUDGET.ERROR:
        case DELETE_RESOURCE_FIELD_BUDGET.ERROR:
        case GET_RESOURCE_FIELD_SLOT_LIST.ERROR:
        case CREATE_RESOURCE_FIELD_SLOT.ERROR:
        case UPDATE_RESOURCE_FIELD_SLOT.ERROR:
        case DELETE_RESOURCE_FIELD_SLOT.ERROR:
        case GET_RESOURCE_FIELD_CALENDAR_LIST.ERROR:
        case GET_RESOURCE_FIELD_CALENDAR_INFO.ERROR:
        case CREATE_RESOURCE_FIELD_CALENDAR.ERROR:
        case UPDATE_RESOURCE_FIELD_CALENDAR.ERROR:
        case DELETE_RESOURCE_FIELD_CALENDAR.ERROR:
        case CREATE_RESOURCE_FIELD_BOOKFRAME.ERROR:
        case UPDATE_RESOURCE_FIELD_BOOKFRAME.ERROR:
        case DELETE_RESOURCE_FIELD_BOOKFRAME.ERROR:
        case CREATE_RESOURCE_FIELD_EXCEPTION.ERROR:
        case UPDATE_RESOURCE_FIELD_EXCEPTION.ERROR:
        case DELETE_RESOURCE_FIELD_EXCEPTION.ERROR:
        case CREATE_RESOURCE_FIELD_CALENDAR_SLOT.ERROR:
        case UPDATE_RESOURCE_FIELD_CALENDAR_SLOT.ERROR:
        case DELETE_RESOURCE_FIELD_CALENDAR_SLOT.ERROR: {
            return {
                ...state,
                isLoading: false,
                error: (action.payload as IErrors) || {},
            };
        }

        default:
            return state;
    }
};

export default resourceFields;
