import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TableHead from '@mui/material/TableHead';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';

import {GET_WORKFLOWS_LIST} from 'appRedux/actions/workflow';
import {RootReducer} from 'appRedux/reducers';
import {WorkflowItemTypes} from 'appRedux/actions/workflow/types';
import {LanguageItemTypes} from 'appRedux/actions/admin/types';

import {AlertContext} from 'contexts/alert/context';
import {PermissionContext} from 'contexts/permission/context';

import WorkflowItemRow from 'pages/admin/workflows/WorkflowItemRow';

interface OrganizationWorkflowsType {
    organizationId: number;
    currentOrganizationLanguage?: LanguageItemTypes;
}

const OrganizationWorkflows: FC<OrganizationWorkflowsType> = ({organizationId, currentOrganizationLanguage}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);
    const {isOrganizationEditEnabled} = useContext(PermissionContext);

    const {
        profile: {profile},
        workflow: {organizationWorkflows},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const getWorkflowsList = useCallback(
        data => dispatch({type: GET_WORKFLOWS_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    useEffect(() => {
        getWorkflowsList({
            organizationId,
            showAlert,
        });
    }, []);

    if (!isOrganizationEditEnabled || !profile) {
        return null;
    }
    return (
        <Box>
            <Table>
                <TableHead>
                    <TableCell>
                        <Typography sx={{fontWeight: 600}}>{t('orguser.workflows.parameters.title')}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography sx={{fontWeight: 600}}>{t('orguser.workflows.parameters.statuses')}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography sx={{fontWeight: 600}}>{t('orguser.workflows.parameters.forms')}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography sx={{fontWeight: 600}}>{t('orguser.workflows.parameters.action')}</Typography>
                    </TableCell>
                </TableHead>
                <TableBody>
                    {organizationWorkflows.length > 0 ? (
                        organizationWorkflows.map((item: WorkflowItemTypes, index: number) => {
                            return (
                                <WorkflowItemRow
                                    key={`workflow-${index}`}
                                    item={item}
                                    currentUserLanguage={profile.lang}
                                    currentOrganizationLanguage={currentOrganizationLanguage}
                                />
                            );
                        })
                    ) : (
                        <TableRow>
                            <TableCell>
                                <Typography>{t('orguser.workflows.parameters.noneWorkflow')}</Typography>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </Box>
    );
};

export default OrganizationWorkflows;
