import React, {FC} from 'react';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';

import {RoleTypes} from 'appRedux/actions/roles/types';

import Permissions from 'pages/admin/roles/Permissions';

import {theme} from 'config/theme';

interface UserRoleType {
    role: RoleTypes;
}

const UserRole: FC<UserRoleType> = ({role}) => {
    const {title} = role;

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);

    const onRoleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <Box>
            <Typography sx={{mb: 1, cursor: 'pointer'}} onClick={onRoleClick}>
                {title}
            </Typography>
            <Popover
                PaperProps={{
                    sx: {
                        boxShadow: `0px 0px 5px rgba(0, 0, 0, 0.1)`,
                        backgroundColor: theme.palette.background.default,
                    },
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                onClose={handleClose}
            >
                <Box sx={{p: 2}}>
                    <Permissions item={role} />
                </Box>
            </Popover>
        </Box>
    );
};

export default UserRole;
