import {MdxEditorCustomButtonType} from 'components/MdxEditorField/helper';

export const customButtons: MdxEditorCustomButtonType[] = [
    {
        title: 'orguser.requesterCase.name',
        markdown: '{{ username }}',
    },
    {
        title: 'orguser.requesterCase.caseLink',
        markdown: '{{ url }}',
    },
    {
        title: 'orguser.requesterCase.caseLabel',
        markdown: '{{ caseLabel }}',
    },
    {
        title: 'orguser.requesterCase.status',
        markdown: '{{ statusTitle }}',
    },
];
