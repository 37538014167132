import React, {FC, ReactNode, useEffect, useContext} from 'react';
import {useSelector} from 'react-redux';
import get from 'lodash/get';
import {useNavigate} from 'react-router-dom';

import {RootReducer} from 'appRedux/reducers';

import {RouteContext} from 'contexts/route/context';

import StartScreenWrapper from 'components/StartScreenComponents/StartScreenWrapper';
import MainLogoWithLink from 'components/StartScreenComponents/MainLogoWithLink';
import LanguageSwitcher from 'components/StartScreenComponents/LanguageSwitcher';
import EmptyArea from 'components/EmptyArea';

import {getRedirectRoute} from 'pages/start/login/helper';

import {LOCAL_STORAGE_REDIRECT_AFTER_LOGIN} from 'services/localStorage';

import {TYPE_MULTI_ROLE_UNDEFINED} from 'config/index';

interface LoginPageWrapperType {
    withoutRedirect?: boolean;
    children: ReactNode;
}

const LoginPageWrapper: FC<LoginPageWrapperType> = ({withoutRedirect, children}) => {
    const navigate = useNavigate();

    const {isEmailUpdatePage} = useContext(RouteContext);

    const {
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    useEffect(() => {
        if (!withoutRedirect) {
            const isTwoFactorActivated = get(profile, 'isTwoFactorActivated', false);
            const isTwoFactorCompleted = get(profile, 'isTwoFactorCompleted', false);
            if (isTwoFactorActivated && !isTwoFactorCompleted) {
                return;
            }
            const roles = get(profile, 'roles', []);
            if (roles.length > 0) {
                const redirectAfterLogin = localStorage.getItem(LOCAL_STORAGE_REDIRECT_AFTER_LOGIN);
                if (redirectAfterLogin) {
                    navigate(redirectAfterLogin);
                    localStorage.removeItem(LOCAL_STORAGE_REDIRECT_AFTER_LOGIN);
                } else if (!isEmailUpdatePage) {
                    const organizationMultiRoleType = get(
                        profile,
                        'organizationMultiRoleType',
                        TYPE_MULTI_ROLE_UNDEFINED,
                    );
                    const defaultMode = get(profile, 'typeMultiRole', TYPE_MULTI_ROLE_UNDEFINED);
                    const route = getRedirectRoute(roles, organizationMultiRoleType, defaultMode);
                    navigate(route);
                }
            }
        }
    }, [profile]);

    return (
        <StartScreenWrapper>
            <MainLogoWithLink />
            <LanguageSwitcher />
            <EmptyArea />
            {children}
        </StartScreenWrapper>
    );
};

export default LoginPageWrapper;
