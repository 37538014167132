import React, {FC, useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';

import {ResourceFieldBudgetFormTypes} from 'appRedux/actions/resourceFields/types';
import {CREATE_RESOURCE_FIELD_BUDGET} from 'appRedux/actions/resourceFields';

import {AlertContext} from 'contexts/alert/context';

import ResourceFieldBudgetForm from 'components/Forms/ResourceField/ResourceFieldBudgetForm';

interface CreateResourceFieldBudgetFormType {
    uuid: string;
    closeModal: () => void;
}

const CreateResourceFieldBudgetForm: FC<CreateResourceFieldBudgetFormType> = ({uuid, closeModal}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const createResourceFieldBudget = useCallback(
        data => dispatch({type: CREATE_RESOURCE_FIELD_BUDGET.REQUEST, payload: data}),
        [dispatch],
    );

    const onSubmitClicked = (values: ResourceFieldBudgetFormTypes) => {
        createResourceFieldBudget({
            ...values,
            uuid,
            showAlert,
            callback: closeModal,
        });
    };

    return (
        <ResourceFieldBudgetForm
            initialValues={{
                title: '',
                description: '',
                type: 0,
                amount: 0,
                resetInterval: 0,
            }}
            onSubmitClicked={onSubmitClicked}
        />
    );
};

export default CreateResourceFieldBudgetForm;
