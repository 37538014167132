import React, {FC, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';

import {RootReducer} from 'appRedux/reducers';

import {RouteContext} from 'contexts/route/context';

import TwoFactorForm from 'components/Forms/SettingsForms/TwoFactorForm/TwoFactorForm';
import UpdatePasswordForm from 'components/Forms/SettingsForms/UpdatePasswordForm/UpdatePasswordForm';
import ProfileSectionWrapper from 'components/AgentScreenComponents/_wrappers/ProfileSectionWrapper';
import UserSessionsList from 'components/UserSessionsList/UserSessionsList';
import PublicKeysList from 'components/PublicKeysList/PublicKeysList';
import ClientSectionWrapper from 'components/ClientScreenComponents/SectionWrapper';

const SecurityPage: FC = () => {
    const [t] = useTranslation();

    const {setPageTitle, setBackLink, setTopBarTitle, setBackTab, setBackLinkLabel} = useContext(RouteContext);

    const {
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    useEffect(() => {
        setPageTitle(t('common.menu.security'));
        setTopBarTitle('');
        setBackLink('');
        setBackTab('');
        setBackLinkLabel('');
    }, []);

    if (!profile) return null;

    return (
        <Box>
            <ProfileSectionWrapper identifier="changeYourPassword" title={t('common.security.changeYourPassword')}>
                <UpdatePasswordForm />
            </ProfileSectionWrapper>
            <ClientSectionWrapper toggleIdentifier="twoFactorAuthTitle" title={t('common.security.twoFactorAuthTitle')}>
                <TwoFactorForm />
            </ClientSectionWrapper>
            <ClientSectionWrapper toggleIdentifier="openedSessions" title={t('common.security.openedSessions')}>
                <UserSessionsList />
            </ClientSectionWrapper>
            {profile.isAdvancedMode && (
                <ClientSectionWrapper toggleIdentifier="publicKeys" title={t('common.security.publicKeys')}>
                    <PublicKeysList />
                </ClientSectionWrapper>
            )}
        </Box>
    );
};

export default SecurityPage;
