import React, {FC} from 'react';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';

import {ResourceFieldFormTypes} from 'appRedux/actions/resourceFields/types';
import {WorkflowItemTypes} from 'appRedux/actions/workflow/types';
import {RootReducer} from 'appRedux/reducers';

import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import {validationSchema} from 'components/Forms/ResourceField/validation';
import {
    resourceFieldTypes,
    resourceFieldScopeTypes,
    ResourceFieldOptionType,
    isImpossibleForSelectedScopeType,
} from 'components/Forms/ResourceField/helper';

interface ResourceFieldFormType {
    initialValues: ResourceFieldFormTypes;
    onSubmitClicked: (values: ResourceFieldFormTypes) => void;
    isCreate?: boolean;
}

const ResourceFieldForm: FC<ResourceFieldFormType> = ({initialValues, onSubmitClicked, isCreate}) => {
    const [t] = useTranslation();

    const {
        workflow: {organizationWorkflows},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmitClicked}
        >
            {(formik: FormikProps<ResourceFieldFormTypes>) => {
                return (
                    <Form>
                        <Field
                            required
                            name="title"
                            placeholder={t('orguser.resourceFields.title')}
                            component={FormikTextInput}
                            onBlur={() => {
                                if (!isCreate) {
                                    formik.submitForm();
                                }
                            }}
                        />
                        <FormControl sx={{mt: 1}} required fullWidth>
                            <InputLabel>{t('orguser.resourceFields.workflow')}</InputLabel>
                            <Select
                                label={t('orguser.resourceFields.workflow')}
                                name={'workflow'}
                                value={formik.values.workflow}
                                onChange={e => {
                                    formik.handleChange(e);
                                    if (!isCreate) {
                                        formik.submitForm();
                                    }
                                }}
                                IconComponent={KeyboardArrowDownIcon}
                                fullWidth
                            >
                                {organizationWorkflows &&
                                    organizationWorkflows.map((item: WorkflowItemTypes, index: number) => {
                                        return (
                                            <MenuItem key={`workflow-${index}`} value={item.id}>
                                                <Typography sx={{fontWeight: 600}}>{item.title}</Typography>
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                        </FormControl>
                        <FormControl sx={{mt: 2}} required fullWidth>
                            <InputLabel>{t('orguser.resourceFields.scope')}</InputLabel>
                            <Select
                                label={t('orguser.resourceFields.scope')}
                                name={'scope'}
                                value={formik.values.scope}
                                onChange={formik.handleChange}
                                IconComponent={KeyboardArrowDownIcon}
                                disabled={!isCreate}
                                fullWidth
                            >
                                {resourceFieldScopeTypes &&
                                    resourceFieldScopeTypes.map((item: ResourceFieldOptionType) => {
                                        const {label, value} = item;
                                        return (
                                            <MenuItem key={`scope-${value}`} value={value}>
                                                <Typography sx={{fontWeight: 600}}>{t(label)}</Typography>
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                        </FormControl>
                        <FormControl sx={{mt: 2}} required fullWidth>
                            <InputLabel>{t('orguser.resourceFields.type')}</InputLabel>
                            <Select
                                label={t('orguser.resourceFields.type')}
                                name={'type'}
                                value={formik.values.type}
                                onChange={formik.handleChange}
                                IconComponent={KeyboardArrowDownIcon}
                                disabled={!isCreate}
                                fullWidth
                            >
                                {resourceFieldTypes &&
                                    resourceFieldTypes.map((item: ResourceFieldOptionType) => {
                                        const {label, value} = item;
                                        if (isImpossibleForSelectedScopeType(formik.values.scope, value)) {
                                            return null;
                                        }
                                        return (
                                            <MenuItem key={`type-${value}`} value={value}>
                                                <Typography sx={{fontWeight: 600}}>{t(label)}</Typography>
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                        </FormControl>
                        {isCreate && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    mt: 3,
                                }}
                            >
                                <AgentSaveButton isSubmit />
                            </Box>
                        )}
                    </Form>
                );
            }}
        </Formik>
    );
};

export default ResourceFieldForm;
