import React from 'react';

export enum CryptoModalType {
    NEW = 'NEW',
    IMPORT = 'IMPORT',
}

export interface KeysType {
    publicKey: CryptoKey;
    privateKey: CryptoKey;
    name: string;
    uuid: string;
}

export type CryptoContextType = {
    keys: KeysType | null;
    readWrapperKeys: (userId: number | string, orgId: string) => void;
    generateWrapperKeys: (wrapperKeyName: string, redirectTo?: string) => void;
    regenerateKeysAfterLogin: (redirectTo?: string) => void;
};

export const CryptoContext = React.createContext({} as CryptoContextType);
