import React, {FC} from 'react';

import {RoleTypes} from 'appRedux/actions/roles/types';

import {
    FILTER_FOR_CASES_OPERATIONS,
    FILTER_FOR_FORMS_OPERATIONS,
    FILTER_FOR_INVITES_OPERATIONS,
    FILTER_FOR_TRANSLATIONS_OPERATIONS,
} from 'components/Forms/RoleForm/helper';

import PermissionItem from 'pages/admin/roles/PermissionItem';

interface PermissionsType {
    item: RoleTypes;
}

const Permissions: FC<PermissionsType> = ({item}) => {
    const {
        isOrganizationEditEnabled,
        isOrganizationViewEnabled,
        isRoleViewEnabled,
        isRoleCreateEnabled,
        isRoleDeleteEnabled,
        isRoleEditEnabled,
        isUserViewEnabled,
        isUserCreateEnabled,
        isUserDeleteEnabled,
        isUserEditEnabled,
        isFormViewEnabled,
        isFormCreateEnabled,
        isFormDeleteEnabled,
        isFormEditEnabled,
        isTranslationViewEnabled,
        isTranslationDeleteEnabled,
        isTranslationEditEnabled,
        isInviteViewEnabled,
        isInviteCreateEnabled,
        isInviteDeleteEnabled,
        isCaseViewEnabled,
        isCaseEditEnabled,
        roleFilters,
    } = item;

    return (
        <>
            <PermissionItem
                title={'organization'}
                hasView={isOrganizationViewEnabled}
                hasUpdate={isOrganizationEditEnabled}
            />
            <PermissionItem
                title={'role'}
                hasView={isRoleViewEnabled}
                hasCreate={isRoleCreateEnabled}
                hasUpdate={isRoleEditEnabled}
                hasDelete={isRoleDeleteEnabled}
            />
            <PermissionItem
                title={'user'}
                hasView={isUserViewEnabled}
                hasCreate={isUserCreateEnabled}
                hasUpdate={isUserEditEnabled}
                hasDelete={isUserDeleteEnabled}
            />
            <PermissionItem
                title={'form'}
                hasView={isFormViewEnabled}
                hasCreate={isFormCreateEnabled}
                hasUpdate={isFormEditEnabled}
                hasDelete={isFormDeleteEnabled}
                filterType={FILTER_FOR_FORMS_OPERATIONS}
                roleFilters={roleFilters}
            />
            <PermissionItem
                title={'translation'}
                hasView={isTranslationViewEnabled}
                hasUpdate={isTranslationEditEnabled}
                hasDelete={isTranslationDeleteEnabled}
                filterType={FILTER_FOR_TRANSLATIONS_OPERATIONS}
                roleFilters={roleFilters}
            />
            <PermissionItem
                title={'invites'}
                hasView={isInviteViewEnabled}
                hasCreate={isInviteCreateEnabled}
                hasDelete={isInviteDeleteEnabled}
                filterType={FILTER_FOR_INVITES_OPERATIONS}
                roleFilters={roleFilters}
            />
            <PermissionItem
                title={'cases'}
                hasView={isCaseViewEnabled}
                hasUpdate={isCaseEditEnabled}
                filterType={FILTER_FOR_CASES_OPERATIONS}
                roleFilters={roleFilters}
            />
        </>
    );
};

export default Permissions;
