import React, {FC} from 'react';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';

import {ResourceFieldBudgetFormTypes} from 'appRedux/actions/resourceFields/types';

import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import MdxEditorSmallField from 'components/MdxEditorField/MdxEditorSmallField';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import {validationBudgetSchema} from 'components/Forms/ResourceField/validation';
import {
    resourceFieldBudgetTypes,
    resourceFieldBudgetResetIntervalTypes,
    ResourceFieldOptionType,
} from 'components/Forms/ResourceField/helper';

interface ResourceFieldBudgetFormType {
    initialValues: ResourceFieldBudgetFormTypes;
    onSubmitClicked: (values: ResourceFieldBudgetFormTypes) => void;
}

const ResourceFieldBudgetForm: FC<ResourceFieldBudgetFormType> = ({initialValues, onSubmitClicked}) => {
    const [t] = useTranslation();

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationBudgetSchema}
            onSubmit={onSubmitClicked}
        >
            {(formik: FormikProps<ResourceFieldBudgetFormTypes>) => {
                return (
                    <Form>
                        <Field
                            required
                            name="title"
                            placeholder={t('orguser.resourceFields.title')}
                            component={FormikTextInput}
                        />
                        <Box sx={{mt: 1}}>
                            <MdxEditorSmallField
                                name="description"
                                value={formik.values.description ? String(formik.values.description) : ''}
                                label={t('orguser.resourceFields.description')}
                            />
                        </Box>
                        <FormControl sx={{mt: 2}} required fullWidth>
                            <InputLabel>{t('orguser.resourceFields.type')}</InputLabel>
                            <Select
                                label={t('orguser.resourceFields.type')}
                                name={'type'}
                                value={formik.values.type}
                                onChange={formik.handleChange}
                                IconComponent={KeyboardArrowDownIcon}
                                fullWidth
                            >
                                {resourceFieldBudgetTypes &&
                                    resourceFieldBudgetTypes.map((item: ResourceFieldOptionType) => {
                                        const {label, value} = item;
                                        return (
                                            <MenuItem key={`type-${value}`} value={value}>
                                                <Typography sx={{fontWeight: 600}}>{t(label)}</Typography>
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                        </FormControl>
                        <Box sx={{mt: 1, mb: 1}}>
                            <Field
                                required
                                type="number"
                                name="amount"
                                placeholder={t('orguser.resourceFields.amount')}
                                component={FormikTextInput}
                            />
                        </Box>
                        <FormControl sx={{mt: 2}} required fullWidth>
                            <InputLabel>{t('orguser.resourceFields.resetInterval')}</InputLabel>
                            <Select
                                label={t('orguser.resourceFields.resetInterval')}
                                name={'resetInterval'}
                                value={formik.values.resetInterval}
                                onChange={formik.handleChange}
                                IconComponent={KeyboardArrowDownIcon}
                                fullWidth
                            >
                                {resourceFieldBudgetResetIntervalTypes &&
                                    resourceFieldBudgetResetIntervalTypes.map((item: ResourceFieldOptionType) => {
                                        const {label, value} = item;
                                        return (
                                            <MenuItem key={`resetInterval-${value}`} value={value}>
                                                <Typography sx={{fontWeight: 600}}>{t(label)}</Typography>
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                        </FormControl>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                mt: 3,
                            }}
                        >
                            <AgentSaveButton isSubmit />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default ResourceFieldBudgetForm;
