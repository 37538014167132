import React, {FC, useCallback, useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import i18n from 'i18next';
import {useTranslation} from 'react-i18next';

import Select, {SelectChangeEvent} from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import {FormLanguageTypes} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';
import {SET_LANGUAGE} from 'appRedux/actions/profile';

import {MediaContext} from 'contexts/media/context';

import LanguageDropdownMobileSwitcher from 'pages/client/formsList/LanguageDropdownMobileSwitcher';

import {getLanguageById} from 'helpers/translationsHelper';

import {LOCAL_STORAGE_CURRENT_LANGUAGE} from 'services/localStorage';

import {DEFAULT_LANGUAGE} from 'config/index';

const LanguageDropdownSwitcher: FC = () => {
    const dispatch = useDispatch();
    const [t] = useTranslation();

    const {isMobile} = useContext(MediaContext);

    const [language, setLanguage] = useState<string>(DEFAULT_LANGUAGE);

    const {
        admin: {languageList},
        auth: {organization},
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const currentProfileLanguage = profile ? getLanguageById(languageList, profile.languageId).name : DEFAULT_LANGUAGE;

    const updateLanguage = useCallback(data => dispatch({type: SET_LANGUAGE.REQUEST, payload: data}), [dispatch]);

    const onLanguageSelect = (event: SelectChangeEvent) => {
        const value = event.target.value as string;
        onLanguageChange(value);
    };

    const onLanguageChange = (value: string) => {
        setLanguage(value);
        i18n.changeLanguage(value);
        localStorage.setItem(LOCAL_STORAGE_CURRENT_LANGUAGE, value);
        updateLanguage({lang: value});
    };

    useEffect(() => {
        if (profile && currentProfileLanguage) {
            setLanguage(currentProfileLanguage);
        }
    }, [profile]);

    const organizationLanguages = organization ? organization.availableLanguagesList : [];

    if (isMobile) {
        return (
            <LanguageDropdownMobileSwitcher
                organizationLanguages={organizationLanguages}
                currentProfileLanguage={currentProfileLanguage}
                onLanguageChange={onLanguageChange}
            />
        );
    }
    return (
        <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <Typography sx={{mr: 1}}>{`${t('common.translateTo')}:`}</Typography>
            <FormControl>
                <Select name="status" variant="standard" fullWidth value={language} onChange={onLanguageSelect}>
                    <MenuItem key={`language-default`} value={currentProfileLanguage}>
                        <Typography>{currentProfileLanguage}</Typography>
                    </MenuItem>
                    {organizationLanguages &&
                        organizationLanguages.map((item: FormLanguageTypes) => {
                            const {name, enabled} = item;
                            if (name !== currentProfileLanguage && enabled) {
                                return (
                                    <MenuItem key={`language-${name}`} value={name}>
                                        <Typography>{name}</Typography>
                                    </MenuItem>
                                );
                            }
                            return null;
                        })}
                </Select>
            </FormControl>
        </Box>
    );
};

export default LanguageDropdownSwitcher;
