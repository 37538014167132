import get from 'lodash/get';
import {TFunction} from 'i18next';

import {GeneralTemplateTypes, GeneralTemplateDefaultTypes} from 'appRedux/actions/generalTemplates/types';

export const TEMPLATE_ACCOUNT_DELETING = 0;
export const TEMPLATE_AGENT_ASSIGNED = 1;
export const TEMPLATE_AGENT_CHAT_MESSAGE = 2;
export const TEMPLATE_INVITE_REGISTRATION = 3;
export const TEMPLATE_ORGANIZATION_ACCOUNT_CREATED = 4;
export const TEMPLATE_PASSWORD_RECOVERY = 5;
export const TEMPLATE_VERIFICATION = 7;
export const TEMPLATE_FORGET_NICKNAME = 8;
export const TEMPLATE_TFA_DEACTIVATED = 9;
export const TEMPLATE_ORGANIZATIONS_STATISTIC_REPORT = 10;
export const TEMPLATE_CASES_QUOTA_HARD = 11;
export const TEMPLATE_CASES_QUOTA_SOFT = 12;
export const TEMPLATE_USER_ACCOUNT_REPORT = 13;

export const TEMPLATE_TYPES_NUMBER = 14;

export const TEMPLATE_ACCOUNT_DELETING_LABEL = 'accountDeletingRequest';
export const TEMPLATE_AGENT_ASSIGNED_LABEL = 'agentAssignedToCase';
export const TEMPLATE_AGENT_CHAT_MESSAGE_LABEL = 'agentGetChatMessage';
export const TEMPLATE_INVITE_REGISTRATION_LABEL = 'inviteForRegistration';
export const TEMPLATE_ORGANIZATION_ACCOUNT_CREATED_LABEL = 'orgUserAccountCreated';
export const TEMPLATE_PASSWORD_RECOVERY_LABEL = 'accountPasswordReset';
export const TEMPLATE_VERIFICATION_LABEL = 'userVerification';
export const TEMPLATE_FORGET_NICKNAME_LABEL = 'forgetNickname';
export const TEMPLATE_TFA_DEACTIVATED_LABEL = 'twoFactorDeactivation';
export const TEMPLATE_ORGANIZATIONS_STATISTIC_REPORT_LABEL = 'organizationStatisticReport';
export const TEMPLATE_CASES_QUOTA_HARD_LABEL = 'requesterCasesQuotaHard';
export const TEMPLATE_CASES_QUOTA_SOFT_LABEL = 'requesterCasesQuotaSoft';
export const TEMPLATE_USER_ACCOUNT_REPORT_LABEL = 'userAccountExport';

const TYPES_ARRAY = [
    TEMPLATE_ACCOUNT_DELETING_LABEL,
    TEMPLATE_AGENT_ASSIGNED_LABEL,
    TEMPLATE_AGENT_CHAT_MESSAGE_LABEL,
    TEMPLATE_INVITE_REGISTRATION_LABEL,
    TEMPLATE_ORGANIZATION_ACCOUNT_CREATED_LABEL,
    TEMPLATE_PASSWORD_RECOVERY_LABEL,
    TEMPLATE_VERIFICATION_LABEL,
    TEMPLATE_FORGET_NICKNAME_LABEL,
    TEMPLATE_TFA_DEACTIVATED_LABEL,
    TEMPLATE_ORGANIZATIONS_STATISTIC_REPORT_LABEL,
    TEMPLATE_CASES_QUOTA_HARD_LABEL,
    TEMPLATE_CASES_QUOTA_SOFT_LABEL,
    TEMPLATE_USER_ACCOUNT_REPORT_LABEL,
];

export const getDefaultGeneralTemplates = (
    defaultTemplates: GeneralTemplateDefaultTypes,
    languageName: string,
    language: number,
): GeneralTemplateTypes[] => [
    {
        type: TEMPLATE_ACCOUNT_DELETING,
        subject: String(get(defaultTemplates, [languageName, `${TEMPLATE_ACCOUNT_DELETING_LABEL}.subject`], '')),
        text: String(get(defaultTemplates, [languageName, `${TEMPLATE_ACCOUNT_DELETING_LABEL}.text`], '')),
        language,
        isActive: false,
    },
    {
        type: TEMPLATE_AGENT_ASSIGNED,
        subject: String(get(defaultTemplates, [languageName, `${TEMPLATE_AGENT_ASSIGNED_LABEL}.subject`], '')),
        text: String(get(defaultTemplates, [languageName, `${TEMPLATE_AGENT_ASSIGNED_LABEL}.text`], '')),
        language,
        isActive: false,
    },
    {
        type: TEMPLATE_AGENT_CHAT_MESSAGE,
        subject: String(get(defaultTemplates, [languageName, `${TEMPLATE_AGENT_CHAT_MESSAGE_LABEL}.subject`], '')),
        text: String(get(defaultTemplates, [languageName, `${TEMPLATE_AGENT_CHAT_MESSAGE_LABEL}.text`], '')),
        language,
        isActive: false,
    },
    {
        type: TEMPLATE_INVITE_REGISTRATION,
        subject: String(get(defaultTemplates, [languageName, `${TEMPLATE_INVITE_REGISTRATION_LABEL}.subject`], '')),
        text: String(get(defaultTemplates, [languageName, `${TEMPLATE_INVITE_REGISTRATION_LABEL}.text`], '')),
        language,
        isActive: false,
    },
    {
        type: TEMPLATE_ORGANIZATION_ACCOUNT_CREATED,
        subject: String(
            get(defaultTemplates, [languageName, `${TEMPLATE_ORGANIZATION_ACCOUNT_CREATED_LABEL}.subject`], ''),
        ),
        text: String(get(defaultTemplates, [languageName, `${TEMPLATE_ORGANIZATION_ACCOUNT_CREATED_LABEL}.text`], '')),
        language,
        isActive: false,
    },
    {
        type: TEMPLATE_PASSWORD_RECOVERY,
        subject: String(get(defaultTemplates, [languageName, `${TEMPLATE_PASSWORD_RECOVERY_LABEL}.subject`], '')),
        text: String(get(defaultTemplates, [languageName, `${TEMPLATE_PASSWORD_RECOVERY_LABEL}.text`], '')),
        language,
        isActive: false,
    },
    {
        type: TEMPLATE_VERIFICATION,
        subject: String(get(defaultTemplates, [languageName, `${TEMPLATE_VERIFICATION_LABEL}.subject`], '')),
        text: String(get(defaultTemplates, [languageName, `${TEMPLATE_VERIFICATION_LABEL}.text`], '')),
        language,
        isActive: false,
    },
    {
        type: TEMPLATE_FORGET_NICKNAME,
        subject: String(get(defaultTemplates, [languageName, `${TEMPLATE_FORGET_NICKNAME_LABEL}.subject`], '')),
        text: String(get(defaultTemplates, [languageName, `${TEMPLATE_FORGET_NICKNAME_LABEL}.text`], '')),
        language,
        isActive: false,
    },
    {
        type: TEMPLATE_TFA_DEACTIVATED,
        subject: String(get(defaultTemplates, [languageName, `${TEMPLATE_TFA_DEACTIVATED_LABEL}.subject`], '')),
        text: String(get(defaultTemplates, [languageName, `${TEMPLATE_TFA_DEACTIVATED_LABEL}.text`], '')),
        language,
        isActive: false,
    },
    {
        type: TEMPLATE_ORGANIZATIONS_STATISTIC_REPORT,
        subject: String(
            get(defaultTemplates, [languageName, `${TEMPLATE_ORGANIZATIONS_STATISTIC_REPORT_LABEL}.subject`], ''),
        ),
        text: String(
            get(defaultTemplates, [languageName, `${TEMPLATE_ORGANIZATIONS_STATISTIC_REPORT_LABEL}.text`], ''),
        ),
        language,
        isActive: false,
    },
    {
        type: TEMPLATE_CASES_QUOTA_HARD,
        subject: String(get(defaultTemplates, [languageName, `${TEMPLATE_CASES_QUOTA_HARD_LABEL}.subject`], '')),
        text: String(get(defaultTemplates, [languageName, `${TEMPLATE_CASES_QUOTA_HARD_LABEL}.text`], '')),
        language,
        isActive: false,
    },
    {
        type: TEMPLATE_CASES_QUOTA_SOFT,
        subject: String(get(defaultTemplates, [languageName, `${TEMPLATE_CASES_QUOTA_SOFT_LABEL}.subject`], '')),
        text: String(get(defaultTemplates, [languageName, `${TEMPLATE_CASES_QUOTA_SOFT_LABEL}.text`], '')),
        language,
        isActive: false,
    },
    {
        type: TEMPLATE_USER_ACCOUNT_REPORT,
        subject: String(get(defaultTemplates, [languageName, `${TEMPLATE_USER_ACCOUNT_REPORT_LABEL}.subject`], '')),
        text: String(get(defaultTemplates, [languageName, `${TEMPLATE_USER_ACCOUNT_REPORT_LABEL}.text`], '')),
        language,
        isActive: false,
    },
];

export const getTemplateTypeLabel = (t: TFunction, type: number): string => {
    switch (type) {
        case TEMPLATE_ACCOUNT_DELETING:
            return t('superadmin.mailer.types.accountDeletingRequest');
        case TEMPLATE_AGENT_ASSIGNED:
            return t('superadmin.mailer.types.agentAssignedToCase');
        case TEMPLATE_AGENT_CHAT_MESSAGE:
            return t('superadmin.mailer.types.agentGetChatMessage');
        case TEMPLATE_INVITE_REGISTRATION:
            return t('superadmin.mailer.types.inviteForRegistration');
        case TEMPLATE_ORGANIZATION_ACCOUNT_CREATED:
            return t('superadmin.mailer.types.orgUserAccountCreated');
        case TEMPLATE_PASSWORD_RECOVERY:
            return t('superadmin.mailer.types.accountPasswordReset');
        case TEMPLATE_VERIFICATION:
            return t('superadmin.mailer.types.userVerification');
        case TEMPLATE_FORGET_NICKNAME:
            return t('superadmin.mailer.types.forgetNickname');
        case TEMPLATE_TFA_DEACTIVATED:
            return t('superadmin.mailer.types.twoFactorDeactivation');
        case TEMPLATE_ORGANIZATIONS_STATISTIC_REPORT:
            return t('superadmin.mailer.types.organizationStatisticReport');
        case TEMPLATE_CASES_QUOTA_HARD:
            return t('superadmin.mailer.types.requesterCasesQuotaHard');
        case TEMPLATE_CASES_QUOTA_SOFT:
            return t('superadmin.mailer.types.requesterCasesQuotaSoft');
        case TEMPLATE_USER_ACCOUNT_REPORT:
            return t('superadmin.mailer.types.userAccountExport');
        default:
            return t('common.none');
    }
};

export const getCurrentTemplates = (
    defaultTemplates: GeneralTemplateTypes[],
    templates: GeneralTemplateTypes[],
    language: number,
): GeneralTemplateTypes[] => {
    const results: GeneralTemplateTypes[] = [];
    defaultTemplates.forEach((defaultTemplate: GeneralTemplateTypes) => {
        const item = templates.find(
            (item: GeneralTemplateTypes) => item.type === defaultTemplate.type && item.language === language,
        );
        if (item) {
            results.push(item);
        } else {
            results.push(defaultTemplate);
        }
    });
    return results;
};
