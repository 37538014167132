import React, {FC, MouseEvent, useCallback, useContext, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';

import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import {RootReducer} from 'appRedux/reducers';
import {REQUEST_CASE_PINNING} from 'appRedux/actions/requestCase';

import PinSvgIcon from 'assets/icons/buttons/PinSvgIcon';
import PrintSvgIcon from 'assets/icons/buttons/PrintSvgIcon';
import ExportSvgIcon from 'assets/icons/buttons/ExportSvgIcon';
import ActivitySvgIcon from 'assets/icons/buttons/ActivitySvgIcon';

import {AlertContext} from 'contexts/alert/context';
import {PermissionContext} from 'contexts/permission/context';
import {MediaContext} from 'contexts/media/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';
import AgentIconSaveButton from 'components/AgentScreenComponents/_buttons/AgentIconSaveButton';
import {useExportCase} from 'components/AgentScreenComponents/_caseBlock/useExportCase';
import AgentIconButton from 'components/AgentScreenComponents/_buttons/AgentIconButton';

import RequesterCaseEmailRequestButton from 'pages/agent/requesterPage/partials/RequesterCaseEmailRequestButton';

import {checkIsCaseEditEnabled} from 'helpers/permissions';

import {routes, DATE_TIME_FORMAT} from 'config/index';
import {theme} from 'config/theme';

const BottomButtonsPanel: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const {caseId, formId} = useParams();
    const navigate = useNavigate();

    const {showAlert} = useContext(AlertContext);
    const {isCaseEditEnabled, filtersCaseEditAllowed} = useContext(PermissionContext);
    const {isLargeDesktop} = useContext(MediaContext);

    const toggleIsCasePinned = useCallback(
        data => dispatch({type: REQUEST_CASE_PINNING.REQUEST, payload: data}),
        [dispatch],
    );

    const exportCase = useExportCase();

    const {
        admin: {organizationList},
        requestCase: {
            currentCase: {isPinned, isEncryptInfo},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const myOrganization = organizationList && organizationList.length > 0 ? organizationList[0] : null;

    const redirectToActivity = () => {
        navigate(`${routes.REQUESTER_CASE}/${caseId}/activity`);
    };

    const redirectToPrint = () => {
        navigate(`${routes.REQUESTER_CASE}/${caseId}/form/${formId}/print`);
    };

    const redirectToPdfExport = () => {
        navigate(`${routes.REQUESTER_CASE}/${caseId}/form/${formId}/pdfexport`);
    };

    const downloadFile = (blob: Blob | string) => {
        if (typeof blob === 'string') return;
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `Export case #${caseId} - ${moment().format(DATE_TIME_FORMAT)}.zip`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const onExportClick = () => {
        exportCase(
            {
                id: Number(caseId),
                showAlert,
                callbackDownload: downloadFile,
            },
            isEncryptInfo,
        );
    };

    const onPinnedClick = () => {
        toggleIsCasePinned({
            caseId,
            showAlert,
        });
    };

    const isEditEnabled = checkIsCaseEditEnabled(isCaseEditEnabled, filtersCaseEditAllowed, Number(formId));

    const SaveButton = isLargeDesktop ? AgentSaveButton : AgentIconSaveButton;
    const InverseButton = isLargeDesktop ? AgentInverseButton : AgentIconButton;

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event?: MouseEvent<HTMLElement>) => {
        event?.stopPropagation();
        event && setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
            }}
        >
            {isEditEnabled && (
                <>
                    {isPinned ? (
                        <SaveButton
                            isSubmit={false}
                            icon={<PinSvgIcon isActive />}
                            title={t('orguser.requesterCase.unpinCase')}
                            onClick={onPinnedClick}
                        />
                    ) : (
                        <InverseButton
                            icon={<PinSvgIcon />}
                            title={t('orguser.requesterCase.pinCase')}
                            onClick={onPinnedClick}
                        />
                    )}
                </>
            )}
            {isEditEnabled && myOrganization && myOrganization.isExportAllowed && (
                <>
                    <InverseButton
                        icon={<ExportSvgIcon />}
                        title={t('orguser.requesterCase.exportCase')}
                        onClick={handleClick}
                    />
                    <Menu
                        anchorEl={anchorEl}
                        id="export-menu"
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                mt: 1,
                                minWidth: 160,
                                borderRadius: 2,
                                overflow: 'visible',
                                boxShadow: `0px 0px 16px rgba(0, 0, 0, 0.1)`,
                                backgroundColor: theme.palette.background.default,
                                '& ul': {
                                    padding: 0,
                                },
                            },
                        }}
                        transformOrigin={{horizontal: 'center', vertical: 'top'}}
                        anchorOrigin={{horizontal: 'center', vertical: 'bottom'}}
                    >
                        <MenuItem
                            onClick={() => {
                                onExportClick();
                                handleClose();
                            }}
                        >
                            <Typography variant="body2" sx={{ml: 1, color: theme.palette.info.main}}>
                                {t('orguser.requesterCase.exportXLSFile')}
                            </Typography>
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                redirectToPdfExport();
                                handleClose();
                            }}
                        >
                            <Typography variant="body2" sx={{ml: 1, color: theme.palette.info.main}}>
                                {t('orguser.requesterCase.exportPdf')}
                            </Typography>
                        </MenuItem>
                    </Menu>
                </>
            )}
            {isEditEnabled && myOrganization && myOrganization.isRequesterEmailDisplayingAllowed && (
                <RequesterCaseEmailRequestButton />
            )}
            <InverseButton
                icon={<ActivitySvgIcon />}
                title={t('orguser.requesterCase.activity')}
                onClick={redirectToActivity}
            />
            <InverseButton
                icon={<PrintSvgIcon />}
                title={t('orguser.requesterCase.printCase')}
                onClick={redirectToPrint}
            />
        </Box>
    );
};

export default BottomButtonsPanel;
