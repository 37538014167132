import React, {FC, useState} from 'react';
import get from 'lodash/get';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router';

import Box from '@mui/material/Box';

import {RootReducer} from 'appRedux/reducers';
import {FormPageTypes, FormVersionTypes} from 'appRedux/actions/forms/types';

import CreatePageForm from 'components/Forms/FormBuilder/PageForm/CreatePageForm';
import UpdatePageForm from 'components/Forms/FormBuilder/PageForm/UpdatePageForm';
import CreateSectionForm from 'components/Forms/FormBuilder/SectionForm/CreateSectionForm';
import UpdateSectionForm from 'components/Forms/FormBuilder/SectionForm/UpdateSectionForm';
import CreateFormField from 'components/Forms/FormBuilder/FieldForm/CreateFormField';
import UpdateFormField from 'components/Forms/FormBuilder/FieldForm/UpdateFormField';
import CreateFormPopupField from 'components/Forms/FormBuilder/FieldForm/CreateFormPopupField';
import CreateFormPopup from 'components/Forms/FormBuilder/PopupForm/CreateFormPopup';
import UpdateFormPopup from 'components/Forms/FormBuilder/PopupForm/UpdateFormPopup';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import MoveFormField from 'components/Forms/FormBuilder/FieldForm/MoveFormField';
import MoveFormPopup from 'components/Forms/FormBuilder/FieldForm/MoveFormPopup';

import {
    ADD_PAGE_KEYWORD,
    UPDATE_PAGE_KEYWORD,
    ADD_NEW_SECTION_KEYWORD,
    UPDATE_SECTION_KEYWORD,
    ADD_NEW_FIELD_KEYWORD,
    UPDATE_FIELD_KEYWORD,
    ADD_NEW_POPUP_KEYWORD,
    UPDATE_POPUP_KEYWORD,
    ADD_FIELD_POPUP_KEYWORD,
    UPDATE_FIELD_POPUP_KEYWORD,
    REPLACE_FIELD_POPUP_KEYWORD,
    REPLACE_POPUP_POPUP_KEYWORD,
} from 'pages/admin/updateForm/partials/constants';
import FormPageInformation from 'pages/admin/updateForm/partials/FormStructure/FormPageInformation';
import {getPlaceholdersCountForPage} from 'pages/admin/updateForm/partials/FormStructure/helper';

import {MAXIMAL_FORM_PAGES_NUMBER} from 'config/index';

interface FormStructureType {
    formId: number;
    viewOnly?: boolean;
}

const FormStructure: FC<FormStructureType> = ({formId, viewOnly = false}) => {
    const [t] = useTranslation();
    const {versionUuid} = useParams();

    const [isShowModal, setIsShowModal] = useState<boolean>(false);
    const [modalTitle, setModalTitle] = useState<string>('');
    const [entityId, setEntityId] = useState<number | null>(null);
    const [formFieldType, setFormFieldType] = useState<number | null>(null);
    const [currentSectionId, setCurrentSectionId] = useState<number>(0);
    const [newItemPriority, setNewItemPriority] = useState<number | undefined>();

    const toggleModal = () => {
        setIsShowModal(previous => !previous);
    };

    const onAddFormClick = () => {
        toggleModal();
        setModalTitle(ADD_PAGE_KEYWORD);
    };

    const {
        admin: {formInfo},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const pages: FormPageTypes[] = get(formInfo, 'pages', []);
    const versions: FormVersionTypes[] = get(formInfo, 'versions', []);

    const currentVersion = versions.find(item => item.uuid === versionUuid);

    if (!currentVersion) return null;

    const getCurrentForm = (modalTitle: string) => {
        switch (modalTitle) {
            case ADD_PAGE_KEYWORD:
                return <CreatePageForm versionId={Number(currentVersion.id)} toggleModal={toggleModal} />;
            case UPDATE_PAGE_KEYWORD:
                return <UpdatePageForm pageId={Number(entityId)} toggleModal={toggleModal} />;
            case ADD_NEW_SECTION_KEYWORD:
                return <CreateSectionForm pageId={Number(entityId)} toggleModal={toggleModal} />;
            case UPDATE_SECTION_KEYWORD:
                return <UpdateSectionForm sectionId={Number(entityId)} toggleModal={toggleModal} />;
            case ADD_NEW_FIELD_KEYWORD:
                return (
                    <CreateFormField
                        pageId={Number(entityId)}
                        currentSectionId={currentSectionId}
                        setCurrentSectionId={setCurrentSectionId}
                        fieldType={formFieldType}
                        newItemPriority={newItemPriority}
                        setModalTitle={setModalTitle}
                        setEntityId={setEntityId}
                    />
                );
            case UPDATE_FIELD_KEYWORD:
                return <UpdateFormField fieldId={Number(entityId)} />;
            case ADD_NEW_POPUP_KEYWORD:
                return (
                    <CreateFormPopup
                        pageId={Number(entityId)}
                        currentSectionId={currentSectionId}
                        toggleModal={toggleModal}
                        setCurrentSectionId={setCurrentSectionId}
                        newItemPriority={newItemPriority}
                    />
                );
            case UPDATE_POPUP_KEYWORD:
                return <UpdateFormPopup popupId={Number(entityId)} toggleModal={toggleModal} />;
            case ADD_FIELD_POPUP_KEYWORD:
                return (
                    <CreateFormPopupField
                        popupId={Number(entityId)}
                        fieldType={formFieldType}
                        newItemPriority={newItemPriority}
                        setModalTitle={setModalTitle}
                        setEntityId={setEntityId}
                    />
                );
            case UPDATE_FIELD_POPUP_KEYWORD:
                return <UpdateFormField fieldId={Number(entityId)} isPopup />;
            case REPLACE_FIELD_POPUP_KEYWORD:
                return (
                    <MoveFormField
                        pageId={Number(entityId)}
                        currentSectionId={currentSectionId}
                        fieldId={Number(entityId)}
                        toggleModal={toggleModal}
                    />
                );
            case REPLACE_POPUP_POPUP_KEYWORD:
                return (
                    <MoveFormPopup
                        pageId={Number(entityId)}
                        currentSectionId={currentSectionId}
                        fieldId={Number(entityId)}
                        toggleModal={toggleModal}
                    />
                );
        }
        return null;
    };

    let placeholdersCount = 0;

    return (
        <Box>
            {pages.map((page: FormPageTypes, index: number) => {
                placeholdersCount += index > 0 ? getPlaceholdersCountForPage(pages[index - 1].sections) : 0;
                return (
                    <FormPageInformation
                        key={`formPage-${index}`}
                        priority={index + 1}
                        item={page}
                        setModalTitle={setModalTitle}
                        setEntityId={setEntityId}
                        toggleModal={toggleModal}
                        isFirst={index === 0}
                        isLast={index === pages.length - 1}
                        isCopyDisabled={pages.length === MAXIMAL_FORM_PAGES_NUMBER}
                        setCurrentSectionId={setCurrentSectionId}
                        setFormFieldType={setFormFieldType}
                        setNewItemPriority={setNewItemPriority}
                        placeholdersCount={placeholdersCount}
                        formId={formId}
                        viewOnly={viewOnly}
                    />
                );
            })}
            {pages.length < MAXIMAL_FORM_PAGES_NUMBER && !viewOnly && (
                <Box
                    sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', pt: 1}}
                >
                    <AgentSaveButton onClick={onAddFormClick} title={t(ADD_PAGE_KEYWORD)} />
                </Box>
            )}
            <ModalWrapper isWideModal isShowModal={isShowModal} toggleModal={toggleModal} title={t(modalTitle)}>
                {getCurrentForm(modalTitle)}
            </ModalWrapper>
        </Box>
    );
};

export default FormStructure;
