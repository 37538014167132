import React, {FC, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import get from 'lodash/get';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';

import {FormPopupTypes} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';

import PopupContent from 'pages/client/form/fieldPopup/PopupContent';
import PopupModalForm from 'pages/client/form/fieldPopup/PopupModalForm';
import FormPopupWrapper from 'pages/client/form/wrappers/FormPopupWrapper';

import {getPopupKeyword, getFormTranslatedLabel} from 'helpers/translationsHelper';

import {theme} from 'config/theme';

interface FormFieldWrapperType {
    formId: number;
    pageId: number;
    sectionId: number;
    disabled: boolean;
    popup: FormPopupTypes;
    clientInformation: unknown;
    handleChangePopupInformation: (pageId: number, sectionId: number, popupId: number, value: unknown) => void;
    onSaveClicked: (isCallAlert?: boolean) => void;
    previewMode?: boolean;
}

const FormPopup: FC<FormFieldWrapperType> = ({
    formId,
    pageId,
    sectionId,
    popup,
    clientInformation,
    handleChangePopupInformation,
    disabled,
    onSaveClicked,
    previewMode,
}) => {
    const [t] = useTranslation();

    const {
        admin: {
            formInfo: {translations},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const ref = useRef<HTMLDivElement | undefined>();

    const {title, id} = popup;

    const [itemIndex, setItemIndex] = useState<number | null>(null);
    const [isShowModal, setIsShowModal] = useState<boolean>(false);
    const [modalTitle, setModalTitle] = useState<string>('');

    const pageKeyword = `page-${pageId}`;
    const sectionKeyword = `section-${sectionId}`;
    const popupKeyword = `popup-${id}`;

    const {fields} = popup;

    const initialItems = get(clientInformation, [pageKeyword, sectionKeyword, popupKeyword], []);

    const toggleModal = () => {
        setIsShowModal(previous => !previous);
    };

    const onAddFieldPopupClick = () => {
        toggleModal();
        setModalTitle(`${t('common.buttons.add')} ${title}`);
    };

    const onEditClick = (index: number) => {
        toggleModal();
        setItemIndex(index);
        setModalTitle(`${t('common.buttons.update')} ${title}`);
    };

    const keywordTitle = getPopupKeyword(formId, popup.id, 'title');

    if (fields.length === 0) {
        return null;
    }
    return (
        <FormPopupWrapper wrapperRef={ref} popup={popup} formId={formId} previewMode={previewMode}>
            <Box>
                <PopupContent
                    popup={popup}
                    pageId={pageId}
                    sectionId={sectionId}
                    initialItems={initialItems}
                    onEditClick={onEditClick}
                    handleChangePopupInformation={handleChangePopupInformation}
                    disabled={disabled}
                    onSaveClicked={onSaveClicked}
                />
            </Box>
            {!disabled && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: 2,
                        mb: 2,
                    }}
                >
                    <IconButton
                        data-id={`input#form-popup-${formId}-${pageId}-${sectionId}-${popup.id}`}
                        onClick={onAddFieldPopupClick}
                        sx={{
                            backgroundColor: theme.palette.info.main,
                            '&:hover': {
                                backgroundColor: theme.palette.info.main,
                            },
                        }}
                    >
                        <AddIcon sx={{color: theme.palette.background.default}} />
                    </IconButton>
                    <Typography
                        variant={'body2'}
                        sx={{
                            mt: 1,
                            color: theme.palette.info.main,
                            fontWeight: 600,
                        }}
                    >{`${t('common.buttons.add')} ${getFormTranslatedLabel(
                        translations,
                        keywordTitle,
                        title,
                    )}`}</Typography>
                </Box>
            )}
            {isShowModal && (
                <PopupModalForm
                    isShowModal={isShowModal}
                    toggleModal={toggleModal}
                    initialItems={initialItems}
                    modalTitle={modalTitle}
                    formId={formId}
                    pageId={pageId}
                    sectionId={sectionId}
                    popup={popup}
                    clientInformation={clientInformation}
                    handleChangePopupInformation={handleChangePopupInformation}
                    itemIndex={itemIndex}
                    setItemIndex={setItemIndex}
                    disabled={disabled}
                    onSaveClicked={onSaveClicked}
                />
            )}
        </FormPopupWrapper>
    );
};

export default FormPopup;
