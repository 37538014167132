import React, {FC, useContext} from 'react';
import {View, Text, Image} from '@react-pdf/renderer';

import {RequesterDocumentResponseType} from 'appRedux/actions/profile/types';

import {pdfStyles} from 'pages/agent/pdfExport/pdfPartials/PdfDocumentStyles';
import {PdfDocumentContext} from 'pages/agent/pdfExport/pdfPartials/pdfDocumentContext';

interface AttachmentPdfContentType {
    file: RequesterDocumentResponseType;
    fileImage?: string | string[];
}

const AttachmentPdfContent: FC<AttachmentPdfContentType> = ({file, fileImage}) => {
    const {description, originalName, uuid} = file;

    const {preview} = useContext(PdfDocumentContext);

    const isImg = typeof fileImage === 'string';

    return (
        <>
            <View style={pdfStyles.sectionHeader}>
                <Text>{`${description}:`}</Text>
            </View>
            <View wrap={!isImg} style={pdfStyles.attachmentView}>
                {fileImage ? (
                    isImg ? (
                        <ImageComponent src={fileImage} preview={preview} />
                    ) : (
                        fileImage.map((image, i) => (
                            <View wrap={false} style={pdfStyles.attachmentView} key={`image-${uuid}-${i}`}>
                                <ImageComponent src={image} preview={preview} />
                            </View>
                        ))
                    )
                ) : (
                    originalName
                )}
            </View>
        </>
    );
};

interface ImgProps {
    src: string;
    preview?: boolean;
}

export const ImageComponent: FC<ImgProps> = ({src, preview}) => {
    if (preview) {
        return <img style={pdfStyles.image} src={src} />;
    }
    return <Image style={pdfStyles.image} src={src} />;
};

export default AttachmentPdfContent;
