import React, {FC, useContext, useState} from 'react';
import {useParams} from 'react-router-dom';

import Box from '@mui/material/Box';

import {PermissionContext} from 'contexts/permission/context';

import NewCommentPanel from 'pages/agent/requesterPage/common/NewCommentPanel';
import CommentsList from 'pages/agent/requesterPage/common/CommentsList';

import {checkIsCaseEditEnabled} from 'helpers/permissions';

interface OverallCommentsType {
    inRightPanel?: boolean;
}

const OverallComments: FC<OverallCommentsType> = ({inRightPanel}) => {
    const {formId} = useParams();

    const {isCaseEditEnabled, filtersCaseEditAllowed} = useContext(PermissionContext);

    const [isCommentsExpanded, setIsCommentsExpanded] = useState<boolean>(false);

    const toggleIsCommentsExpanded = () => {
        setIsCommentsExpanded(previous => !previous);
    };

    const isEditEnabled = checkIsCaseEditEnabled(isCaseEditEnabled, filtersCaseEditAllowed, Number(formId));

    return (
        <Box sx={{mt: 1, mb: 1}}>
            <CommentsList
                sectionId={null}
                isCommentsExpanded={isCommentsExpanded}
                toggleIsCommentsExpanded={toggleIsCommentsExpanded}
                inRightPanel={inRightPanel}
            />
            {!inRightPanel && isEditEnabled && (
                <NewCommentPanel sectionId={null} setIsCommentsExpanded={setIsCommentsExpanded} />
            )}
        </Box>
    );
};

export default OverallComments;
