import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';

import PlusSvgIcon from 'assets/icons/buttons/PlusSvgIcon';

import {WorkflowStatusRuleItemTypes} from 'appRedux/actions/workflow/types';

import WorkflowRuleApproverForm from 'components/Forms/Workflow/workflowStatusRule/WorkflowRuleApproverForm';
import WorkflowRuleOptionForm from 'components/Forms/Workflow/workflowStatusRule/WorkflowRuleOptionForm';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

import ApproversList from 'pages/admin/updateWorkflow/partials/rules/ApproversList';

interface WorkflowRuleApprovalDetailsType {
    item: WorkflowStatusRuleItemTypes;
    hasNotCompletedRules: boolean;
}

const WorkflowRuleApprovalDetails: FC<WorkflowRuleApprovalDetailsType> = ({item, hasNotCompletedRules}) => {
    const [t] = useTranslation();

    const [showApproverForm, setShowApproverForm] = useState<boolean>(false);

    const {approvers} = item;

    return (
        <Box>
            <Divider sx={{mt: 1, mb: 2}} />
            <Typography sx={{fontWeight: 600, mt: 1, mb: 1}}>{`${t(
                'orguser.workflows.rules.approversList',
            )}:`}</Typography>
            {hasNotCompletedRules && (
                <Alert severity={'error'} sx={{mt: 2, mb: 2}}>
                    <Typography>{t('orguser.workflows.rules.hasNotCompletedRules')}</Typography>
                </Alert>
            )}
            <ApproversList items={approvers} />
            {showApproverForm ? (
                <WorkflowRuleApproverForm item={item} closeForm={() => setShowApproverForm(false)} />
            ) : (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: 2,
                    }}
                >
                    <AgentSaveButton
                        isSubmit={false}
                        icon={<PlusSvgIcon isActive />}
                        title={t('orguser.workflows.rules.addApprover')}
                        onClick={() => setShowApproverForm(true)}
                    />
                </Box>
            )}
            <WorkflowRuleOptionForm item={item} />
        </Box>
    );
};

export default WorkflowRuleApprovalDetails;
