import {GET_MESSAGES_LIST, SEND_MESSAGE} from 'appRedux/actions/chat';
import {MessagesListResponseTypes} from 'appRedux/actions/chat/types';

export const initialState: ChatState = {
    isLoading: false,
    messagesList: undefined,
    errors: undefined,
};

export interface ChatState {
    isLoading: boolean;
    messagesList: MessagesListResponseTypes | undefined;
    errors: string[] | undefined;
}

const chat = (state = initialState, action: IActionType<MessagesListResponseTypes>): ChatState => {
    switch (action.type) {
        case GET_MESSAGES_LIST.REQUEST:
        case SEND_MESSAGE.REQUEST: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case GET_MESSAGES_LIST.SUCCESS:
        case SEND_MESSAGE.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                messagesList: action.payload,
            };
        }

        case GET_MESSAGES_LIST.ERROR:
        case SEND_MESSAGE.ERROR: {
            return {
                ...state,
                isLoading: false,
                errors: action.payload as any,
            };
        }

        default:
            return state;
    }
};

export default chat;
