import {InviteTypes} from 'appRedux/actions/invites/types';

import {SORTING_ASC} from 'config/index';

export const getInviteStatusLabel = (status: number): string => {
    switch (status) {
        case 0:
            return 'orguser.invites.statusTypes.inviteStatusCreated';
        case 1:
            return 'orguser.invites.statusTypes.inviteStatusSent';
        case 2:
            return 'orguser.invites.statusTypes.inviteStatusCompleted';
        default:
            return '';
    }
};

export const filterSearchValue = (searchTerm: RegExp) => (row: any) => {
    const match = Object.keys(row).some((field: string) => {
        if (field === 'uuid') return false;
        if (field === 'createdAt') return false;
        if (field === 'formId') return false;

        return row[field] ? (searchTerm instanceof RegExp ? searchTerm.test(row[field].toString()) : false) : false;
    });

    return match;
};

export const escapeRegExp = (value: string) => {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
};

export const sortInvites =
    (sortByParameter: keyof InviteTypes, sortTypeParameter: string) => (a: InviteTypes, b: InviteTypes) => {
        const strA = (
            typeof a[sortByParameter] === 'string' ? a[sortByParameter] : a[sortByParameter].toString()
        ) as string;
        const strB = (
            typeof b[sortByParameter] === 'string' ? b[sortByParameter] : b[sortByParameter].toString()
        ) as string;

        return sortTypeParameter === SORTING_ASC ? strA.localeCompare(strB) : strB.localeCompare(strA);
    };
