import React, {FC, useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Typography from '@mui/material/Typography';
import BarChartIcon from '@mui/icons-material/BarChart';
import Box from '@mui/material/Box';

import {ORGANIZATION_LIST} from 'appRedux/actions/organization';
import {RootReducer} from 'appRedux/reducers';

import {TableFilterTypes} from 'components/AdminScreenComponents/types';
import Charts from 'components/RequesterCaseStatistic/Charts';
import StatsTable from 'components/RequesterCaseStatistic/StatsTable';

import {checkSubdomainCorrect} from 'helpers/security';

import {theme} from 'config/theme';

interface RequesterStatisticType {
    filters?: TableFilterTypes[];
}

const RequesterCaseStatistic: FC<RequesterStatisticType> = ({filters}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {
        admin: {organizationList},
        requestCase: {requesterCasesStatistic},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const getOrganizationList = useCallback(() => dispatch({type: ORGANIZATION_LIST.REQUEST}), [dispatch]);

    const myOrganization = organizationList && organizationList.length > 0 ? organizationList[0] : null;
    const isSubdomainCorrect = checkSubdomainCorrect(myOrganization);

    useEffect(() => {
        getOrganizationList();
    }, []);

    if (requesterCasesStatistic.length === 0) {
        return null;
    }
    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    mb: 3.5,
                }}
            >
                <Typography sx={{fontWeight: 600, mr: 1}}>{t('orguser.dashboard.statistic')}</Typography>
                <BarChartIcon />
            </Box>
            <Box sx={{mb: 3, borderRadius: 2, backgroundColor: theme.palette.background.default, padding: 1}}>
                {isSubdomainCorrect ? (
                    filters ? (
                        <Charts filters={filters} />
                    ) : (
                        <StatsTable />
                    )
                ) : (
                    <Typography>{t('messages.error.incorrectSubdomain')}</Typography>
                )}
            </Box>
        </Box>
    );
};

export default RequesterCaseStatistic;
