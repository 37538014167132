import React, {FC, useCallback, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import Markdown from 'react-markdown';

import Box from '@mui/material/Box';

import {FormFieldTypes} from 'appRedux/actions/forms/types';
import {FORM_FIELD_COPY, FORM_FIELD_DELETE, FORM_FIELD_DOWN, FORM_FIELD_UP} from 'appRedux/actions/forms';

import {AlertContext} from 'contexts/alert/context';

import TopPanel from 'pages/admin/updateForm/partials/FormStructure/TopPanel';
import {formValidationTypes, getFieldTitle} from 'pages/admin/updateForm/partials/FormStructure/helper';
import {
    UPDATE_FIELD_KEYWORD,
    UPDATE_FIELD_POPUP_KEYWORD,
    REPLACE_FIELD_POPUP_KEYWORD,
} from 'pages/admin/updateForm/partials/constants';

import {CLIENT_BACKGROUND_COLOR} from 'config/theme';

interface FormFieldInformationType {
    isFirst: boolean;
    isLast: boolean;
    item: FormFieldTypes;
    setModalTitle: (value: string) => void;
    setEntityId: (value: number) => void;
    toggleModal: () => void;
    isPopup?: boolean;
    viewOnly: boolean;
}

const FormFieldInformation: FC<FormFieldInformationType> = ({
    item,
    isFirst,
    isLast,
    setModalTitle,
    setEntityId,
    toggleModal,
    isPopup,
    viewOnly,
}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const {label, description, isRequired, id, validationType} = item;

    const handleFieldRemove = useCallback(
        data => dispatch({type: FORM_FIELD_DELETE.REQUEST, payload: data}),
        [dispatch],
    );

    const handleFieldCopy = useCallback(data => dispatch({type: FORM_FIELD_COPY.REQUEST, payload: data}), [dispatch]);

    const handleFieldUpPriority = useCallback(
        data => dispatch({type: FORM_FIELD_UP.REQUEST, payload: data}),
        [dispatch],
    );

    const handleFieldDownPriority = useCallback(
        data => dispatch({type: FORM_FIELD_DOWN.REQUEST, payload: data}),
        [dispatch],
    );

    const onEditClick = () => {
        toggleModal();
        setModalTitle(isPopup ? UPDATE_FIELD_POPUP_KEYWORD : UPDATE_FIELD_KEYWORD);
        setEntityId(id);
    };

    const onCopyClick = () => {
        handleFieldCopy({
            id,
            showAlert,
        });
    };

    const onReplaceClick = () => {
        toggleModal();
        setModalTitle(REPLACE_FIELD_POPUP_KEYWORD);
        setEntityId(id);
    };

    const onDeleteClick = () => {
        handleFieldRemove({
            id,
            showAlert,
        });
    };

    const onUpClick = () => {
        handleFieldUpPriority({
            id,
            showAlert,
        });
    };

    const onDownClick = () => {
        handleFieldDownPriority({
            id,
            showAlert,
        });
    };

    return (
        <Box
            sx={{
                my: 1,
                borderColor: CLIENT_BACKGROUND_COLOR,
                borderRadius: 2,
                borderWidth: 2,
                borderStyle: 'solid',
                minHeight: 35,
            }}
        >
            <TopPanel
                isFirst={isFirst}
                isLast={isLast}
                entityId={id}
                typeTitle={t(getFieldTitle(item))}
                title={label}
                validationType={validationType ? t((formValidationTypes as any)[validationType] || '') : ''}
                isRequired={isRequired}
                onEditClick={onEditClick}
                onCopyClick={onCopyClick}
                onReplaceClick={onReplaceClick}
                onDeleteClick={onDeleteClick}
                onUpClick={onUpClick}
                onDownClick={onDownClick}
                viewOnly={viewOnly}
                showReplaceButton={!isPopup}
            />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    px: 1,
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        '& p': {
                            px: 1,
                            fontSize: 14,
                            fontWeight: 300,
                        },
                    }}
                >
                    <Markdown>{description}</Markdown>
                </Box>
            </Box>
        </Box>
    );
};

export default FormFieldInformation;
