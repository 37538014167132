import React, {FC, useCallback, useContext, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import i18n from 'i18next';
import get from 'lodash/get';
import {useTranslation} from 'react-i18next';

import Select, {SelectChangeEvent} from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import {RootReducer} from 'appRedux/reducers';
import {FormHasLanguageTypes} from 'appRedux/actions/forms/types';
import {SET_LANGUAGE} from 'appRedux/actions/profile';

import {MediaContext} from 'contexts/media/context';

import LanguageDropdownMobileSwitcher from 'pages/client/form/partials/LanguageDropdownMobileSwitcher';

import {getLanguageById} from 'helpers/translationsHelper';

import {LOCAL_STORAGE_CURRENT_LANGUAGE} from 'services/localStorage';

import {DEFAULT_LANGUAGE} from 'config/index';

const LanguageDropdownSwitcher: FC = () => {
    const dispatch = useDispatch();
    const [t] = useTranslation();

    const {isMobile} = useContext(MediaContext);

    const {
        admin: {formInfo, languageList},
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const defaultLanguageId: number | null = get(formInfo, 'defaultLanguage', null);
    const organizationLanguage = defaultLanguageId ? getLanguageById(languageList, defaultLanguageId) : null;
    const defaultLanguage = organizationLanguage ? organizationLanguage.name : DEFAULT_LANGUAGE;

    const currentLanguage = get(profile, 'lang', defaultLanguage);

    const [language, setLanguage] = useState<string>(currentLanguage);

    const updateLanguage = useCallback(data => dispatch({type: SET_LANGUAGE.REQUEST, payload: data}), [dispatch]);

    const onLanguageSelect = (event: SelectChangeEvent) => {
        const value = event.target.value as string;
        onLanguageChange(value);
    };

    const onLanguageChange = (value: string) => {
        setLanguage(value);
        i18n.changeLanguage(value);
        localStorage.setItem(LOCAL_STORAGE_CURRENT_LANGUAGE, value);
        updateLanguage({lang: value});
    };

    const formHasLanguages: FormHasLanguageTypes[] = get(formInfo, 'languages', []);

    const availableLanguages = formHasLanguages.filter((item: FormHasLanguageTypes) => {
        const {
            isActive,
            isAvailableForOrganization,
            language: {enabled},
        } = item;
        return isActive && isAvailableForOrganization && enabled;
    });

    if (!availableLanguages || availableLanguages.length === 0 || formHasLanguages.length === 0) {
        return null;
    }

    if (isMobile) {
        return (
            <LanguageDropdownMobileSwitcher
                availableLanguages={availableLanguages}
                onLanguageChange={onLanguageChange}
            />
        );
    }
    return (
        <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <Typography sx={{mr: 1}}>{`${t('common.translateTo')}:`}</Typography>
            <FormControl>
                <Select name="status" variant="standard" fullWidth value={language} onChange={onLanguageSelect}>
                    <MenuItem key={`language-default`} value={defaultLanguage}>
                        <Typography>{defaultLanguage}</Typography>
                    </MenuItem>
                    {availableLanguages &&
                        availableLanguages.map((language: FormHasLanguageTypes) => {
                            const {
                                language: {id, name},
                            } = language;
                            if (Number(id) !== Number(defaultLanguageId)) {
                                return (
                                    <MenuItem key={`language-${name}`} value={name}>
                                        <Typography>{name}</Typography>
                                    </MenuItem>
                                );
                            }
                            return null;
                        })}
                </Select>
            </FormControl>
        </Box>
    );
};

export default LanguageDropdownSwitcher;
