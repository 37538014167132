import React, {FC} from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import OverallComments from 'pages/agent/requesterPage/overallComments/OverallComments';

interface CommentsBlockType {
    showComments: boolean;
}

const CommentsBlock: FC<CommentsBlockType> = ({showComments}) => {
    if (showComments) {
        return (
            <Grid item sm={6}>
                <Box
                    sx={{
                        mr: 2,
                        pb: 25,
                        maxHeight: '55vw',
                        overflowY: 'scroll',
                        scrollbarWidth: 'none' /* Firefox */,
                        msOverflowStyle: 'none' /* IE and Edge */,
                        '&::-webkit-scrollbar': {
                            display: 'none' /* Chrome */,
                        },
                    }}
                >
                    <OverallComments inRightPanel />
                </Box>
            </Grid>
        );
    }
    return null;
};

export default CommentsBlock;
