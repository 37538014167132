import React, {FC, useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';

import {FormFieldMoveRequestFormType} from 'appRedux/actions/forms/types';
import {FORM_FIELD_MOVE} from 'appRedux/actions/forms';

import {AlertContext} from 'contexts/alert/context';

import MoveForm from 'components/Forms/FormBuilder/FieldForm/MoveForm';

interface ReplaceFieldFormType {
    pageId: number;
    currentSectionId: number;
    fieldId: number;
    toggleModal: () => void;
}

const MoveFormField: FC<ReplaceFieldFormType> = ({pageId, currentSectionId, fieldId, toggleModal}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const formFieldMove = useCallback(data => dispatch({type: FORM_FIELD_MOVE.REQUEST, payload: data}), [dispatch]);

    const onSubmitClick = (values: FormFieldMoveRequestFormType) => {
        formFieldMove({
            id: fieldId,
            ...values,
            showAlert,
            callback: toggleModal,
        });
    };

    return <MoveForm pageId={pageId} currentSectionId={currentSectionId} onSubmitClick={onSubmitClick} />;
};

export default MoveFormField;
