import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';

import {FormPopupTypes} from 'appRedux/actions/forms/types';

import {ContentItemType} from 'pages/client/form/partials/types';
import PopupContentRow from 'pages/client/form/fieldPopup/PopupContentRow';

import {theme} from 'config/theme';

interface PopupContentType {
    pageId: number;
    sectionId: number;
    popup: FormPopupTypes;
    disabled: boolean;
    initialItems: ContentItemType[];
    onEditClick: (value: number) => void;
    handleChangePopupInformation: (pageId: number, sectionId: number, popupId: number, value: unknown) => void;
    onSaveClicked: (isCallAlert?: boolean) => void;
}

const PopupContent: FC<PopupContentType> = ({
    popup,
    pageId,
    sectionId,
    disabled,
    handleChangePopupInformation,
    initialItems,
    onEditClick,
    onSaveClicked,
}) => {
    const [t] = useTranslation();

    const [isOpened, setIsOpened] = useState<boolean>(true);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    const {title, fields} = popup;

    const firstFieldId = fields[0].id;

    return (
        <Box>
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                <Typography sx={{fontWeight: 600}}>{title}</Typography>
                {initialItems.length > 0 ? (
                    <Button onClick={toggleIsOpened}>
                        <Typography
                            sx={{
                                textTransform: 'none',
                                color: theme.palette.info.main,
                                pr: 1,
                            }}
                        >
                            {`${initialItems.length} ${t('requester.casePage.entries')}`}
                        </Typography>
                        {isOpened ? (
                            <KeyboardArrowUp sx={{color: theme.palette.info.main}} />
                        ) : (
                            <KeyboardArrowDown sx={{color: theme.palette.info.main}} />
                        )}
                    </Button>
                ) : (
                    <Typography
                        sx={{
                            textTransform: 'none',
                            color: theme.palette.info.main,
                            pr: 1,
                        }}
                    >
                        {t('common.none')}
                    </Typography>
                )}
            </Box>
            {isOpened && (
                <Box>
                    {initialItems.map((item: ContentItemType, index: number) => {
                        return (
                            <PopupContentRow
                                key={`popup-${index}`}
                                pageId={pageId}
                                sectionId={sectionId}
                                popupId={popup.id}
                                currentIndex={index}
                                item={item}
                                handleChangePopupInformation={handleChangePopupInformation}
                                firstFieldId={firstFieldId}
                                initialItems={initialItems}
                                onEditClick={onEditClick}
                                disabled={disabled}
                                onSaveClicked={onSaveClicked}
                            />
                        );
                    })}
                </Box>
            )}
        </Box>
    );
};

export default PopupContent;
