import React, {FC, useCallback, useContext, useState} from 'react';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';
import {uniq} from 'lodash';
import {useSelector} from 'react-redux';

import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';

import {FormFieldRequestType, FormAddFieldRequest, FieldOptionType} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import OptionsList from 'components/Forms/FormBuilder/FieldForm/OptionsList';
import {isImageOptionsList, showOptionsList} from 'components/Forms/FormBuilder/helper';
import {
    formFieldValidationSchema,
    formFieldValidationSchemaNotOnlyLatin,
} from 'components/Forms/FormBuilder/FieldForm/validation';
import MdxEditorField from 'components/MdxEditorField/MdxEditorField';
import {
    OPTION_ERROR_SEMICOLON,
    OPTION_ERROR_MIN_TWO_OPTIONS,
    OPTION_ERROR_MIN_ONE_OPTION,
    OPTION_ERROR_REPEAT,
    OPTION_ERROR_HTML_TAG,
    getOptionsListError,
    OPTION_ERROR_MIN_LESS_MAX,
    OPTION_ERROR_BOTH_RANGES,
} from 'components/Forms/FormBuilder/FieldForm/helper';
import EmptyArea from 'components/EmptyArea';
import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import FormBuilderCheckboxReverse from 'components/AgentScreenComponents/_form/FormBuilderCheckboxReverse';
import ImageOptionsList from 'components/Forms/FormBuilder/FieldForm/ImageOptionsList';
import Range from 'components/Forms/FormBuilder/FieldForm/Range';

import {
    formTypes,
    formValidationTypes,
    FORM_FIELD_TEXT_INPUT,
    FORM_FIELD_DROPDOWN,
    FORM_FIELD_UPLOADER,
    FORM_FIELD_CHECKBOXES,
    FORM_FIELD_RADIOBUTTONS,
    OPTIONS_SEPARATOR,
    FORM_FIELD_TEXTAREA,
    FORM_FIELD_IMAGE_CHECK,
    FORM_FIELD_IMAGE_RADIO,
    FORM_FIELD_RANGE,
} from 'pages/admin/updateForm/partials/FormStructure/helper';

import {preventSendForm} from 'helpers/index';
import {htmlTagsPresented} from 'helpers/validation';
import {checkIsLanguageWithLatinLetters} from 'helpers/translationsHelper';

import {theme, ERROR_TEXT_COLOR} from 'config/theme';

interface FieldFormType {
    entityId: number;
    onSubmitAction: (values: FormAddFieldRequest) => void;
    initialValues: FormFieldRequestType;
    isCreate?: boolean;
    isDisabled?: boolean;
    isPopup?: boolean;
    newItemPriority?: number;
    continueEditing?: (fieldId: number) => void;
}

const FieldForm: FC<FieldFormType> = ({
    initialValues,
    entityId,
    onSubmitAction,
    isCreate,
    isDisabled,
    isPopup,
    newItemPriority,
    continueEditing,
}) => {
    const [t] = useTranslation();

    const {showAlert} = useContext(AlertContext);

    const [showOptionsError, setShowOptionsError] = useState<boolean>(false);
    const [optionErrorType, setOptionErrorType] = useState<number | null>(null);

    const {
        admin: {languageList, organizationList},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const myOrganization = organizationList && organizationList.length > 0 ? organizationList[0] : null;

    const isLanguageWithLatinLetters = myOrganization
        ? checkIsLanguageWithLatinLetters(languageList, myOrganization.language)
        : false;

    const onDisabledClick = () => {
        if (optionErrorType) {
            setShowOptionsError(true);
        }
    };

    const isSubmitDisabled = Number(optionErrorType) > 0 || isDisabled;

    const validateOptions = useCallback((fieldOptions: FieldOptionType[], fieldType: number) => {
        setShowOptionsError(false);
        setOptionErrorType(null);

        fieldOptions.forEach((option: FieldOptionType) => {
            if (option.title.includes(OPTIONS_SEPARATOR)) {
                setOptionErrorType(OPTION_ERROR_SEMICOLON);
            }
            if (!htmlTagsPresented(option.title)) {
                setOptionErrorType(OPTION_ERROR_HTML_TAG);
            }
        });
        if (
            fieldOptions.length < 2 &&
            [FORM_FIELD_DROPDOWN, FORM_FIELD_RADIOBUTTONS, FORM_FIELD_IMAGE_RADIO].includes(fieldType)
        ) {
            setOptionErrorType(OPTION_ERROR_MIN_TWO_OPTIONS);
        }
        if (fieldOptions.length < 1 && fieldType === FORM_FIELD_CHECKBOXES) {
            setOptionErrorType(OPTION_ERROR_MIN_ONE_OPTION);
        }

        const uniqueOptions = uniq(fieldOptions.map(opt => opt.title));
        if (uniqueOptions.length < fieldOptions.length) {
            setOptionErrorType(OPTION_ERROR_REPEAT);
        }
    }, []);

    const validateRange = (options: string[], fieldType: number) => {
        setShowOptionsError(false);
        setOptionErrorType(null);

        options.forEach((option: string) => {
            if (option.includes(OPTIONS_SEPARATOR)) {
                setOptionErrorType(OPTION_ERROR_SEMICOLON);
            }
            if (!htmlTagsPresented(option)) {
                setOptionErrorType(OPTION_ERROR_HTML_TAG);
            }
        });

        if (fieldType === FORM_FIELD_RANGE && Number(options[0]) >= Number(options[1])) {
            setOptionErrorType(OPTION_ERROR_MIN_LESS_MAX);
        }
        if (fieldType === FORM_FIELD_RANGE && !(options[0]?.length && options[1]?.length)) {
            setOptionErrorType(OPTION_ERROR_BOTH_RANGES);
        }

        const uniqueOptions = uniq(options);
        if (uniqueOptions.length < options.length) {
            setOptionErrorType(OPTION_ERROR_REPEAT);
        }
    };

    const schema = isLanguageWithLatinLetters ? formFieldValidationSchemaNotOnlyLatin(t) : formFieldValidationSchema(t);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={
                isSubmitDisabled
                    ? onDisabledClick
                    : (values: FormFieldRequestType, {setErrors}) => {
                          onSubmitAction({
                              ...values,
                              id: entityId,
                              priority: newItemPriority,
                              callback: id => continueEditing && continueEditing(Number(id)),
                              showAlert,
                              setErrors,
                          });
                      }
            }
        >
            {(formik: FormikProps<FormFieldRequestType>) => {
                const createAndContinue =
                    (isImageOptionsList(formik.values.type) || showOptionsList(formik.values.type)) &&
                    continueEditing &&
                    isCreate;

                return (
                    <Form onKeyDown={preventSendForm}>
                        <Typography variant="body2" sx={{mb: 1}}>
                            {t('orguser.forms.formField.fieldType')}
                        </Typography>
                        <FormControl
                            sx={{
                                backgroundColor: theme.palette.warning.contrastText,
                                borderRadius: 3,
                                pl: 3,
                                pr: 3,
                                pb: 2,
                                mb: 1,
                            }}
                            required
                            fullWidth
                        >
                            <Select
                                sx={{
                                    height: 50,
                                }}
                                name={'type'}
                                value={formik.values.type}
                                variant="standard"
                                onChange={formik.handleChange}
                            >
                                {Object.values(formTypes).map((item: string, index: number) => {
                                    if (
                                        !isCreate &&
                                        ((isImageOptionsList(formik.values.type) &&
                                            ![FORM_FIELD_IMAGE_CHECK, FORM_FIELD_IMAGE_RADIO].includes(index)) ||
                                            (!isImageOptionsList(formik.values.type) &&
                                                [FORM_FIELD_IMAGE_CHECK, FORM_FIELD_IMAGE_RADIO].includes(index)) ||
                                            (showOptionsList(formik.values.type) &&
                                                ![
                                                    FORM_FIELD_DROPDOWN,
                                                    FORM_FIELD_CHECKBOXES,
                                                    FORM_FIELD_RADIOBUTTONS,
                                                ].includes(index)) ||
                                            (!showOptionsList(formik.values.type) &&
                                                [
                                                    FORM_FIELD_DROPDOWN,
                                                    FORM_FIELD_CHECKBOXES,
                                                    FORM_FIELD_RADIOBUTTONS,
                                                ].includes(index)))
                                    ) {
                                        return null;
                                    }

                                    if (isPopup && index === FORM_FIELD_UPLOADER) {
                                        return null;
                                    }
                                    return (
                                        <MenuItem key={`menuItem-${index}`} value={index}>
                                            <Typography>{t(item)}</Typography>
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        <Box
                            sx={{
                                backgroundColor: theme.palette.warning.contrastText,
                                borderRadius: 3,
                                pl: 3,
                                pr: 3,
                                pb: 3,
                                mt: 2,
                                mb: 2,
                            }}
                        >
                            <Field
                                required
                                name="label"
                                label={t('orguser.forms.formField.fieldLabel')}
                                placeholder={t('orguser.forms.formField.fieldLabelPlaceholder')}
                                component={FormikTextInput}
                            />
                            <MdxEditorField
                                name="description"
                                value={formik.values.description ? String(formik.values.description) : ''}
                                label={t('orguser.forms.formField.fieldDescription')}
                            />
                            <Field
                                name="tooltip"
                                label={t('orguser.forms.formField.fieldTooltip')}
                                placeholder={t('orguser.forms.formField.fieldTooltipPlaceholder')}
                                component={FormikTextInput}
                            />
                            {Number(formik.values.type) === FORM_FIELD_UPLOADER && (
                                <Field
                                    name="options"
                                    type="number"
                                    required
                                    label={t('orguser.forms.formField.maximalFilesNumber')}
                                    placeholder={t('orguser.forms.formField.maximalFilesNumber')}
                                    component={FormikTextInput}
                                />
                            )}
                            {formik.values.type === FORM_FIELD_TEXTAREA && (
                                <Box>
                                    <Field
                                        type="number"
                                        name="minLength"
                                        value={formik.values.minLength}
                                        label={t('orguser.forms.formField.minLength')}
                                        placeholder={t('orguser.forms.formField.minLength')}
                                        component={FormikTextInput}
                                    />
                                </Box>
                            )}
                            {Number(formik.values.type) === FORM_FIELD_RANGE && (
                                <Range
                                    fieldType={formik.values.type}
                                    optionsString={formik.values.options}
                                    setOptionsParameter={(value: string) => formik.setFieldValue('options', value)}
                                    validateRange={validateRange}
                                    validationErrors={showOptionsError ? t(getOptionsListError(optionErrorType)) : null}
                                    setShowOptionsError={setShowOptionsError}
                                />
                            )}
                        </Box>
                        {showOptionsList(Number(formik.values.type)) && !isCreate && initialValues.fieldOptions && (
                            <Box>
                                <OptionsList
                                    fieldType={formik.values.type}
                                    fieldOptions={initialValues.fieldOptions}
                                    setShowOptionsError={setShowOptionsError}
                                    fieldId={formik.values.id}
                                    validateOptions={validateOptions}
                                />
                                {showOptionsError && (
                                    <Typography
                                        sx={{
                                            mt: 2,
                                            mb: 2,
                                            color: ERROR_TEXT_COLOR,
                                        }}
                                    >
                                        {t(getOptionsListError(optionErrorType))}
                                    </Typography>
                                )}
                            </Box>
                        )}
                        {isImageOptionsList(formik.values.type) && !isCreate && initialValues.fieldOptions && (
                            <Box>
                                <ImageOptionsList
                                    fieldType={formik.values.type}
                                    setShowOptionsError={setShowOptionsError}
                                    fieldOptions={initialValues.fieldOptions}
                                    fieldId={formik.values.id}
                                    validateOptions={validateOptions}
                                />
                                {showOptionsError && (
                                    <Typography
                                        sx={{
                                            mt: 2,
                                            mb: 2,
                                            color: ERROR_TEXT_COLOR,
                                        }}
                                    >
                                        {t(getOptionsListError(optionErrorType))}
                                    </Typography>
                                )}
                            </Box>
                        )}
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                mb: 2,
                            }}
                        >
                            <Box sx={{width: '30%'}}>
                                <Field
                                    type="checkbox"
                                    name="isRequired"
                                    disabled={formik.values.isFilterable}
                                    label={t('orguser.forms.formField.required')}
                                    component={FormBuilderCheckboxReverse}
                                />
                            </Box>
                            <EmptyArea />
                            <Box sx={{width: '30%'}}>
                                {formik.values.type === FORM_FIELD_TEXT_INPUT && (
                                    <FormControl required fullWidth>
                                        <Select
                                            sx={{
                                                height: 50,
                                            }}
                                            name={'validationType'}
                                            value={formik.values.validationType}
                                            variant="standard"
                                            onChange={formik.handleChange}
                                        >
                                            {Object.values(formValidationTypes).map((item: string, index: number) => {
                                                return (
                                                    <MenuItem key={`menuItem-${index}`} value={index}>
                                                        <Typography>{t(item)}</Typography>
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                )}
                            </Box>
                        </Box>
                        {formik.values.isRequired && showOptionsList(Number(formik.values.type)) && (
                            <Box>
                                <Field
                                    type="checkbox"
                                    name="isFilterable"
                                    label={t('orguser.forms.formField.useForFilter')}
                                    component={FormBuilderCheckboxReverse}
                                />
                            </Box>
                        )}
                        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', mt: 1}}>
                            <AgentSaveButton
                                isSubmit
                                icon={isCreate ? <AddIcon sx={{color: theme.palette.background.default}} /> : undefined}
                                title={t(
                                    isCreate
                                        ? createAndContinue
                                            ? 'orguser.forms.formField.saveAndAddOptions'
                                            : 'orguser.forms.addNewFormField'
                                        : 'common.buttons.saveButton',
                                )}
                            />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default FieldForm;
