import get from 'lodash/get';
import {fork, put, take, call} from 'redux-saga/effects';
import {http} from 'services/http';

import {
    FORM_SECTION_ADD,
    FORM_SECTION_UPDATE,
    FORM_SECTION_DELETE,
    FORM_SECTION_COPY,
    FORM_SECTION_DOWN,
    FORM_SECTION_UP,
    FORM_SECTION_RECALCULATE_PRIORITIES,
    addFormSection,
    updateFormSection,
    copyFormSection,
    deleteFormSection,
    formSectionDownPriority,
    formSectionUpPriority,
    formSectionPrioritiesRecalculate,
} from 'appRedux/actions/forms';
import {
    FormAddPageRequest,
    FormAddSectionRequest,
    FormTotalInformationTypes,
    CommonFormBuilderRequest,
    FormChangeStatusType,
    FormItemBasic,
} from 'appRedux/actions/forms/types';
import {ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS} from 'config/index';

function* watchFormSectionAdding() {
    while (true) {
        const {
            payload: {id, callback, showAlert, ...values},
        }: IActionType<FormAddSectionRequest> = yield take(FORM_SECTION_ADD.REQUEST);
        try {
            const data: FormTotalInformationTypes[] = yield call(http, `section/${id}/create`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data) {
                const pages = get(data, 'pages', []);
                yield put(addFormSection.success(pages));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(addFormSection.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(addFormSection.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormSectionUpdating() {
    while (true) {
        const {
            payload: {id, callback, showAlert, ...values},
        }: IActionType<FormAddPageRequest> = yield take(FORM_SECTION_UPDATE.REQUEST);
        try {
            const data: FormTotalInformationTypes[] = yield call(http, `section/${id}/edit`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data) {
                const pages = get(data, 'pages', []);
                yield put(updateFormSection.success(pages));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(updateFormSection.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updateFormSection.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormSectionDeleting() {
    while (true) {
        const {
            payload: {id, callback, showAlert},
        }: IActionType<CommonFormBuilderRequest> = yield take(FORM_SECTION_DELETE.REQUEST);
        try {
            const data: FormTotalInformationTypes[] = yield call(http, `section/${id}/delete`, {
                method: 'DELETE',
            });
            if (data) {
                const pages = get(data, 'pages', []);
                yield put(deleteFormSection.success(pages));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(deleteFormSection.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(deleteFormSection.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormSectionCopying() {
    while (true) {
        const {
            payload: {id, showAlert, ...values},
        }: IActionType<CommonFormBuilderRequest> = yield take(FORM_SECTION_COPY.REQUEST);
        try {
            const data: FormTotalInformationTypes[] = yield call(http, `section/${id}/copy`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data) {
                const pages = get(data, 'pages', []);
                yield put(copyFormSection.success(pages));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(copyFormSection.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(copyFormSection.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormSectionUpPriority() {
    while (true) {
        const {
            payload: {id, showAlert},
        }: IActionType<FormChangeStatusType> = yield take(FORM_SECTION_UP.REQUEST);
        try {
            const data: FormItemBasic[] = yield call(http, `section/${id}/up`, {
                method: 'POST',
                body: JSON.stringify({}),
            });
            if (data) {
                const forms = get(data, 'pages', []);
                yield put(formSectionUpPriority.success(forms));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(formSectionUpPriority.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(formSectionUpPriority.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormSectionDownPriority() {
    while (true) {
        const {
            payload: {id, showAlert},
        }: IActionType<FormChangeStatusType> = yield take(FORM_SECTION_DOWN.REQUEST);
        try {
            const data: FormItemBasic[] = yield call(http, `section/${id}/down`, {
                method: 'POST',
                body: JSON.stringify({}),
            });
            if (data) {
                const forms = get(data, 'pages', []);
                yield put(formSectionDownPriority.success(forms));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(formSectionDownPriority.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(formSectionDownPriority.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormSectionPrioritiesRecalculation() {
    while (true) {
        const {
            payload: {id, showAlert},
        }: IActionType<FormChangeStatusType> = yield take(FORM_SECTION_RECALCULATE_PRIORITIES.REQUEST);
        try {
            const data: FormItemBasic[] = yield call(http, `section/${id}/priorities`, {
                method: 'POST',
                body: JSON.stringify({}),
            });
            if (data) {
                const forms = get(data, 'pages', []);
                yield put(formSectionPrioritiesRecalculate.success(forms));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(formSectionPrioritiesRecalculate.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(formSectionPrioritiesRecalculate.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

export default [
    fork(watchFormSectionAdding),
    fork(watchFormSectionUpdating),
    fork(watchFormSectionDeleting),
    fork(watchFormSectionCopying),
    fork(watchFormSectionUpPriority),
    fork(watchFormSectionDownPriority),
    fork(watchFormSectionPrioritiesRecalculation),
];
