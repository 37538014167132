import {
    ResourceFieldsListRequestTypes,
    ResourceFieldCreateRequestTypes,
    ResourceFieldUpdateRequestTypes,
    ResourceFieldDeleteRequestTypes,
    ResourceFieldItemTypes,
    ResourceFieldBudgetListRequestTypes,
    ResourceFieldBudgetCreateRequestTypes,
    ResourceFieldBudgetUpdateRequestTypes,
    ResourceFieldBudgetDeleteRequestTypes,
    ResourceFieldBudgetItemTypes,
    ResourceFieldSlotListRequestTypes,
    ResourceFieldSlotCreateRequestTypes,
    ResourceFieldSlotUpdateRequestTypes,
    ResourceFieldSlotDeleteRequestTypes,
    ResourceFieldSlotItemTypes,
    ResourceFieldCalendarListRequestTypes,
    ResourceFieldCalendarInfoRequestTypes,
    ResourceFieldCalendarCreateRequestTypes,
    ResourceFieldCalendarUpdateRequestTypes,
    ResourceFieldCalendarDeleteRequestTypes,
    ResourceFieldCalendarItemTypes,
    ResourceFieldBookframeCreateRequestTypes,
    ResourceFieldBookframeUpdateRequestTypes,
    ResourceFieldBookframeDeleteRequestTypes,
    ResourceFieldExceptionCreateRequestTypes,
    ResourceFieldExceptionUpdateRequestTypes,
    ResourceFieldExceptionDeleteRequestTypes,
    ResourceFieldCalendarSlotCreateRequestTypes,
    ResourceFieldCalendarSlotUpdateRequestTypes,
    ResourceFieldCalendarSlotDeleteRequestTypes,
} from 'appRedux/actions/resourceFields/types';

import {requestTypeCreator} from 'helpers/index';

export const GET_RESOURCE_FIELDS_LIST = requestTypeCreator('GET_RESOURCE_FIELDS_LIST');
export const CREATE_RESOURCE_FIELD = requestTypeCreator('CREATE_RESOURCE_FIELD');
export const UPDATE_RESOURCE_FIELD = requestTypeCreator('UPDATE_RESOURCE_FIELD');
export const DELETE_RESOURCE_FIELD = requestTypeCreator('DELETE_RESOURCE_FIELD');

export const GET_RESOURCE_FIELD_BUDGET_LIST = requestTypeCreator('GET_RESOURCE_FIELD_BUDGET_LIST');
export const CREATE_RESOURCE_FIELD_BUDGET = requestTypeCreator('CREATE_RESOURCE_FIELD_BUDGET');
export const UPDATE_RESOURCE_FIELD_BUDGET = requestTypeCreator('UPDATE_RESOURCE_FIELD_BUDGET');
export const DELETE_RESOURCE_FIELD_BUDGET = requestTypeCreator('DELETE_RESOURCE_FIELD_BUDGET');

export const GET_RESOURCE_FIELD_SLOT_LIST = requestTypeCreator('GET_RESOURCE_FIELD_SLOT_LIST');
export const CREATE_RESOURCE_FIELD_SLOT = requestTypeCreator('CREATE_RESOURCE_FIELD_SLOT');
export const UPDATE_RESOURCE_FIELD_SLOT = requestTypeCreator('UPDATE_RESOURCE_FIELD_SLOT');
export const DELETE_RESOURCE_FIELD_SLOT = requestTypeCreator('DELETE_RESOURCE_FIELD_SLOT');

export const GET_RESOURCE_FIELD_CALENDAR_LIST = requestTypeCreator('GET_RESOURCE_FIELD_CALENDAR_LIST');
export const GET_RESOURCE_FIELD_CALENDAR_INFO = requestTypeCreator('GET_RESOURCE_FIELD_CALENDAR_INFO');
export const CREATE_RESOURCE_FIELD_CALENDAR = requestTypeCreator('CREATE_RESOURCE_FIELD_CALENDAR');
export const UPDATE_RESOURCE_FIELD_CALENDAR = requestTypeCreator('UPDATE_RESOURCE_FIELD_CALENDAR');
export const DELETE_RESOURCE_FIELD_CALENDAR = requestTypeCreator('DELETE_RESOURCE_FIELD_CALENDAR');

export const CREATE_RESOURCE_FIELD_BOOKFRAME = requestTypeCreator('CREATE_RESOURCE_FIELD_BOOKFRAME');
export const UPDATE_RESOURCE_FIELD_BOOKFRAME = requestTypeCreator('UPDATE_RESOURCE_FIELD_BOOKFRAME');
export const DELETE_RESOURCE_FIELD_BOOKFRAME = requestTypeCreator('DELETE_RESOURCE_FIELD_BOOKFRAME');

export const CREATE_RESOURCE_FIELD_EXCEPTION = requestTypeCreator('CREATE_RESOURCE_FIELD_EXCEPTION');
export const UPDATE_RESOURCE_FIELD_EXCEPTION = requestTypeCreator('UPDATE_RESOURCE_FIELD_EXCEPTION');
export const DELETE_RESOURCE_FIELD_EXCEPTION = requestTypeCreator('DELETE_RESOURCE_FIELD_EXCEPTION');

export const CREATE_RESOURCE_FIELD_CALENDAR_SLOT = requestTypeCreator('CREATE_RESOURCE_FIELD_CALENDAR_SLOT');
export const UPDATE_RESOURCE_FIELD_CALENDAR_SLOT = requestTypeCreator('UPDATE_RESOURCE_FIELD_CALENDAR_SLOT');
export const DELETE_RESOURCE_FIELD_CALENDAR_SLOT = requestTypeCreator('DELETE_RESOURCE_FIELD_CALENDAR_SLOT');

export const getResourceFieldsList = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GET_RESOURCE_FIELDS_LIST.ERROR,
    }),
    request: (payload: ResourceFieldsListRequestTypes): IActionType<ResourceFieldsListRequestTypes> => ({
        payload,
        type: GET_RESOURCE_FIELDS_LIST.REQUEST,
    }),
    success: (payload: ResourceFieldItemTypes[]): IActionType<ResourceFieldItemTypes[]> => ({
        payload,
        type: GET_RESOURCE_FIELDS_LIST.SUCCESS,
    }),
};

export const createResourceField = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: CREATE_RESOURCE_FIELD.ERROR,
    }),
    request: (payload: ResourceFieldCreateRequestTypes): IActionType<ResourceFieldCreateRequestTypes> => ({
        payload,
        type: CREATE_RESOURCE_FIELD.REQUEST,
    }),
    success: (payload: ResourceFieldItemTypes[]): IActionType<ResourceFieldItemTypes[]> => ({
        payload,
        type: CREATE_RESOURCE_FIELD.SUCCESS,
    }),
};

export const updateResourceField = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: UPDATE_RESOURCE_FIELD.ERROR,
    }),
    request: (payload: ResourceFieldUpdateRequestTypes): IActionType<ResourceFieldUpdateRequestTypes> => ({
        payload,
        type: UPDATE_RESOURCE_FIELD.REQUEST,
    }),
    success: (payload: ResourceFieldItemTypes[]): IActionType<ResourceFieldItemTypes[]> => ({
        payload,
        type: UPDATE_RESOURCE_FIELD.SUCCESS,
    }),
};

export const deleteResourceField = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: DELETE_RESOURCE_FIELD.ERROR,
    }),
    request: (payload: ResourceFieldDeleteRequestTypes): IActionType<ResourceFieldDeleteRequestTypes> => ({
        payload,
        type: DELETE_RESOURCE_FIELD.REQUEST,
    }),
    success: (payload: ResourceFieldItemTypes[]): IActionType<ResourceFieldItemTypes[]> => ({
        payload,
        type: DELETE_RESOURCE_FIELD.SUCCESS,
    }),
};

export const getResourceFieldBudgetList = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GET_RESOURCE_FIELD_BUDGET_LIST.ERROR,
    }),
    request: (payload: ResourceFieldBudgetListRequestTypes): IActionType<ResourceFieldBudgetListRequestTypes> => ({
        payload,
        type: GET_RESOURCE_FIELD_BUDGET_LIST.REQUEST,
    }),
    success: (payload: ResourceFieldBudgetItemTypes[]): IActionType<ResourceFieldBudgetItemTypes[]> => ({
        payload,
        type: GET_RESOURCE_FIELD_BUDGET_LIST.SUCCESS,
    }),
};

export const createResourceFieldBudget = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: CREATE_RESOURCE_FIELD_BUDGET.ERROR,
    }),
    request: (payload: ResourceFieldBudgetCreateRequestTypes): IActionType<ResourceFieldBudgetCreateRequestTypes> => ({
        payload,
        type: CREATE_RESOURCE_FIELD_BUDGET.REQUEST,
    }),
    success: (payload: ResourceFieldBudgetItemTypes[]): IActionType<ResourceFieldBudgetItemTypes[]> => ({
        payload,
        type: CREATE_RESOURCE_FIELD_BUDGET.SUCCESS,
    }),
};

export const updateResourceFieldBudget = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: UPDATE_RESOURCE_FIELD_BUDGET.ERROR,
    }),
    request: (payload: ResourceFieldBudgetUpdateRequestTypes): IActionType<ResourceFieldBudgetUpdateRequestTypes> => ({
        payload,
        type: UPDATE_RESOURCE_FIELD_BUDGET.REQUEST,
    }),
    success: (payload: ResourceFieldBudgetItemTypes[]): IActionType<ResourceFieldBudgetItemTypes[]> => ({
        payload,
        type: UPDATE_RESOURCE_FIELD_BUDGET.SUCCESS,
    }),
};

export const deleteResourceFieldBudget = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: DELETE_RESOURCE_FIELD_BUDGET.ERROR,
    }),
    request: (payload: ResourceFieldBudgetDeleteRequestTypes): IActionType<ResourceFieldBudgetDeleteRequestTypes> => ({
        payload,
        type: DELETE_RESOURCE_FIELD_BUDGET.REQUEST,
    }),
    success: (payload: ResourceFieldBudgetItemTypes[]): IActionType<ResourceFieldBudgetItemTypes[]> => ({
        payload,
        type: DELETE_RESOURCE_FIELD_BUDGET.SUCCESS,
    }),
};

export const getResourceFieldSlotList = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GET_RESOURCE_FIELD_SLOT_LIST.ERROR,
    }),
    request: (payload: ResourceFieldSlotListRequestTypes): IActionType<ResourceFieldSlotListRequestTypes> => ({
        payload,
        type: GET_RESOURCE_FIELD_SLOT_LIST.REQUEST,
    }),
    success: (payload: ResourceFieldSlotItemTypes[]): IActionType<ResourceFieldSlotItemTypes[]> => ({
        payload,
        type: GET_RESOURCE_FIELD_SLOT_LIST.SUCCESS,
    }),
};

export const createResourceFieldSlot = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: CREATE_RESOURCE_FIELD_SLOT.ERROR,
    }),
    request: (payload: ResourceFieldSlotCreateRequestTypes): IActionType<ResourceFieldSlotCreateRequestTypes> => ({
        payload,
        type: CREATE_RESOURCE_FIELD_SLOT.REQUEST,
    }),
    success: (payload: ResourceFieldSlotItemTypes[]): IActionType<ResourceFieldSlotItemTypes[]> => ({
        payload,
        type: CREATE_RESOURCE_FIELD_SLOT.SUCCESS,
    }),
};

export const updateResourceFieldSlot = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: UPDATE_RESOURCE_FIELD_SLOT.ERROR,
    }),
    request: (payload: ResourceFieldSlotUpdateRequestTypes): IActionType<ResourceFieldSlotUpdateRequestTypes> => ({
        payload,
        type: UPDATE_RESOURCE_FIELD_SLOT.REQUEST,
    }),
    success: (payload: ResourceFieldSlotItemTypes[]): IActionType<ResourceFieldSlotItemTypes[]> => ({
        payload,
        type: UPDATE_RESOURCE_FIELD_SLOT.SUCCESS,
    }),
};

export const deleteResourceFieldSlot = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: DELETE_RESOURCE_FIELD_SLOT.ERROR,
    }),
    request: (payload: ResourceFieldSlotDeleteRequestTypes): IActionType<ResourceFieldSlotDeleteRequestTypes> => ({
        payload,
        type: DELETE_RESOURCE_FIELD_SLOT.REQUEST,
    }),
    success: (payload: ResourceFieldSlotItemTypes[]): IActionType<ResourceFieldSlotItemTypes[]> => ({
        payload,
        type: DELETE_RESOURCE_FIELD_SLOT.SUCCESS,
    }),
};

export const getResourceFieldCalendarList = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GET_RESOURCE_FIELD_CALENDAR_LIST.ERROR,
    }),
    request: (payload: ResourceFieldCalendarListRequestTypes): IActionType<ResourceFieldCalendarListRequestTypes> => ({
        payload,
        type: GET_RESOURCE_FIELD_CALENDAR_LIST.REQUEST,
    }),
    success: (payload: ResourceFieldCalendarItemTypes[]): IActionType<ResourceFieldCalendarItemTypes[]> => ({
        payload,
        type: GET_RESOURCE_FIELD_CALENDAR_LIST.SUCCESS,
    }),
};

export const getResourceFieldCalendarInfo = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GET_RESOURCE_FIELD_CALENDAR_INFO.ERROR,
    }),
    request: (payload: ResourceFieldCalendarInfoRequestTypes): IActionType<ResourceFieldCalendarInfoRequestTypes> => ({
        payload,
        type: GET_RESOURCE_FIELD_CALENDAR_INFO.REQUEST,
    }),
    success: (payload: ResourceFieldCalendarItemTypes): IActionType<ResourceFieldCalendarItemTypes> => ({
        payload,
        type: GET_RESOURCE_FIELD_CALENDAR_INFO.SUCCESS,
    }),
};

export const createResourceFieldCalendar = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: CREATE_RESOURCE_FIELD_CALENDAR.ERROR,
    }),
    request: (
        payload: ResourceFieldCalendarCreateRequestTypes,
    ): IActionType<ResourceFieldCalendarCreateRequestTypes> => ({
        payload,
        type: CREATE_RESOURCE_FIELD_CALENDAR.REQUEST,
    }),
    success: (payload: ResourceFieldCalendarItemTypes[]): IActionType<ResourceFieldCalendarItemTypes[]> => ({
        payload,
        type: CREATE_RESOURCE_FIELD_CALENDAR.SUCCESS,
    }),
};

export const updateResourceFieldCalendar = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: UPDATE_RESOURCE_FIELD_CALENDAR.ERROR,
    }),
    request: (
        payload: ResourceFieldCalendarUpdateRequestTypes,
    ): IActionType<ResourceFieldCalendarUpdateRequestTypes> => ({
        payload,
        type: UPDATE_RESOURCE_FIELD_CALENDAR.REQUEST,
    }),
    success: (payload: ResourceFieldCalendarItemTypes): IActionType<ResourceFieldCalendarItemTypes> => ({
        payload,
        type: UPDATE_RESOURCE_FIELD_CALENDAR.SUCCESS,
    }),
};

export const deleteResourceFieldCalendar = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: DELETE_RESOURCE_FIELD_CALENDAR.ERROR,
    }),
    request: (
        payload: ResourceFieldCalendarDeleteRequestTypes,
    ): IActionType<ResourceFieldCalendarDeleteRequestTypes> => ({
        payload,
        type: DELETE_RESOURCE_FIELD_CALENDAR.REQUEST,
    }),
    success: (payload: ResourceFieldCalendarItemTypes[]): IActionType<ResourceFieldCalendarItemTypes[]> => ({
        payload,
        type: DELETE_RESOURCE_FIELD_CALENDAR.SUCCESS,
    }),
};

export const createResourceFieldBookframe = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: CREATE_RESOURCE_FIELD_BOOKFRAME.ERROR,
    }),
    request: (
        payload: ResourceFieldBookframeCreateRequestTypes,
    ): IActionType<ResourceFieldBookframeCreateRequestTypes> => ({
        payload,
        type: CREATE_RESOURCE_FIELD_BOOKFRAME.REQUEST,
    }),
    success: (payload: ResourceFieldCalendarItemTypes): IActionType<ResourceFieldCalendarItemTypes> => ({
        payload,
        type: CREATE_RESOURCE_FIELD_BOOKFRAME.SUCCESS,
    }),
};

export const updateResourceFieldBookframe = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: UPDATE_RESOURCE_FIELD_BOOKFRAME.ERROR,
    }),
    request: (
        payload: ResourceFieldBookframeUpdateRequestTypes,
    ): IActionType<ResourceFieldBookframeUpdateRequestTypes> => ({
        payload,
        type: UPDATE_RESOURCE_FIELD_BOOKFRAME.REQUEST,
    }),
    success: (payload: ResourceFieldCalendarItemTypes): IActionType<ResourceFieldCalendarItemTypes> => ({
        payload,
        type: UPDATE_RESOURCE_FIELD_BOOKFRAME.SUCCESS,
    }),
};

export const deleteResourceFieldBookframe = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: DELETE_RESOURCE_FIELD_BOOKFRAME.ERROR,
    }),
    request: (
        payload: ResourceFieldBookframeDeleteRequestTypes,
    ): IActionType<ResourceFieldBookframeDeleteRequestTypes> => ({
        payload,
        type: DELETE_RESOURCE_FIELD_BOOKFRAME.REQUEST,
    }),
    success: (payload: ResourceFieldCalendarItemTypes): IActionType<ResourceFieldCalendarItemTypes> => ({
        payload,
        type: DELETE_RESOURCE_FIELD_BOOKFRAME.SUCCESS,
    }),
};

export const createResourceFieldException = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: CREATE_RESOURCE_FIELD_EXCEPTION.ERROR,
    }),
    request: (
        payload: ResourceFieldExceptionCreateRequestTypes,
    ): IActionType<ResourceFieldExceptionCreateRequestTypes> => ({
        payload,
        type: CREATE_RESOURCE_FIELD_EXCEPTION.REQUEST,
    }),
    success: (payload: ResourceFieldCalendarItemTypes): IActionType<ResourceFieldCalendarItemTypes> => ({
        payload,
        type: CREATE_RESOURCE_FIELD_EXCEPTION.SUCCESS,
    }),
};

export const updateResourceFieldException = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: UPDATE_RESOURCE_FIELD_EXCEPTION.ERROR,
    }),
    request: (
        payload: ResourceFieldExceptionUpdateRequestTypes,
    ): IActionType<ResourceFieldExceptionUpdateRequestTypes> => ({
        payload,
        type: UPDATE_RESOURCE_FIELD_EXCEPTION.REQUEST,
    }),
    success: (payload: ResourceFieldCalendarItemTypes): IActionType<ResourceFieldCalendarItemTypes> => ({
        payload,
        type: UPDATE_RESOURCE_FIELD_EXCEPTION.SUCCESS,
    }),
};

export const deleteResourceFieldException = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: DELETE_RESOURCE_FIELD_EXCEPTION.ERROR,
    }),
    request: (
        payload: ResourceFieldExceptionDeleteRequestTypes,
    ): IActionType<ResourceFieldExceptionDeleteRequestTypes> => ({
        payload,
        type: DELETE_RESOURCE_FIELD_EXCEPTION.REQUEST,
    }),
    success: (payload: ResourceFieldCalendarItemTypes): IActionType<ResourceFieldCalendarItemTypes> => ({
        payload,
        type: DELETE_RESOURCE_FIELD_EXCEPTION.SUCCESS,
    }),
};

export const createResourceFieldCalendarSlot = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: CREATE_RESOURCE_FIELD_CALENDAR_SLOT.ERROR,
    }),
    request: (
        payload: ResourceFieldCalendarSlotCreateRequestTypes,
    ): IActionType<ResourceFieldCalendarSlotCreateRequestTypes> => ({
        payload,
        type: CREATE_RESOURCE_FIELD_CALENDAR_SLOT.REQUEST,
    }),
    success: (payload: ResourceFieldCalendarItemTypes): IActionType<ResourceFieldCalendarItemTypes> => ({
        payload,
        type: CREATE_RESOURCE_FIELD_CALENDAR_SLOT.SUCCESS,
    }),
};

export const updateResourceFieldCalendarSlot = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: UPDATE_RESOURCE_FIELD_CALENDAR_SLOT.ERROR,
    }),
    request: (
        payload: ResourceFieldCalendarSlotUpdateRequestTypes,
    ): IActionType<ResourceFieldCalendarSlotUpdateRequestTypes> => ({
        payload,
        type: UPDATE_RESOURCE_FIELD_CALENDAR_SLOT.REQUEST,
    }),
    success: (payload: ResourceFieldCalendarItemTypes): IActionType<ResourceFieldCalendarItemTypes> => ({
        payload,
        type: UPDATE_RESOURCE_FIELD_CALENDAR_SLOT.SUCCESS,
    }),
};

export const deleteResourceFieldCalendarSlot = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: DELETE_RESOURCE_FIELD_CALENDAR_SLOT.ERROR,
    }),
    request: (
        payload: ResourceFieldCalendarSlotDeleteRequestTypes,
    ): IActionType<ResourceFieldCalendarSlotDeleteRequestTypes> => ({
        payload,
        type: DELETE_RESOURCE_FIELD_CALENDAR_SLOT.REQUEST,
    }),
    success: (payload: ResourceFieldCalendarItemTypes): IActionType<ResourceFieldCalendarItemTypes> => ({
        payload,
        type: DELETE_RESOURCE_FIELD_CALENDAR_SLOT.SUCCESS,
    }),
};
