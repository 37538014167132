import React, {FC, useContext} from 'react';
import {useLocation, useParams} from 'react-router-dom';

import Box from '@mui/material/Box';

import {RouteContext} from 'contexts/route/context';

import {
    AgentMenuItemTypes,
    isMenuItemActive,
    isMenuSubItemActive,
    isAllSubItemsInactive,
} from 'components/AgentScreenComponents/helper';
import MenuLink from 'components/AgentScreenComponents/MenuLink';

import {isCurrentSubItemActive} from 'helpers/menuHelper';

import {routes} from 'config/index';

interface MenuItemsBlockTypes {
    isMenuCollapsed: boolean;
    items: AgentMenuItemTypes[];
    toggleAdminMenu: (value: string) => void;
}

const MenuItemsBlock: FC<MenuItemsBlockTypes> = ({items, isMenuCollapsed, toggleAdminMenu}) => {
    const {pathname, search} = useLocation();
    const {uuid} = useParams();

    const {isAgentPage} = useContext(RouteContext);

    return (
        <Box sx={{mt: 3}}>
            {items.map((menuItem: AgentMenuItemTypes, index: number) => {
                if (menuItem.isHidden) {
                    return null;
                }

                const path = isAgentPage ? routes.AGENT : routes.ADMIN;

                const showSubItems =
                    isMenuItemActive(menuItem, pathname, search) ||
                    isMenuSubItemActive(menuItem, pathname, search, uuid);

                const isActive = menuItem.subItems
                    ? isMenuItemActive(menuItem, pathname, search) &&
                      isAllSubItemsInactive(menuItem.subItems, pathname, search, isAgentPage, uuid)
                    : showSubItems;

                const subItems = menuItem?.subItems?.filter(subItem => !subItem.isHidden);
                const subItemsOpened = showSubItems && subItems && subItems.length > 0;

                return (
                    <Box key={`menuItem-${index}`} sx={{mb: 0.25}}>
                        <MenuLink
                            isActive={isActive}
                            isMenuCollapsed={isMenuCollapsed}
                            menuItem={menuItem}
                            toggleAdminMenu={toggleAdminMenu}
                            hasSubItems={subItemsOpened}
                        />
                        {subItemsOpened && (
                            <Box sx={{mt: 0, mb: 3}}>
                                {subItems &&
                                    subItems.map((subItem: AgentMenuItemTypes, subIndex: number) => {
                                        return (
                                            <MenuLink
                                                key={`subItem-${index}-${subIndex}`}
                                                isActive={isCurrentSubItemActive(
                                                    menuItem,
                                                    subItem,
                                                    path,
                                                    pathname,
                                                    search,
                                                    uuid,
                                                )}
                                                isMenuCollapsed={isMenuCollapsed}
                                                menuItem={subItem}
                                                toggleAdminMenu={toggleAdminMenu}
                                                isSubItem
                                            />
                                        );
                                    })}
                            </Box>
                        )}
                    </Box>
                );
            })}
        </Box>
    );
};

export default MenuItemsBlock;
