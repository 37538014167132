import React, {FC, useContext} from 'react';
import get from 'lodash/get';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import {RootReducer} from 'appRedux/reducers';
import {RequesterCaseCommentsTypes} from 'appRedux/actions/comments/types';

import {PermissionContext} from 'contexts/permission/context';

import CommentRow from 'pages/agent/requesterPage/common/CommentRow';
import CommentEncryptRow from 'pages/agent/requesterPage/common/CommentEncryptRow';

interface CommentsListType {
    sectionId: number | null;
    isCommentsExpanded: boolean;
    toggleIsCommentsExpanded: () => void;
    inRightPanel?: boolean;
}

const CommentsList: FC<CommentsListType> = ({
    sectionId,
    inRightPanel,
    isCommentsExpanded,
    toggleIsCommentsExpanded,
}) => {
    const [t] = useTranslation();

    const {
        requestCase: {currentCase},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const {isCaseEditEnabled} = useContext(PermissionContext);

    const comments: RequesterCaseCommentsTypes[] = get(currentCase, 'comments', []);

    const commentsBySection = comments.filter(item => item.section === sectionId);

    if (commentsBySection.length === 0) {
        return null;
    }
    if (!sectionId) {
        return (
            <>
                {commentsBySection &&
                    commentsBySection.map((item: RequesterCaseCommentsTypes, index: number) => {
                        if (item.isEncryptInfo) {
                            return (
                                <CommentEncryptRow item={item} key={`comment-${index}`} inRightPanel={inRightPanel} />
                            );
                        }
                        return <CommentRow item={item} key={`comment-${index}`} inRightPanel={inRightPanel} />;
                    })}
                {!inRightPanel && isCaseEditEnabled && <Divider sx={{mt: 1, mb: 1}} />}
            </>
        );
    }
    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    mb: 1,
                    cursor: 'pointer',
                }}
                onClick={toggleIsCommentsExpanded}
            >
                <Typography sx={{fontWeight: 600}}>
                    {`${t('orguser.requesterCase.comments.showComments')} (${commentsBySection.length})`}
                </Typography>
                <IconButton>{isCommentsExpanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}</IconButton>
            </Box>
            {isCommentsExpanded &&
                commentsBySection &&
                commentsBySection.map((item: RequesterCaseCommentsTypes, index: number) => {
                    if (item.isEncryptInfo) {
                        return <CommentEncryptRow item={item} key={`comment-encrypt-${index}`} />;
                    }
                    return <CommentRow item={item} key={`comment-${index}`} />;
                })}
            {isCaseEditEnabled && <Divider sx={{mt: 1, mb: 1}} />}
        </>
    );
};

export default CommentsList;
