import React from 'react';
import {useTranslation} from 'react-i18next';

import Typography from '@mui/material/Typography';
import {GridColDef} from '@mui/x-data-grid';

import MarkdownWithShowAll from 'components/TextWithShowAll/MarkdownWithShowAll';
import {
    getResourceFieldBudgetTypeLabel,
    getResourceFieldBudgetResetIntervalTypeLabel,
} from 'components/Forms/ResourceField/helper';

import DeleteResourceFieldBudgetButton from 'pages/admin/updateResourceField/budget/DeleteResourceFieldBudgetButton';
import UpdateResourceFieldBudgetButton from 'pages/admin/updateResourceField/budget/UpdateResourceFieldBudgetButton';

const getResourceFieldBudgetColumns = (): GridColDef[] | null => {
    const [t] = useTranslation();

    return [
        {
            field: 'title',
            headerName: t('orguser.resourceFields.title'),
            minWidth: 280,
            flex: 1,
            renderCell: ({value}) => {
                return <Typography>{value}</Typography>;
            },
        },
        {
            field: 'description',
            headerName: t('orguser.resourceFields.description'),
            minWidth: 350,
            flex: 1,
            renderCell: ({value}) => {
                return <MarkdownWithShowAll text={value} isTable />;
            },
        },
        {
            field: 'type',
            headerName: t('orguser.resourceFields.type'),
            flex: 1,
            renderCell: ({value}) => {
                return <Typography>{t(getResourceFieldBudgetTypeLabel(value))}</Typography>;
            },
        },
        {
            field: 'amount',
            headerName: t('orguser.resourceFields.amount'),
            flex: 1,
            renderCell: ({value}) => {
                return <Typography>{value}</Typography>;
            },
        },
        {
            field: 'resetInterval',
            headerName: t('orguser.resourceFields.resetInterval'),
            flex: 1,
            renderCell: ({value}) => {
                return <Typography>{t(getResourceFieldBudgetResetIntervalTypeLabel(value))}</Typography>;
            },
        },
        {
            field: 'actions',
            headerName: t('common.action'),
            align: 'right',
            maxWidth: 90,
            flex: 1,
            renderCell: ({row}) => {
                return (
                    <>
                        <UpdateResourceFieldBudgetButton item={row} />
                        <DeleteResourceFieldBudgetButton item={row} />
                    </>
                );
            },
        },
    ];
};

export default getResourceFieldBudgetColumns;
