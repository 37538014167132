import React, {FC} from 'react';
import get from 'lodash/get';
import {useTranslation} from 'react-i18next';

import {LineChart, LineChartProps, lineElementClasses} from '@mui/x-charts/LineChart';
import {axisClasses} from '@mui/x-charts/ChartsAxis';
import {chartsGridClasses} from '@mui/x-charts/ChartsGrid';
import Box from '@mui/material/Box';

import {PanelItemsTypes} from 'appRedux/actions/analytics/types';

interface StatusTimeAnalysisChartType {
    item: PanelItemsTypes;
}

const StatusTimeAnalysisChart: FC<StatusTimeAnalysisChartType> = ({item}) => {
    const [t] = useTranslation();

    const dataset = item.cases.map(params => {
        return {
            minimal: get(params, 0, 0),
            maximal: get(params, 1, 0),
            average: get(params, 2, 0),
            status: get(params, 3, 0),
        };
    });

    const chartSettingsV: Partial<LineChartProps> = {
        dataset,
        margin: {top: 15, bottom: 30, left: 70},
        xAxis: [{scaleType: 'point', dataKey: 'status'}],
        yAxis: [
            {
                label: t('orguser.analytics.panels.statusTimeAnalysis.verticalAxis'),
            },
        ],
        sx: {
            [`& .${axisClasses.directionY} .${axisClasses.label}`]: {
                transform: 'translateX(-25px) !important',
            },
            [`& .${lineElementClasses.root}`]: {
                strokeWidth: 0,
            },
            [`& .${chartsGridClasses.line}`]: {strokeDasharray: '5 3', strokeWidth: 2},
            '& tspan': {
                fontSize: 14,
            },
        },
        slotProps: {
            legend: {
                hidden: true,
            },
        },
    };

    return (
        <Box style={{height: '600px', width: '100%'}}>
            <LineChart
                slotProps={{legend: {hidden: true}}}
                series={[
                    {dataKey: 'minimal', label: t('common.statistic.min'), stack: 'stack A', color: '#205072'},
                    {dataKey: 'average', label: t('common.statistic.avg'), stack: 'stack B', color: '#329D9C'},
                    {dataKey: 'maximal', label: t('common.statistic.max'), stack: 'stack C', color: '#CC4941'},
                ]}
                grid={{horizontal: true, vertical: true}}
                {...chartSettingsV}
            />
        </Box>
    );
};

export default StatusTimeAnalysisChart;
