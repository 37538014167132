import React from 'react';
import {useSelector} from 'react-redux';
import get from 'lodash/get';

import {RootReducer} from 'appRedux/reducers';

import LoginSso from 'pages/start/loginSso/LoginSso';
import LoginPageContent from 'pages/start/login/partials/LoginPageContent';

const LoginScreen = () => {
    const {
        auth: {organization},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const isAutoSsoEnabled = get(organization, 'isAutoSsoEnabled', false);

    if (isAutoSsoEnabled) {
        return <LoginSso />;
    }
    return <LoginPageContent />;
};

export default LoginScreen;
