import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import Typography from '@mui/material/Typography';

import {RootReducer} from 'appRedux/reducers';
import {ORGANIZATION_LIST} from 'appRedux/actions/organization';

import {RouteContext} from 'contexts/route/context';
import {PermissionContext} from 'contexts/permission/context';

import ClientSectionWrapper from 'components/ClientScreenComponents/SectionWrapper';

import OrganizationContent from 'pages/admin/myOrganization/OrganizationContent';

import {checkSubdomainCorrect} from 'helpers/security';

const MyOrganization: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {setPageTitle, setTopBarTitle, setBackLink, setBackTab, setBackLinkLabel} = useContext(RouteContext);
    const {isOrganizationViewEnabled} = useContext(PermissionContext);

    const getOrganizationList = useCallback(() => dispatch({type: ORGANIZATION_LIST.REQUEST}), [dispatch]);

    const {
        admin: {organizationList},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const myOrganization = organizationList && organizationList.length > 0 ? organizationList[0] : null;
    const isSubdomainCorrect = checkSubdomainCorrect(myOrganization);

    useEffect(() => {
        if (isOrganizationViewEnabled) {
            getOrganizationList();
        }
    }, []);

    useEffect(() => {
        setPageTitle('');
        setTopBarTitle('');
        setBackLink('');
        setBackTab('');
        setBackLinkLabel('');
    }, []);

    if (!isOrganizationViewEnabled) return null;

    if (myOrganization && isSubdomainCorrect) {
        return <OrganizationContent organization={myOrganization} />;
    }
    return (
        <ClientSectionWrapper title={t('superadmin.organizations.organizationInfo')}>
            <Typography>
                {t(
                    isSubdomainCorrect
                        ? 'superadmin.organizations.noneOrganization'
                        : 'messages.error.incorrectSubdomain',
                )}
            </Typography>
        </ClientSectionWrapper>
    );
};

export default MyOrganization;
