export const WORKFLOW_STATUS_RULE_TYPE_ALL_SECTIONS_CHECKED = 1;
export const WORKFLOW_STATUS_RULE_TYPE_APPROVAL_REQUIRED = 2;

export interface WorkflowRuleTypes {
    type: number;
    label: string;
}

export const workflowRuleTypes: WorkflowRuleTypes[] = [
    {
        type: WORKFLOW_STATUS_RULE_TYPE_ALL_SECTIONS_CHECKED,
        label: 'orguser.workflows.rules.types.allSectionsChecked',
    },
    {
        type: WORKFLOW_STATUS_RULE_TYPE_APPROVAL_REQUIRED,
        label: 'orguser.workflows.rules.types.approvalRequired',
    },
];

export const getWorkflowRuleLabel = (type: number): string => {
    const currentResult = workflowRuleTypes.find(item => item.type === type);
    return currentResult ? currentResult.label : '';
};
