import React, {FC, useContext, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import get from 'lodash/get';
import {useTranslation} from 'react-i18next';

import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import {CircularProgress} from '@mui/material';

import {RootReducer} from 'appRedux/reducers';

import {MediaContext} from 'contexts/media/context';
import {CaseKeyContext} from 'contexts/caseKey/context';

import ImagePreview from 'components/FilesListWithGallery/ImagePreview';
import GalleryWrapper from 'components/FilesListWithGallery/GalleryWrapper';
import DesktopGallery from 'components/FilesListWithGallery/DesktopGallery';
import MobileGallery from 'components/FilesListWithGallery/MobileGallery';

import {CommonFileType} from 'pages/agent/requesterPage/partials/helper';

import {getEncryptedFileData} from 'helpers/cryptoApiHelper';

import {theme} from 'config/theme';

interface FilesGalleryType {
    isModalOpened: boolean;
    toggleIsModalOpened: () => void;
    documentIsPdf: boolean;
    documentIsImage: boolean;
    selectedFile: CommonFileType;
    allFiles: CommonFileType[];
    downloadFile: (value: string, fileName?: string) => void;
    documentFormField?: string;
    documentImageSource?: string;
    getModalContent?: (file: CommonFileType) => void;
}

const FilesGallery: FC<FilesGalleryType> = ({
    isModalOpened,
    toggleIsModalOpened,
    documentIsPdf,
    documentIsImage,
    selectedFile,
    allFiles,
    downloadFile,
    documentFormField,
    documentImageSource,
    getModalContent,
}) => {
    const [t] = useTranslation();

    const {index, title, extension, encryptPrefix, fileLink} = selectedFile;

    const {unwrappedCaseKey} = useContext(CaseKeyContext);

    const onLeftClick = (index: number) => {
        const previousIndex = index === 0 ? allFiles.length - 1 : index - 1;
        const previousFile = allFiles.find(item => item.index === previousIndex);
        if (previousFile && getModalContent) {
            getModalContent(previousFile);
        }
    };

    const onRightClick = (index: number) => {
        const nextIndex = index === allFiles.length - 1 ? 0 : index + 1;
        const nextFile = allFiles.find(item => item.index === nextIndex);
        if (nextFile && getModalContent) {
            getModalContent(nextFile);
        }
    };

    const {
        profile,
        requestCase: {isLoading},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const profileLoading = get(profile, 'isLoading', false);

    const [thumbLinks, setThumbLinks] = useState<(string | null | undefined)[]>([]);

    const downloadEncryptedFile = async (url: string) => {
        if (encryptPrefix) {
            const decryptedFileSource = await getEncryptedFileData(url, encryptPrefix, unwrappedCaseKey);
            if (decryptedFileSource) {
                downloadFile(decryptedFileSource, `${title}.${extension}`);
            }
        }
    };

    useEffect(() => {
        Promise.all(
            allFiles.map(file => {
                const thumbLink = file.thumbLink || file.thumb;
                console.log('thumbLink', thumbLink);
                if (thumbLink) return unwrappedCaseKey ? downloadEncryptedThumb(thumbLink) : thumbLink;
                return '';
            }),
        ).then(links => {
            setThumbLinks(links);
        });
    }, [allFiles.length, allFiles?.[0].uuid]);

    const downloadEncryptedThumb = async (url: string) => {
        return await getEncryptedFileData(url, 'data:image/jpeg;base64,', unwrappedCaseKey);
    };

    const handleDownload = async () => {
        if (!fileLink) return;
        if (encryptPrefix) {
            await downloadEncryptedFile(fileLink);
        } else {
            downloadFile(fileLink);
        }
    };

    useEffect(() => {
        const navigateWithArrows = (keyEvent: KeyboardEvent) => {
            if (keyEvent && keyEvent.key === 'ArrowLeft') {
                onLeftClick(index);
            }

            if (keyEvent && keyEvent.key === 'ArrowRight') {
                onRightClick(index);
            }
        };

        window.addEventListener('keydown', navigateWithArrows);
        return () => {
            window.removeEventListener('keydown', navigateWithArrows);
        };
    }, [index]);

    const {isMobile} = useContext(MediaContext);

    const Gallery = isMobile ? MobileGallery : DesktopGallery;

    return (
        <GalleryWrapper
            isShowModal={isModalOpened}
            toggleModal={toggleIsModalOpened}
            title={
                profileLoading || isLoading
                    ? t('common.pleaseWait')
                    : allFiles.length > 1
                    ? `${index + 1}/${allFiles.length}  ${documentFormField || ''} | ${title}`
                    : title
            }
            handleDownload={documentImageSource ? handleDownload : undefined}
        >
            <Gallery
                allFiles={allFiles}
                thumbLinks={thumbLinks}
                selectedFile={selectedFile}
                getModalContent={getModalContent}
            >
                {!documentImageSource && <CircularProgress />}
                {!profileLoading && !isLoading && documentImageSource && (
                    <ImagePreview
                        documentIsImage={documentIsImage}
                        documentIsPdf={documentIsPdf}
                        documentImageSource={documentImageSource}
                        styles={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                        }}
                    />
                )}
                {allFiles.length > 1 && (
                    <>
                        <IconButton
                            onClick={() => onLeftClick(index)}
                            sx={{
                                position: 'absolute',
                                left: 0,
                                top: 0,
                                bottom: 0,
                                width: 'min(100px, 20%)',
                                borderRadius: 0,
                                opacity: 1,
                                backgroundColor: 'none',
                                '&:hover': {
                                    backgroundColor: `${theme.palette.info.contrastText}40`,
                                },
                            }}
                        >
                            <KeyboardArrowLeft sx={{color: theme.palette.info.main}} fontSize="large" />
                        </IconButton>
                        <IconButton
                            onClick={() => onRightClick(index)}
                            sx={{
                                position: 'absolute',
                                right: 0,
                                top: 0,
                                bottom: 0,
                                width: 'min(100px, 20%)',
                                borderRadius: 0,
                                opacity: 1,
                                backgroundColor: 'none',
                                '&:hover': {
                                    backgroundColor: `${theme.palette.info.contrastText}40`,
                                },
                            }}
                        >
                            <KeyboardArrowRight sx={{color: theme.palette.info.main}} fontSize="large" />
                        </IconButton>
                    </>
                )}
            </Gallery>
        </GalleryWrapper>
    );
};

export default FilesGallery;
