import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useDispatch, useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {RootReducer} from 'appRedux/reducers';
import {FormConfigurationRequestTypes, FormMainTranslationsUpdateRequestTypes} from 'appRedux/actions/forms/types';
import {FORM_TRANSLATIONS, FORM_MAIN_TRANSLATION_UPDATE} from 'appRedux/actions/forms';

import {AlertContext} from 'contexts/alert/context';

import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import {
    formTranslationsValidationSchema,
    subFormTranslationsValidationSchema,
} from 'components/Forms/FormBuilder/FormConfigurationTranslationForm/validation';
import MdxEditorField from 'components/MdxEditorField/MdxEditorField';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

import {getFormKeyword, getFormTranslatedLabel} from 'helpers/translationsHelper';

interface FormConfigurationTranslationFormType {
    formId: number;
    initialValues: FormConfigurationRequestTypes;
    currentTab: string;
}

const FormConfigurationTranslationForm: FC<FormConfigurationTranslationFormType> = ({
    formId,
    initialValues,
    currentTab,
}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const getFormTranslations = useCallback(
        data => dispatch({type: FORM_TRANSLATIONS.REQUEST, payload: data}),
        [dispatch],
    );

    const updateFormTranslations = useCallback(
        data => dispatch({type: FORM_MAIN_TRANSLATION_UPDATE.REQUEST, payload: data}),
        [dispatch],
    );

    const {title, description, privacyPolice, pauseReason, formButtonText, isSubForm} = initialValues;

    const {
        admin: {
            isLoading,
            formInfo: {translations},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    useEffect(() => {
        getFormTranslations({id: formId, lang: currentTab});
    }, [currentTab]);

    return (
        <Box>
            <Typography sx={{fontWeight: 600, mb: 2}}>{`${t(
                'orguser.forms.translateForm',
            )}: ${currentTab}`}</Typography>
            <Formik
                initialValues={{
                    id: formId,
                    lang: currentTab,
                    title: getFormTranslatedLabel(translations, getFormKeyword(formId, 'title'), title),
                    description: getFormTranslatedLabel(
                        translations,
                        getFormKeyword(formId, 'description'),
                        description ? description : '',
                    ),
                    privacyPolice: isSubForm
                        ? ''
                        : getFormTranslatedLabel(
                              translations,
                              getFormKeyword(formId, 'privacyPolice'),
                              privacyPolice ? privacyPolice : '',
                          ),
                    pauseReason: isSubForm
                        ? ''
                        : getFormTranslatedLabel(
                              translations,
                              getFormKeyword(formId, 'pauseReason'),
                              pauseReason ? pauseReason : '',
                          ),
                    button: isSubForm
                        ? ''
                        : getFormTranslatedLabel(
                              translations,
                              getFormKeyword(formId, 'button'),
                              formButtonText ? formButtonText : '',
                          ),
                }}
                enableReinitialize
                validationSchema={isSubForm ? subFormTranslationsValidationSchema : formTranslationsValidationSchema}
                onSubmit={(values, {setErrors}) => {
                    updateFormTranslations({
                        ...values,
                        showAlert,
                        setErrors,
                    });
                }}
            >
                {(formik: FormikProps<FormMainTranslationsUpdateRequestTypes>) => {
                    return (
                        <Form>
                            <Box>
                                <Field
                                    required
                                    name="title"
                                    placeholder={t('orguser.forms.title')}
                                    component={FormikTextInput}
                                />
                            </Box>
                            <Box>
                                <MdxEditorField
                                    name="description"
                                    value={formik.values.description ? String(formik.values.description) : ''}
                                    label={t('orguser.forms.description')}
                                />
                            </Box>
                            {!isSubForm && (
                                <>
                                    <Box>
                                        <MdxEditorField
                                            name="privacyPolice"
                                            value={
                                                formik.values.privacyPolice ? String(formik.values.privacyPolice) : ''
                                            }
                                            label={t('orguser.forms.privacyPolicy')}
                                        />
                                    </Box>
                                    <Box>
                                        <MdxEditorField
                                            name="pauseReason"
                                            value={formik.values.pauseReason ? String(formik.values.pauseReason) : ''}
                                            label={t('orguser.forms.pauseReason')}
                                        />
                                    </Box>
                                    <Box>
                                        <Field
                                            required
                                            name="button"
                                            placeholder={t('orguser.forms.requestHelp')}
                                            component={FormikTextInput}
                                        />
                                    </Box>
                                </>
                            )}
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    pt: 3,
                                }}
                            >
                                <AgentSaveButton isSubmit isLoading={isLoading} />
                            </Box>
                        </Form>
                    );
                }}
            </Formik>
        </Box>
    );
};

export default FormConfigurationTranslationForm;
