import React, {FC, useState, MouseEvent} from 'react';

import TranslateIcon from '@mui/icons-material/Translate';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import {FormHasLanguageTypes} from 'appRedux/actions/forms/types';

import {DEFAULT_LANGUAGE} from 'config/index';
import {theme} from 'config/theme';

interface LanguageDropdownMobileSwitcherType {
    availableLanguages: FormHasLanguageTypes[];
    onLanguageChange: (value: string) => void;
}

const LanguageDropdownMobileSwitcher: FC<LanguageDropdownMobileSwitcherType> = ({
    availableLanguages,
    onLanguageChange,
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onChange = (value: string) => {
        onLanguageChange(value);
        handleClose();
    };

    return (
        <>
            <IconButton
                sx={{
                    backgroundColor: theme.palette.info.main,
                    '&:hover': {
                        backgroundColor: theme.palette.info.main,
                    },
                }}
                onClick={handleClick}
            >
                <TranslateIcon sx={{color: theme.palette.background.default}} />
            </IconButton>
            {open && (
                <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                    <MenuItem
                        key={`language-${DEFAULT_LANGUAGE}`}
                        value={DEFAULT_LANGUAGE}
                        onClick={() => onChange(DEFAULT_LANGUAGE)}
                    >
                        <Typography>{DEFAULT_LANGUAGE}</Typography>
                    </MenuItem>
                    {availableLanguages &&
                        availableLanguages.map((language: FormHasLanguageTypes) => {
                            const {
                                language: {name},
                            } = language;
                            return (
                                <MenuItem key={`language-${name}`} value={name} onClick={() => onChange(name)}>
                                    <Typography>{name}</Typography>
                                </MenuItem>
                            );
                        })}
                </Menu>
            )}
        </>
    );
};

export default LanguageDropdownMobileSwitcher;
