import React, {FC, useContext} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import {PanelItemsTypes} from 'appRedux/actions/analytics/types';

import {PermissionContext} from 'contexts/permission/context';

import EmptyArea from 'components/EmptyArea';
import {
    getPanelType,
    getPanelDatefilterType,
    getPanelDisplayType,
    DISPLAY_TYPE_BAR_CHART,
    TYPE_CASE_CHECKBOX_ANALYSIS,
} from 'components/Forms/Dashboard/helper';
import MarkdownWithShowAll from 'components/TextWithShowAll/MarkdownWithShowAll';

import DeletePanelButton from 'pages/admin/analytics/panels/DeletePanelButton';
import EditPanelButton from 'pages/admin/analytics/panels/EditPanelButton';
import PanelTileItem from 'pages/admin/analytics/panels/PanelTileItem';
import {getFormFieldsList} from 'pages/admin/analytics/panels/helper';
import BarStatisticChart from 'pages/admin/analytics/charts/BarStatisticChart';
import CircleStatisticChart from 'pages/admin/analytics/charts/CircleStatisticChart';

import {theme} from 'config/theme';

interface PanelTileType {
    item: PanelItemsTypes;
}

const PanelTile: FC<PanelTileType> = ({item}) => {
    const [t] = useTranslation();

    const {isDashboardEditEnabled} = useContext(PermissionContext);

    const {workflowTitle, formTitle, title, datefilterType, description, displayType, type, fields} = item;

    return (
        <Box
            sx={{backgroundColor: theme.palette.background.default, width: '100%', padding: 2, borderRadius: 4, mb: 2}}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: isDashboardEditEnabled ? 'space-between' : 'flex-start',
                    alignItems: 'center',
                    width: '100%',
                    mb: 2,
                }}
            >
                <Typography sx={{fontWeight: 700}}>{title}</Typography>
                {isDashboardEditEnabled && (
                    <>
                        <EmptyArea />
                        <EditPanelButton item={item} />
                        <DeletePanelButton item={item} />
                    </>
                )}
            </Box>
            <Grid container>
                <Grid item sm={12} md={7} sx={{pr: 2}}>
                    {displayType === DISPLAY_TYPE_BAR_CHART ? (
                        <BarStatisticChart item={item} />
                    ) : (
                        <CircleStatisticChart item={item} />
                    )}
                </Grid>
                <Grid item sm={12} md={5}>
                    {workflowTitle && (
                        <PanelTileItem label={t('orguser.analytics.panels.workflow')} text={workflowTitle} />
                    )}
                    <PanelTileItem
                        label={t('orguser.analytics.panels.formTitle')}
                        text={formTitle ? formTitle : t('common.all')}
                    />
                    {type === TYPE_CASE_CHECKBOX_ANALYSIS && fields.length > 0 && (
                        <PanelTileItem
                            label={t('orguser.analytics.panels.formFields')}
                            text={getFormFieldsList(fields)}
                        />
                    )}
                    <PanelTileItem label={t('orguser.analytics.panels.type')} text={t(getPanelType(type))} />
                    <PanelTileItem
                        label={t('orguser.analytics.panels.datefilterType')}
                        text={t(getPanelDatefilterType(datefilterType))}
                    />
                    <PanelTileItem
                        label={t('orguser.analytics.panels.displayType')}
                        text={t(getPanelDisplayType(displayType))}
                    />
                    <Box
                        sx={{
                            mt: 2,
                        }}
                    >
                        <MarkdownWithShowAll text={description} />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default PanelTile;
