import React, {ReactNode, useCallback, useContext, useState} from 'react';
import {useLocation, useSearchParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {LOGOUT} from 'appRedux/actions/auth';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';
import {BreadcrumbsType, RouteContext, RouteContextType} from 'contexts/route/context';

import {ADMIN_DASHBOARD, ROLES_LIST} from 'components/AgentScreenComponents/helper';

import {
    getRefreshToken,
    LOCAL_STORAGE_LOGOUT_TIME,
    LOCAL_STORAGE_PERSIST_ROOT,
    LOCAL_STORAGE_REFRESH_TOKEN,
    LOCAL_STORAGE_TOKEN,
    LOCAL_STORAGE_NICKNAME_POPUP_STATUS,
} from 'services/localStorage';
import {ERROR_FETCH} from 'services/http';

import {isSuperAdminUser, isOrgAdminUser, isAgentUser} from 'helpers/roles';

import {routes, PARAMETER_TAB, DEFAULT_PAGE} from 'config/index';

interface ContextType {
    children: ReactNode;
}

const RouteContextWrapper: React.FC<ContextType> = ({children}) => {
    const dispatch = useDispatch();
    const {pathname, search} = useLocation();
    const [searchParams] = useSearchParams();

    const {showAlert} = useContext(AlertContext);

    const {
        errors: {generalError},
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const [currentTab, setCurrentTab] = useState<string>(searchParams.get(PARAMETER_TAB) || ADMIN_DASHBOARD);
    const [formPage, setFormPage] = useState<number>(DEFAULT_PAGE);
    const [formSection, setFormSection] = useState<number | null>(null);
    const [pageTitle, setPageTitle] = useState<string>('');
    const [pageBreadcrumbs, setPageBreadcrumbs] = useState<BreadcrumbsType[]>([]);
    const [topBarTitle, setTopBarTitle] = useState<string>('');
    const [topBarSubtitle, setTopBarSubtitle] = useState<string>('');
    const [backLink, setBackLink] = useState<string | null>(null);
    const [addLink, setAddLink] = useState<string | null>(null);
    const [backTab, setBackTab] = useState<string | null>(null);
    const [backLinkLabel, setBackLinkLabel] = useState<string | null>(null);
    const [customBoardTitle, setCustomBoardTitle] = useState<string | null>(null);
    const [isClientMode, setIsClientMode] = useState<boolean>(false);

    const isImprintPage = pathname.includes(routes.RULE_ROUTE_IMPRINT);
    const isPrivacyPolicyPage = pathname.includes(routes.RULE_ROUTE_PRIVACY_POLICY);

    const isSuperAdminPage = profile ? isSuperAdminUser(profile) : false;
    const isSuperAdminDashboard = pathname.replace(/\/+$/, '') === routes.SUPER_ADMIN;
    const isOrganizationCreatePage = pathname === routes.ADD_ORGANIZATION;
    const isOrganizationUpdatePage = pathname.includes(routes.UPDATE_ORGANIZATION) && !pathname.includes('roles');

    const isRolesListPage = pathname.includes(ROLES_LIST);
    const isCreateRolePage = pathname.includes(routes.ROLE) && pathname.includes('create');
    const isUpdateRolePage = pathname.includes(routes.ROLE) && pathname.includes('edit');

    const isAdminPage = profile ? isOrgAdminUser(profile) : false;
    const isAdminDashboard = pathname.replace(/\/+$/, '') === routes.ADMIN;
    const isFormCreatePage = pathname.includes(routes.FORM) && pathname.includes('create');
    const isFormUpdatePage = pathname.includes(routes.FORM) && pathname.includes('edit');
    const isFormVersionPage = pathname.includes(routes.FORM) && pathname.includes('version');
    const isFormViewPage = pathname.includes(routes.FORM) && pathname.includes('view');

    const isWorkflowEditPage = pathname.includes(routes.WORKFLOW) && pathname.includes('edit');
    const isWorkflowStatusEventsPage = pathname.includes(routes.WORKFLOW) && pathname.includes('events');

    const isCaseAccessRequestUpdatePage = pathname.includes(routes.CASE_ACCESS_REQUEST) && pathname.includes('edit');

    const isDashboardWithPanelsPage = pathname.includes(routes.DASHBOARD) && pathname.includes('panels');

    const isUpdateResourceFieldPage = pathname.includes(routes.RESOURCE_FIELD) && pathname.includes('edit');
    const isUpdateResourceFieldCalendarPage =
        pathname.includes(routes.RESOURCE_FIELD) && pathname.includes('/calendar') && pathname.includes('edit');

    const isAgentPage = profile ? isAgentUser(profile) : false;
    const isAgentDashboard = pathname.replace(/\/+$/, '') === routes.AGENT && !search.includes(`?${PARAMETER_TAB}=`);
    const isAgentChatPage = pathname.includes('chat');
    const isAgentSavedFiltersPage = pathname.includes('filters');

    const isBoardCasesPage = pathname.includes(routes.BOARD) && pathname.includes('/cases');
    const isBoardOverviewCasesPage = pathname.includes(routes.BOARD_OVERVIEW) && pathname.includes('/cases');
    const isBoardListCasesPage = pathname.includes(routes.LIST) && pathname.includes('/cases');

    const isRequesterCaseInfoPage = pathname.includes(routes.REQUESTER_CASE) && pathname.includes('form');
    const isRequesterCaseChatPage = pathname.includes(routes.REQUESTER_CASE) && pathname.includes('chat');
    const isRequesterCasePrintPage = pathname.includes(routes.REQUESTER_CASE) && pathname.includes('print');

    const isRequesterFormPage = pathname.includes(routes.REQUEST) && pathname.includes('form');
    const isRequesterChatPage = pathname.includes(routes.REQUESTER_CASE) && pathname.includes('chat');
    const isRequesterCaseActivityPage = pathname.includes(routes.REQUESTER_CASE) && pathname.includes('activity');

    const isRequesterPage = pathname.includes(routes.REQUESTER_CASE) && pathname.includes('form');

    const isEmailUpdatePage = pathname.includes(routes.EMAIL_UPDATE);

    const isAccountSettingsPage =
        [routes.SUPER_ADMIN_ACCOUNT, routes.AGENT_ACCOUNT, routes.ADMIN_ACCOUNT, routes.ACCOUNT].indexOf(pathname) !==
        -1;
    const isSecurityPage =
        [routes.SUPER_ADMIN_SECURITY, routes.AGENT_SECURITY, routes.ADMIN_SECURITY, routes.SECURITY].indexOf(
            pathname,
        ) !== -1;

    const isFormsListForRequester = pathname.includes(routes.FORMS_LIST_REQUESTER);

    const logout = useCallback(data => dispatch({type: LOGOUT.REQUEST, payload: data}), [dispatch]);

    const removeTokens = () => {
        localStorage.removeItem(LOCAL_STORAGE_TOKEN);
        localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN);
        localStorage.removeItem(LOCAL_STORAGE_PERSIST_ROOT);
        localStorage.removeItem(LOCAL_STORAGE_LOGOUT_TIME);
        localStorage.removeItem(LOCAL_STORAGE_NICKNAME_POPUP_STATUS);
    };

    const removeTokensAndRedirect = () => {
        removeTokens();
        window.location.replace(routes.START);
    };

    const logoutCallback = async () => {
        const logout = setTimeout(() => {
            removeTokensAndRedirect();
        }, 500);
        return () => clearTimeout(logout);
    };

    const onLogoutClicked = async (isSessionExpired?: boolean) => {
        const refreshToken = await getRefreshToken();

        if (isSessionExpired || String(generalError).includes(ERROR_FETCH) || !refreshToken) {
            logoutCallback();
        } else {
            logout({
                showAlert,
                refreshToken,
                callback: logoutCallback,
            });
        }
    };

    const refreshPageInformation = () => {
        setPageTitle('');
        setTopBarTitle('');
        setTopBarSubtitle('');
        setBackLink('');
        setBackTab('');
    };

    const context: RouteContextType = {
        currentTab,
        setCurrentTab,
        formPage,
        setFormPage,
        formSection,
        setFormSection,
        pageTitle,
        setPageTitle,
        pageBreadcrumbs,
        setPageBreadcrumbs,
        topBarTitle,
        setTopBarTitle,
        topBarSubtitle,
        setTopBarSubtitle,
        addLink,
        setAddLink,
        backLink,
        setBackLink,
        backTab,
        setBackTab,
        backLinkLabel,
        setBackLinkLabel,
        customBoardTitle,
        setCustomBoardTitle,
        isSuperAdminPage,
        isOrganizationCreatePage,
        isOrganizationUpdatePage,
        isAdminPage,
        isAgentPage,
        isAgentChatPage,
        isSuperAdminDashboard,
        isAdminDashboard,
        isAgentDashboard,
        isAgentSavedFiltersPage,
        isClientMode,
        setIsClientMode,
        isFormCreatePage,
        isFormUpdatePage,
        isFormVersionPage,
        isFormViewPage,
        isWorkflowEditPage,
        isWorkflowStatusEventsPage,
        isCaseAccessRequestUpdatePage,
        isRequesterCaseInfoPage,
        isRequesterCaseChatPage,
        isRequesterCasePrintPage,
        isRequesterFormPage,
        isRequesterChatPage,
        isAccountSettingsPage,
        isSecurityPage,
        isRequesterPage,
        isImprintPage,
        isPrivacyPolicyPage,
        isRequesterCaseActivityPage,
        isRolesListPage,
        isCreateRolePage,
        isUpdateRolePage,
        isBoardCasesPage,
        isBoardOverviewCasesPage,
        isBoardListCasesPage,
        isDashboardWithPanelsPage,
        isFormsListForRequester,
        isUpdateResourceFieldPage,
        isUpdateResourceFieldCalendarPage,
        isEmailUpdatePage,
        refreshPageInformation,
        onLogoutClicked,
    };

    return <RouteContext.Provider value={context}>{children}</RouteContext.Provider>;
};

export default RouteContextWrapper;
