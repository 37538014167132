import React, {FC, ReactNode, useContext, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import Box from '@mui/material/Box';

import {RootReducer} from 'appRedux/reducers';

import {MediaContext} from 'contexts/media/context';
import {RouteContext} from 'contexts/route/context';
import PermissionContextWrapper from 'contexts/permission';

import ClientFormWrapper from 'components/ClientScreenComponents/ClientScreenWrapper/ClientFormWrapper';
import ClientFormMobileWrapper from 'components/ClientScreenComponents/_mobile/ClientFormMobileWrapper';
import {isGeneralInfoPresented} from 'components/ClientScreenComponents/ClientScreenWrapper/helper';
import GeneralErrorMessage from 'components/GeneralErrorMessage/GeneralErrorMessage';
import UpdateUsernamePopup from 'components/UpdateUsernamePopup/UpdateUsernamePopup';

import {routes} from 'config/index';

interface ClientScreenWrapperType {
    children: ReactNode;
    chat?: boolean;
}

const ClientScreenWrapper: FC<ClientScreenWrapperType> = ({children, chat}) => {
    const navigate = useNavigate();

    const {isMobile} = useContext(MediaContext);
    const {setIsClientMode} = useContext(RouteContext);

    const {
        profile: {profile},
        errors: {generalError},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const ComponentWrapper = isMobile ? ClientFormMobileWrapper : ClientFormWrapper;

    useEffect(() => {
        if (profile && !isGeneralInfoPresented(profile)) {
            navigate(routes.GENERAL);
        }
    }, [profile]);

    useEffect(() => {
        setIsClientMode(true);
    }, []);

    const getCurrentModal = () => {
        if (profile && !profile.nickname) {
            return <UpdateUsernamePopup isAnonymousAccount={profile.isAnonymousAccount} />;
        }
        if (generalError) {
            return <GeneralErrorMessage />;
        }
        return null;
    };

    return (
        <PermissionContextWrapper>
            <ComponentWrapper chat={chat}>
                {getCurrentModal()}
                {!generalError && <Box sx={{pt: chat ? 0 : 3, width: '100%'}}>{children}</Box>}
            </ComponentWrapper>
        </PermissionContextWrapper>
    );
};

export default ClientScreenWrapper;
