import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';

import EditSvgIcon from 'assets/icons/buttons/EditSvgIcon';

import {WorkflowItemTypes, WorkflowStatusItemTypes} from 'appRedux/actions/workflow/types';
import {LanguageItemTypes} from 'appRedux/actions/admin/types';

import DeleteWorkflowButton from 'pages/admin/workflows/DeleteWorkflowButton';
import {getTranslatedWorkflowStatus} from 'pages/admin/workflows/helper';

import {routes, PARAMETER_LANGUAGE} from 'config/index';

interface WorkflowItemRow {
    item: WorkflowItemTypes;
    currentUserLanguage: string;
    currentOrganizationLanguage?: LanguageItemTypes;
}

const WorkflowItemRow: FC<WorkflowItemRow> = ({item, currentUserLanguage, currentOrganizationLanguage}) => {
    const [t] = useTranslation();
    const navigate = useNavigate();

    const {title, workflowStatuses, forms, uuid} = item;

    const redirectToWorkflow = () => {
        if (currentOrganizationLanguage && currentOrganizationLanguage.name === currentUserLanguage) {
            navigate(`${routes.WORKFLOW}/${uuid}/edit`);
        } else {
            navigate(`${routes.WORKFLOW}/${uuid}/edit?${PARAMETER_LANGUAGE}=${currentUserLanguage}`);
        }
    };

    return (
        <TableRow>
            <TableCell>
                <Typography>{title}</Typography>
            </TableCell>
            <TableCell>
                {workflowStatuses &&
                    workflowStatuses.map((workflowStatus: WorkflowStatusItemTypes, index: number) => {
                        return (
                            <Typography key={`status-${uuid}-type-${index}`} sx={{mb: 0.5}}>
                                <span style={{fontWeight: 600, marginRight: 5}}>
                                    {getTranslatedWorkflowStatus(workflowStatus, currentUserLanguage)}
                                </span>
                                {`(${t(String(workflowStatus.statusType))})`}
                            </Typography>
                        );
                    })}
            </TableCell>
            <TableCell>
                {forms.length > 0 ? (
                    forms.map((form: string, index: number) => {
                        return (
                            <Typography key={`form-${index}`} sx={{mb: 0.5}}>
                                {form}
                            </Typography>
                        );
                    })
                ) : (
                    <Typography sx={{mb: 0.5}}>{t('common.none')}</Typography>
                )}
            </TableCell>
            <TableCell>
                <IconButton title={t('common.buttons.update')} onClick={redirectToWorkflow}>
                    <EditSvgIcon />
                </IconButton>
                <DeleteWorkflowButton item={item} />
            </TableCell>
        </TableRow>
    );
};

export default WorkflowItemRow;
