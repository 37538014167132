import React, {FC, useCallback, useContext, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import Grid from '@mui/material/Grid';

import {GET_REQUESTER_CASES_STATISTIC} from 'appRedux/actions/requestCase';

import {RouteContext} from 'contexts/route/context';
import {PermissionContext} from 'contexts/permission/context';

import RequesterCaseStatistic from 'components/RequesterCaseStatistic/RequesterCaseStatistic';

import PinnedCasesList from 'pages/agent/dashboard/PinnedCasesList';
import CasesWaitingApproveList from 'pages/agent/dashboard/CasesWaitingApproveList';
import ActivitiesLog from 'pages/agent/dashboard/ActivitiesLog';

import {DEFAULT_AGENT_FILTER_DATE_FROM, DEFAULT_FILTER_DATE_TO} from 'config/index';

const AgentDashboard: FC = () => {
    const dispatch = useDispatch();

    const {setPageTitle, setTopBarTitle, setBackLink, setBackTab, setBackLinkLabel} = useContext(RouteContext);
    const {isCaseViewEnabled} = useContext(PermissionContext);

    const [isPinnedListUpdated, setIsPinnedListUpdated] = useState<boolean>(false);
    const [isApproveWaitingListUpdated, setIsApproveWaitingListUpdated] = useState<boolean>(false);

    const togglePinnedListUpdated = () => {
        setIsPinnedListUpdated(previous => !previous);
    };

    const toggleApproveListUpdated = () => {
        setIsApproveWaitingListUpdated(previous => !previous);
    };

    const getRequesterCasesStatistic = useCallback(
        data => dispatch({type: GET_REQUESTER_CASES_STATISTIC.REQUEST, payload: data}),
        [dispatch],
    );

    useEffect(() => {
        if (isCaseViewEnabled) {
            getRequesterCasesStatistic({
                dateFrom: DEFAULT_AGENT_FILTER_DATE_FROM.getTime(),
                dateTo: DEFAULT_FILTER_DATE_TO.getTime(),
            });
        }
    }, [isCaseViewEnabled]);

    useEffect(() => {
        setPageTitle('');
        setTopBarTitle('');
        setBackLink('');
        setBackTab('');
        setBackLinkLabel('');
    }, []);

    if (!isCaseViewEnabled) {
        return null;
    }
    return (
        <Grid container spacing={2}>
            <Grid item sm={12} md={4}>
                <PinnedCasesList
                    isApproveWaitingListUpdated={isApproveWaitingListUpdated}
                    toggleApproveListUpdated={toggleApproveListUpdated}
                />
                <CasesWaitingApproveList
                    isPinnedListUpdated={isPinnedListUpdated}
                    togglePinnedListUpdated={togglePinnedListUpdated}
                />
            </Grid>
            <Grid item sm={12} md={8}>
                <RequesterCaseStatistic />
                <ActivitiesLog />
            </Grid>
        </Grid>
    );
};

export default AgentDashboard;
