import React, {FC, useMemo} from 'react';
import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import {RootReducer} from 'appRedux/reducers';

import FiltersBlock from 'components/AdminScreenComponents/FiltersBlock';
import {TableFilterTypes} from 'components/AdminScreenComponents/types';

interface ChartsType {
    filters: TableFilterTypes[];
}

const Charts: FC<ChartsType> = ({filters}) => {
    const [t] = useTranslation();
    const {
        requestCase: {requesterCasesStatistic},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const requesterCaseTranslated = useMemo(
        () =>
            requesterCasesStatistic.map(cases => {
                cases.name = t(cases.name);
                return cases;
            }),
        [requesterCasesStatistic],
    );

    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 2,
                }}
            >
                <Grid container>
                    <Grid item sm={9}>
                        <FiltersBlock filters={filters} />
                    </Grid>
                </Grid>
            </Box>
            <ResponsiveContainer width="100%" height={250}>
                <BarChart width={730} height={250} data={requesterCaseTranslated}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis allowDecimals={false} />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="quantity" fill="#8884d8" />
                </BarChart>
            </ResponsiveContainer>
        </Box>
    );
};

export default Charts;
