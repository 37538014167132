import React, {FC, useRef, useState} from 'react';
import {usePdf} from '@mikecousins/react-pdf';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

import {theme} from 'config/theme';

interface PdfPreviewType {
    fileSource: string;
}

const PdfPreview: FC<PdfPreviewType> = ({fileSource}) => {
    const [page, setPage] = useState(1);

    const containerRef = useRef<HTMLDivElement | null>(null);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);

    const {pdfDocument} = usePdf({
        file: fileSource,
        page,
        scale: 1,
        canvasRef,
        workerSrc: '/js/pdf.worker.min.js',
        onPageRenderSuccess: () => {
            if (canvasRef && canvasRef.current && canvasRef.current.style) {
                const heightScale = canvasRef.current.clientHeight / (containerRef.current?.clientHeight || 1);
                const widthScale = canvasRef.current.clientWidth / (containerRef.current?.clientWidth || 1);

                if (
                    heightScale >= widthScale &&
                    canvasRef.current.clientHeight > (containerRef.current?.clientHeight || 1)
                ) {
                    const height = `${containerRef.current?.clientHeight}px` || 'auto';
                    canvasRef.current.style.width = 'auto';
                    canvasRef.current.style.height = height;
                }

                if (
                    heightScale < widthScale &&
                    canvasRef.current.clientWidth > (containerRef.current?.clientWidth || 1)
                ) {
                    const width = `${containerRef.current?.clientWidth}px` || 'auto';
                    canvasRef.current.style.width = width;
                    canvasRef.current.style.height = 'auto';
                }
            }
        },
    });

    if (!pdfDocument) {
        return <CircularProgress />;
    }
    return (
        <Box sx={{position: 'relative', height: '100%', width: '100%'}} ref={containerRef}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                    width: '100%',
                    overflow: 'auto',
                }}
                ref={containerRef}
            >
                <canvas ref={canvasRef} style={{}} />
            </Box>
            {pdfDocument && pdfDocument.numPages && (
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: 15,
                        pl: 1,
                        pr: 1,
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        gap: 5,
                        alignItems: 'center',
                    }}
                >
                    <Button
                        disabled={page === 1}
                        size={'medium'}
                        onClick={() => {
                            setPage(page - 1);
                        }}
                        sx={{
                            minWidth: 25,
                            opacity: 0.5,
                            backgroundColor: theme.palette.info.main,
                            '&:hover': {
                                opacity: 1,
                                backgroundColor: theme.palette.info.main,
                            },
                        }}
                    >
                        <KeyboardArrowLeft fontSize="small" sx={{color: theme.palette.background.default}} />
                    </Button>
                    <Button
                        disabled={page === pdfDocument.numPages}
                        size={'medium'}
                        onClick={() => {
                            setPage(page + 1);
                        }}
                        sx={{
                            minWidth: 25,
                            opacity: 0.5,
                            backgroundColor: theme.palette.info.main,
                            '&:hover': {
                                opacity: 1,
                                backgroundColor: theme.palette.info.main,
                            },
                        }}
                    >
                        <KeyboardArrowRight fontSize="small" sx={{color: theme.palette.background.default}} />
                    </Button>
                </Box>
            )}
        </Box>
    );
};

export default PdfPreview;
