import React, {FC, useState} from 'react';
import {Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import RedirectSvgIcon from 'assets/icons/buttons/RedirectSvgIcon';

import {FormFieldMoveRequestFormType, FormPageTypes, FormSectionTypes} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

import {theme} from 'config/theme';

import {preventSendForm} from 'helpers/index';

interface MoveFormType {
    pageId: number;
    currentSectionId: number;
    onSubmitClick: (values: FormFieldMoveRequestFormType) => void;
}

const MoveForm: FC<MoveFormType> = ({pageId, currentSectionId, onSubmitClick}) => {
    const [t] = useTranslation();

    const [sections, setSections] = useState<FormSectionTypes[]>([]);

    const {
        admin: {
            formInfo: {pages},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const onPageSelected = (event: SelectChangeEvent) => {
        const pageId = Number(event.target.value);
        const page = pages.find(page => page.id === pageId);
        if (page) {
            setSections(page.sections);
        }
    };

    return (
        <Formik
            initialValues={{
                pageId: pageId,
                sectionId: currentSectionId,
            }}
            onSubmit={onSubmitClick}
        >
            {(formik: FormikProps<FormFieldMoveRequestFormType>) => {
                return (
                    <Form onKeyDown={preventSendForm}>
                        <Box>
                            <Typography variant="body2" sx={{mb: 1}}>
                                {t('orguser.forms.formField.selectPage')}
                            </Typography>
                            <FormControl
                                sx={{
                                    backgroundColor: theme.palette.warning.contrastText,
                                    borderRadius: 3,
                                    pl: 3,
                                    pr: 3,
                                    pb: 2,
                                    mb: 2,
                                }}
                                required
                                fullWidth
                            >
                                <Select
                                    sx={{
                                        height: 50,
                                    }}
                                    name={'pageId'}
                                    value={String(formik.values.pageId)}
                                    variant="standard"
                                    onChange={e => {
                                        onPageSelected(e);
                                        formik.handleChange(e);
                                    }}
                                    required
                                >
                                    {pages.map((page: FormPageTypes) => {
                                        const {title, id} = page;
                                        return (
                                            <MenuItem key={`page-${id}`} value={id}>
                                                <Typography>{title}</Typography>
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box>
                            <Typography variant="body2" sx={{mb: 1}}>
                                {t('orguser.forms.formField.selectSection')}
                            </Typography>
                            <FormControl
                                sx={{
                                    backgroundColor: theme.palette.warning.contrastText,
                                    borderRadius: 3,
                                    pl: 3,
                                    pr: 3,
                                    pb: 2,
                                    mb: 2,
                                }}
                                required
                                fullWidth
                            >
                                <Select
                                    sx={{
                                        height: 50,
                                    }}
                                    name={'sectionId'}
                                    value={formik.values.sectionId}
                                    variant="standard"
                                    onChange={formik.handleChange}
                                    required
                                >
                                    {sections.map((item: FormSectionTypes, index: number) => {
                                        const {title, id} = item;
                                        return (
                                            <MenuItem key={`menuItem-${index}`} value={id}>
                                                <Typography>{title}</Typography>
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', mt: 1}}>
                            <AgentSaveButton
                                isSubmit
                                isDisabled={!formik.values.pageId || !formik.values.sectionId}
                                icon={<RedirectSvgIcon isActive />}
                                title={t('common.buttons.move')}
                            />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default MoveForm;
