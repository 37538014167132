import React, {FC} from 'react';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';

import {WorkflowFormTypes} from 'appRedux/actions/workflow/types';

import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import {validationSchemaCreate} from 'components/Forms/Workflow/validation';

interface WorkflowFormType {
    initialValues: WorkflowFormTypes;
    onSubmitClicked: (values: WorkflowFormTypes) => void;
}

const WorkflowForm: FC<WorkflowFormType> = ({initialValues, onSubmitClicked}) => {
    const [t] = useTranslation();
    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={() => validationSchemaCreate(t)}
            onSubmit={(values, {setErrors}) => {
                onSubmitClicked({
                    ...values,
                    setErrors,
                });
            }}
        >
            {(formik: FormikProps<WorkflowFormTypes>) => {
                return (
                    <Form>
                        <Field
                            required
                            name="title"
                            placeholder={t('orguser.workflows.parameters.title')}
                            component={FormikTextInput}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                mt: 2,
                            }}
                        >
                            <AgentSaveButton isSubmit />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default WorkflowForm;
