import React, {FC, useCallback, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import Countdown from 'react-countdown';
import {useNavigate} from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {RootReducer} from 'appRedux/reducers';
import {SET_ERRORS} from 'appRedux/actions/errors';

import {RouteContext} from 'contexts/route/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import {getErrorDescription} from 'components/GeneralErrorMessage/helper';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';

import {ERROR_404, ERROR_500} from 'services/http';

const COUNTDOWN_TIME = 15;

const GeneralErrorMessage: FC = () => {
    const [t] = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {onLogoutClicked, isRequesterCaseChatPage, isRequesterCasePrintPage, isRequesterCaseInfoPage} =
        useContext(RouteContext);

    const refreshErrors = useCallback(data => dispatch({type: SET_ERRORS.GENERAL_ERROR, payload: data}), [dispatch]);

    const {
        errors: {generalError},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const agentCasePages = [isRequesterCaseChatPage, isRequesterCasePrintPage, isRequesterCaseInfoPage];

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const renderer = ({seconds, completed}) => {
        if (seconds === COUNTDOWN_TIME) {
            return null;
        }
        if (completed) {
            onLogoutClicked();
        }
        return (
            <Typography align="center" sx={{mt: 1, mb: 4}}>
                {t('messages.error.countdownText').replace('%NUMBER%', seconds)}
            </Typography>
        );
    };

    const onBackClicked = () => {
        refreshErrors(null);
        navigate(-1);
    };

    if (String(generalError).includes(ERROR_500)) return null;

    return (
        <ModalWrapper isShowModal={true} title={t('messages.error.error')}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                <Typography align="center" sx={{fontWeight: 600, mb: 2, fontSize: 22}}>
                    {t(getErrorDescription(String(generalError), agentCasePages))}
                </Typography>
                {String(generalError).includes(ERROR_404) ? (
                    <AgentSaveButton
                        isBigButton
                        isSubmit={false}
                        onClick={onBackClicked}
                        title={t('common.buttons.goBack')}
                    />
                ) : (
                    <>
                        <Countdown date={Date.now() + COUNTDOWN_TIME * 1000} renderer={renderer} />
                        <AgentSaveButton
                            isBigButton
                            isSubmit={false}
                            onClick={onLogoutClicked}
                            title={t('common.buttons.logout')}
                        />
                    </>
                )}
            </Box>
        </ModalWrapper>
    );
};

export default GeneralErrorMessage;
