import React, {ChangeEvent, FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Field, Form, Formik, FormikProps} from 'formik';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import {WorkflowStatusFormTypes} from 'appRedux/actions/workflow/types';

import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import {validationSchemaCreate} from 'components/Forms/Workflow/validation';
import TagChip from 'components/TagChip/TagChip';

import {tagColors} from 'config/theme';

interface WorkflowStatusFormInterface {
    initialValues: WorkflowStatusFormTypes;
    onSubmitClicked: (values: WorkflowStatusFormTypes) => void;
    isInitial?: boolean;
    isCreate?: boolean;
}

const WorkflowStatusForm: FC<WorkflowStatusFormInterface> = ({initialValues, onSubmitClicked, isInitial, isCreate}) => {
    const [t] = useTranslation();

    const [currentColor, setCurrentColor] = useState<string>(initialValues.color);
    const [currentColorRequester, setCurrentColorRequester] = useState<string>(initialValues.colorRequester);

    const onColorSelect = (e: ChangeEvent<{value: string}>) => {
        setCurrentColor(e.target.value);
    };

    const onColorRequesterSelect = (e: ChangeEvent<{value: string}>) => {
        setCurrentColorRequester(e.target.value);
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={() => validationSchemaCreate(t)}
            onSubmit={(values, {setErrors}) => {
                onSubmitClicked({
                    ...values,
                    setErrors,
                    color: currentColor,
                    colorRequester: currentColorRequester,
                });
            }}
        >
            {(formik: FormikProps<WorkflowStatusFormTypes>) => {
                return (
                    <Form>
                        <Field
                            required
                            name="title"
                            placeholder={t('orguser.workflows.workflowStatusParameters.title')}
                            component={FormikTextInput}
                        />
                        {isCreate && (
                            <Box sx={{pt: 2, pb: 1}}>
                                <Typography sx={{fontWeight: 700}}>{`${t(
                                    'orguser.workflows.workflowStatusParameters.selectColor',
                                )}:`}</Typography>
                                <RadioGroup
                                    value={currentColor}
                                    name="color"
                                    row
                                    sx={{
                                        p: 1,
                                        mb: 1,
                                    }}
                                >
                                    {tagColors.map((tagColor: string, index: number) => {
                                        return (
                                            <FormControlLabel
                                                key={`color-${index}`}
                                                value={tagColor}
                                                control={
                                                    <Radio
                                                        sx={{mb: 1.5, display: 'none'}}
                                                        checked={currentColor === tagColor}
                                                        onChange={onColorSelect}
                                                    />
                                                }
                                                label={
                                                    <TagChip
                                                        id={String(index)}
                                                        title={tagColor}
                                                        color={tagColor}
                                                        hasOpacity={currentColor === tagColor}
                                                    />
                                                }
                                            />
                                        );
                                    })}
                                </RadioGroup>
                            </Box>
                        )}
                        <Field
                            required
                            name="titleRequester"
                            placeholder={t('orguser.workflows.workflowStatusParameters.titleRequester')}
                            component={FormikTextInput}
                        />
                        {isCreate && (
                            <Box sx={{pt: 2, pb: 1}}>
                                <Typography sx={{fontWeight: 700}}>{`${t(
                                    'orguser.workflows.workflowStatusParameters.selectColorRequester',
                                )}:`}</Typography>
                                <RadioGroup
                                    value={currentColorRequester}
                                    name="colorRequester"
                                    row
                                    sx={{
                                        p: 1,
                                        mb: 1,
                                    }}
                                >
                                    {tagColors.map((tagColor: string, index: number) => {
                                        return (
                                            <FormControlLabel
                                                key={`color-${index}`}
                                                value={tagColor}
                                                control={
                                                    <Radio
                                                        sx={{mb: 1.5, display: 'none'}}
                                                        checked={currentColorRequester === tagColor}
                                                        onChange={onColorRequesterSelect}
                                                    />
                                                }
                                                label={
                                                    <TagChip
                                                        id={String(index)}
                                                        title={tagColor}
                                                        color={tagColor}
                                                        hasOpacity={currentColorRequester === tagColor}
                                                    />
                                                }
                                            />
                                        );
                                    })}
                                </RadioGroup>
                            </Box>
                        )}
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                mt: 3,
                            }}
                        >
                            <AgentSaveButton isSubmit />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default WorkflowStatusForm;
