import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams, useSearchParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {SET_ERRORS} from 'appRedux/actions/errors';
import {RootReducer} from 'appRedux/reducers';

import {BreadcrumbsType, RouteContext} from 'contexts/route/context';

import AgentScreenWrapper from 'components/AgentScreenComponents/_wrappers/AgentScreenWrapper';
import GeneralErrorMessage from 'components/GeneralErrorMessage/GeneralErrorMessage';
import TwoFactorChecking from 'components/TwoFactorChecking/TwoFactorChecking';
import RulesList from 'components/Rules/RulesList';
import {
    ADMIN_DASHBOARD,
    ADMIN_MY_ORGANIZATION,
    SUPER_ADMIN_ORGANIZATIONS,
    SUPER_ADMIN_INSTANCE,
    SUPER_ADMIN_FORMS,
    SUPER_ADMIN_GENERAL_TEMPLATES,
    SUPER_ADMIN_LOGIN_ATTEMPTS,
    SUPER_ADMIN_PASSWORD_RESET_ATTEMPTS,
    SUPER_ADMIN_DRAFT_REMOVES,
    SUPER_ADMIN_IMPRINT,
    SUPER_ADMIN_PRIVACY_POLICY,
    ROLES_LIST,
    ORG_USERS_LIST,
    WORKFLOWS_LIST,
    INVITES_LIST,
    BOARDS_LIST,
    FORMS_LIST,
    PUBLIC_KEYS_LIST,
    ACCESS_REQUESTS_LIST,
    TEMPLATES_LIST,
    PANELS_LIST,
    DASHBOARDS_LIST,
    ORGANIZATION_POLICY,
    getBreadcrumbs,
    UPDATE_WORKFLOW,
    UPDATE_WORKFLOW_STATUS_EVENTS,
    UPDATE_FORM,
    FORM_VERSION,
    SUPER_ADMIN_ORGANIZATION_DETAILS,
    REQUESTER_CASE_FORM,
    REQUESTER_CASE_ACTIVITY,
    RESOURCE_FIELDS,
} from 'components/AgentScreenComponents/helper';
import Invites from 'components/Invites/Invites';
import UpdateUsernamePopup from 'components/UpdateUsernamePopup/UpdateUsernamePopup';

import SuperAdminDashboard from 'pages/admin/dashboard/SuperAdminDashboard';
import RequesterPage from 'pages/agent/requesterPage/RequesterPage';
import SecurityPage from 'pages/common/security/SecurityPage';
import AccountSettingsPage from 'pages/common/account/AccountSettingsPage';
import NotFound from 'pages/start/404/NotFound';
import MyOrganization from 'pages/admin/myOrganization/MyOrganization';
import AgentDashboard from 'pages/agent/dashboard/AgentDashboard';
import AgentChatPage from 'pages/agent/chat/AgentChatPage';
import SavedFiltersPage from 'pages/agent/savedFilters/SavedFiltersPage';
import FormsList from 'pages/admin/formsList/FormsList';
import BoardsList from 'pages/admin/boardsList/BoardsList';
import LoginAttempts from 'pages/admin/loginAttempts/LoginAttempts';
import PasswordResetAttempts from 'pages/admin/passwordResetAttempts/PasswordResetAttempts';
import CreateFormComponent from 'pages/admin/createForm/CreateFormComponent';
import UpdateFormComponent from 'pages/admin/updateForm/UpdateFormComponent';
import UpdateWorkflowComponent from 'pages/admin/updateWorkflow/UpdateWorkflow';
import UpdateWorkflowStatusEventsComponent from 'pages/admin/updateWorkflowEvents/UpdateWorkflowStatusEventsComponent';
import CreateOrganizationComponent from 'pages/admin/createOrganization/CreateOrganizationComponent';
import UpdateOrganizationComponent from 'pages/admin/updateOrganization/UpdateOrganizationComponent';
import UpdateCaseAccessRequest from 'pages/admin/updateAccessRequest/UpdateAccessRequestComponent';
import InstanceSettings from 'pages/admin/instanceSettings/InstanceSettings';
import ActivityPage from 'pages/agent/activityPage/ActivityPage';
import GeneralTemplates from 'pages/admin/generalTemplate/GeneralTemplates';
import OrgUsers from 'pages/admin/orgUsers/OrgUsers';
import Roles from 'pages/admin/roles/Roles';
import CreateRole from 'pages/admin/createRole/CreateRole';
import UpdateRole from 'pages/admin/updateRole/UpdateRole';
import OrganizationPublicKeys from 'pages/admin/publicKeys/OrganizationPublicKeys';
import CaseAccessRequestsList from 'pages/admin/accessRequests/CaseAccessRequestsList';
import OrganizationTemplatesList from 'pages/admin/organizationTemplates/OrganizationTemplatesList';
import OrganizationPrivacyPolicy from 'pages/admin/organizationPrivacyPolicy/OrganizationPrivacyPolicy';
import ViewFormComponent from 'pages/admin/viewForm/ViewFormComponent';
import Workflows from 'pages/admin/workflows/Workflows';
import OrganizationsTiles from 'pages/admin/organizations/OrganizationList/OrganizationsTiles';
import BoardCasesComponent from 'pages/agent/blockView/BoardCasesComponent';
import BoardOverviewCasesComponent from 'pages/agent/tableView/RequestersList';
import FormVersionComponent from 'pages/admin/formVersion/FormVersion';
import CustomBoardCasesList from 'pages/agent/listView/CustomBoardCasesList';
import PanelsList from 'pages/admin/analytics/panels/PanelsList';
import DashboardsList from 'pages/admin/analytics/dashboards/DashboardsList';
import ResourceFieldsList from 'pages/admin/resourceFields/ResourceFieldsList';
import UpdateResourceFieldPage from 'pages/admin/updateResourceField/UpdateResourceFieldPage';
import UpdateResourceFieldCalendarPage from 'pages/admin/updateResourceField/calendar/UpdateResourceFieldCalendarPage';
import DashboardWithPanelsPage from 'pages/admin/analytics/dashboardPage/DashboardWithPanelsPage';
import DraftRemoves from 'pages/admin/draftRemoves/DraftRemoves';

import {ERROR_401} from 'services/http';

import {userRoles, PARAMETER_TAB, RULE_TYPE_IMPRINT, RULE_TYPE_PRIVACY_POLICY, routes} from 'config/index';

const AgentScreen: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const routerParams = useParams();

    const {
        currentTab,
        setCurrentTab,
        isSuperAdminPage,
        isSuperAdminDashboard,
        isOrganizationCreatePage,
        isOrganizationUpdatePage,
        isRolesListPage,
        isCreateRolePage,
        isUpdateRolePage,
        isAdminDashboard,
        isAgentDashboard,
        isAccountSettingsPage,
        isAgentChatPage,
        isAgentSavedFiltersPage,
        isSecurityPage,
        isRequesterPage,
        isRequesterCaseActivityPage,
        isCaseAccessRequestUpdatePage,
        setTopBarTitle,
        setBackLinkLabel,
        setBackTab,
        setBackLink,
        isFormCreatePage,
        isFormUpdatePage,
        isFormVersionPage,
        isFormViewPage,
        isWorkflowEditPage,
        isWorkflowStatusEventsPage,
        isBoardCasesPage,
        isBoardListCasesPage,
        isBoardOverviewCasesPage,
        isDashboardWithPanelsPage,
        setPageTitle,
        setAddLink,
        setPageBreadcrumbs,
        isAgentPage,
        isUpdateResourceFieldPage,
        isUpdateResourceFieldCalendarPage,
    } = useContext(RouteContext);

    const {
        admin: {organizationList, formsList, formInfo},
        errors: {generalError},
        profile: {profile},
        requestCase: {currentCase},
        workflow: {currentWorkflowStatus, organizationWorkflows, boards, currentBoardId},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const refreshErrors = useCallback(data => dispatch({type: SET_ERRORS.GENERAL_ERROR, payload: data}), [dispatch]);

    const myOrganization = organizationList && organizationList.length > 0 ? organizationList[0] : null;

    const toggleAdminMenu = (tabName: string, tab?: string) => {
        if (!String(generalError).includes(ERROR_401)) {
            refreshErrors(null);
        }
        if (tab) {
            setCurrentTab(tab);
            setSearchParams({
                [PARAMETER_TAB]: tab,
            });
        } else {
            setCurrentTab(tabName);
            setSearchParams({
                [PARAMETER_TAB]: tabName,
            });
        }
    };

    useEffect(() => {
        setCurrentTab(searchParams.get(PARAMETER_TAB) || ADMIN_DASHBOARD);
    }, [searchParams]);

    useEffect(() => {
        let breadcrumbs: BreadcrumbsType[];

        if (isWorkflowEditPage) {
            breadcrumbs = getBreadcrumbs(UPDATE_WORKFLOW, {
                isAgentPage,
                isSuperAdminPage,
                workflowName: organizationWorkflows.find(item => item.uuid === routerParams.uuid)?.title,
            });
        } else if (isWorkflowStatusEventsPage && currentWorkflowStatus) {
            breadcrumbs = getBreadcrumbs(UPDATE_WORKFLOW_STATUS_EVENTS, {
                isSuperAdminPage,
                isAgentPage,
                workflowId: currentWorkflowStatus?.workflowUuid,
                workflowName: organizationWorkflows.find(item => item.uuid === currentWorkflowStatus?.workflowUuid)
                    ?.title,
            });
        } else if (isFormUpdatePage) {
            const currentForm = formsList ? formsList.find(item => item.id === Number(routerParams.id)) : null;
            breadcrumbs = getBreadcrumbs(UPDATE_FORM, {isAgentPage, isSuperAdminPage, formName: currentForm?.title});
        } else if (isFormVersionPage) {
            const currentForm = formsList ? formsList.find(item => item.id === formInfo.id) : null;
            const currentVersion = formInfo?.versions.find(item => item.uuid === routerParams.versionUuid);
            breadcrumbs = getBreadcrumbs(FORM_VERSION, {
                isAgentPage,
                isSuperAdminPage,
                formId: formInfo?.id,
                formName: currentForm?.title,
                versionName: currentVersion?.title,
            });
        } else if (isDashboardWithPanelsPage) {
            breadcrumbs = getBreadcrumbs(PANELS_LIST, {isAgentPage, isSuperAdminPage});
        } else if (isOrganizationCreatePage || isOrganizationUpdatePage) {
            breadcrumbs = getBreadcrumbs(SUPER_ADMIN_ORGANIZATION_DETAILS, {isAgentPage, isSuperAdminPage});
        } else if (isRequesterPage) {
            const currentBoard = boards.find(item => item.uuid === currentBoardId) || boards[0];
            breadcrumbs = getBreadcrumbs(REQUESTER_CASE_FORM, {
                isAgentPage,
                isSuperAdminPage,
                board: currentBoard,
            });
        } else if (isRequesterCaseActivityPage) {
            breadcrumbs = getBreadcrumbs(REQUESTER_CASE_ACTIVITY, {
                isAgentPage,
                isSuperAdminPage,
                currentCase: currentCase,
            });
        } else {
            breadcrumbs = getBreadcrumbs(currentTab, {isAgentPage, isSuperAdminPage});
        }

        setPageBreadcrumbs(breadcrumbs);
    }, [
        routerParams,
        currentTab,
        isAgentPage,
        isSuperAdminPage,
        isWorkflowEditPage,
        currentWorkflowStatus,
        isFormUpdatePage,
        isFormVersionPage,
        formInfo,
        formsList,
        isDashboardWithPanelsPage,
        isOrganizationCreatePage,
        isOrganizationUpdatePage,
        isRequesterPage,
        isRequesterCaseActivityPage,
        currentBoardId,
        boards,
        currentCase,
    ]);

    const getStepContent = () => {
        setAddLink(null);
        if (isAccountSettingsPage) {
            return <AccountSettingsPage />;
        }
        if (isSecurityPage) {
            return <SecurityPage />;
        }
        if (isCreateRolePage) {
            setPageTitle(t('orguser.roles.createRole'));
            return <CreateRole />;
        }
        if (isUpdateRolePage) {
            setPageTitle(t('orguser.roles.updateRole'));
            return <UpdateRole />;
        }
        switch (true) {
            case profile?.roles?.includes(userRoles.ROLE_SUPER_ADMIN):
                if (isOrganizationCreatePage) {
                    setPageTitle(t('superadmin.organizations.addNewOrganization'));
                    return <CreateOrganizationComponent />;
                } else if (isOrganizationUpdatePage) {
                    setPageTitle(t('superadmin.organizations.updateOrganization'));
                    return <UpdateOrganizationComponent />;
                } else if (isRolesListPage) {
                    setPageTitle(t('common.menu.roles'));
                    return <Roles />;
                } else if (isSuperAdminDashboard && !currentTab) {
                    setPageTitle(t('common.menu.dashboard'));
                    return <SuperAdminDashboard />;
                }
                switch (currentTab) {
                    case SUPER_ADMIN_ORGANIZATIONS: {
                        setPageTitle(t('common.menu.organizations'));
                        setAddLink(routes.ADD_ORGANIZATION);
                        return <OrganizationsTiles />;
                    }
                    case SUPER_ADMIN_INSTANCE: {
                        setPageTitle(t('superadmin.instance.instanceSettings'));
                        return <InstanceSettings />;
                    }
                    case SUPER_ADMIN_FORMS: {
                        setPageTitle(t('common.menu.forms'));
                        return <FormsList />;
                    }
                    case SUPER_ADMIN_GENERAL_TEMPLATES: {
                        setPageTitle(t('common.menu.mailer'));
                        return <GeneralTemplates />;
                    }
                    case PUBLIC_KEYS_LIST: {
                        setPageTitle(t('common.menu.publicKeys'));
                        return <OrganizationPublicKeys />;
                    }
                    case SUPER_ADMIN_LOGIN_ATTEMPTS: {
                        setPageTitle(t('common.menu.loginAttempts'));
                        return <LoginAttempts />;
                    }
                    case SUPER_ADMIN_PASSWORD_RESET_ATTEMPTS: {
                        setPageTitle(t('common.menu.passwordResets'));
                        return <PasswordResetAttempts />;
                    }
                    case SUPER_ADMIN_DRAFT_REMOVES: {
                        setPageTitle(t('common.menu.draftRemoves'));
                        return <DraftRemoves />;
                    }
                    case SUPER_ADMIN_IMPRINT: {
                        return <RulesList type={RULE_TYPE_IMPRINT} />;
                    }
                    case SUPER_ADMIN_PRIVACY_POLICY: {
                        return <RulesList type={RULE_TYPE_PRIVACY_POLICY} />;
                    }
                }
                return <NotFound />;

            case profile?.roles?.includes(userRoles.ROLE_ADMIN):
            case profile?.roles?.includes(userRoles.ROLE_AGENT):
                if (isBoardListCasesPage) {
                    setBackTab('');
                    setPageTitle('');
                    return <CustomBoardCasesList />;
                }
                if (isBoardOverviewCasesPage) {
                    setBackTab('');
                    setPageTitle('');
                    return <BoardOverviewCasesComponent />;
                }
                if (isBoardCasesPage) {
                    setBackTab('');
                    setPageTitle('');
                    return <BoardCasesComponent />;
                }
                if (isFormCreatePage) {
                    return <CreateFormComponent />;
                }
                if (isFormUpdatePage) {
                    return <UpdateFormComponent />;
                }
                if (isFormVersionPage) {
                    return <FormVersionComponent />;
                }
                if (isFormViewPage) {
                    setPageTitle(t('orguser.forms.viewForm'));
                    return <ViewFormComponent />;
                }
                if (isWorkflowEditPage) {
                    setPageTitle(t('orguser.workflows.updateWorkflow'));
                    return <UpdateWorkflowComponent />;
                }
                if (isWorkflowStatusEventsPage) {
                    setPageTitle(t('orguser.workflows.updateWorkflowStatusEvents'));
                    return <UpdateWorkflowStatusEventsComponent />;
                }
                if (isCaseAccessRequestUpdatePage) {
                    setPageTitle(t('orguser.caseAccessRequests.updateCaseAccessRequest'));
                    return <UpdateCaseAccessRequest />;
                }
                if ((isAdminDashboard || isAgentDashboard) && !currentTab) {
                    setPageTitle(t('common.menu.dashboard'));
                    return <AgentDashboard />;
                }
                if (isRequesterPage) {
                    return <RequesterPage />;
                }
                if (isRequesterCaseActivityPage) {
                    return <ActivityPage />;
                }
                if (isAgentSavedFiltersPage) {
                    return <SavedFiltersPage />;
                }
                if (isAgentChatPage) {
                    setTopBarTitle(t('common.menu.messages'));
                    setBackLinkLabel('');
                    setBackLink('');
                    setBackTab('');
                    setPageTitle('');
                    return <AgentChatPage />;
                }
                if (isDashboardWithPanelsPage) {
                    setBackLinkLabel('');
                    setBackLink('');
                    setBackTab('');
                    return <DashboardWithPanelsPage />;
                }
                if (isUpdateResourceFieldCalendarPage) {
                    setPageTitle(
                        `${t('orguser.resourceFields.updateResourceField')} (${t(
                            'orguser.resourceFields.types.calendar',
                        )})`,
                    );
                    return <UpdateResourceFieldCalendarPage />;
                }
                if (isUpdateResourceFieldPage) {
                    setPageTitle(t('orguser.resourceFields.updateResourceField'));
                    return <UpdateResourceFieldPage />;
                }
                switch (currentTab) {
                    case ADMIN_MY_ORGANIZATION: {
                        setPageTitle(t('common.menu.myOrganization'));
                        return <MyOrganization />;
                    }
                    case ORG_USERS_LIST: {
                        setPageTitle(t('common.menu.organizationUsers'));
                        return <OrgUsers />;
                    }
                    case ORGANIZATION_POLICY: {
                        setPageTitle(t('common.menu.privacyPolicy'));
                        return <OrganizationPrivacyPolicy />;
                    }
                    case WORKFLOWS_LIST: {
                        setPageTitle(t('common.menu.workflows'));
                        return <Workflows />;
                    }
                    case ROLES_LIST: {
                        setPageTitle(t('common.menu.roles'));
                        return <Roles />;
                    }
                    case FORMS_LIST: {
                        setPageTitle(t('common.menu.forms'));
                        return <FormsList />;
                    }
                    case BOARDS_LIST: {
                        setPageTitle(t('common.menu.boards'));
                        return <BoardsList />;
                    }
                    case PUBLIC_KEYS_LIST: {
                        setPageTitle(t('common.menu.publicKeys'));
                        return <OrganizationPublicKeys />;
                    }
                    case ACCESS_REQUESTS_LIST: {
                        setPageTitle(t('common.menu.accessRequests'));
                        return <CaseAccessRequestsList />;
                    }
                    case TEMPLATES_LIST: {
                        setPageTitle(t('common.menu.mailer'));
                        return <OrganizationTemplatesList />;
                    }
                    case INVITES_LIST: {
                        setPageTitle(t('common.menu.invites'));
                        return <Invites />;
                    }
                    case PANELS_LIST: {
                        setPageTitle(t('common.menu.panels'));
                        return <PanelsList />;
                    }
                    case DASHBOARDS_LIST: {
                        setPageTitle(t('common.menu.dashboards'));
                        return <DashboardsList />;
                    }
                    case RESOURCE_FIELDS: {
                        setPageTitle(t('common.menu.resourceFields'));
                        return <ResourceFieldsList />;
                    }
                }
                return <NotFound />;

            default:
                if (profile) {
                    return <NotFound />;
                }
        }
        return <NotFound />;
    };

    const getCurrentModal = () => {
        if (profile && !profile.nickname && !profile.isSsoLogin) {
            return <UpdateUsernamePopup />;
        }
        if (!isSuperAdminPage && myOrganization && myOrganization.isForcedTwoFactor) {
            return <TwoFactorChecking />;
        }
        if (generalError) {
            return <GeneralErrorMessage />;
        }
        return null;
    };

    return (
        <AgentScreenWrapper setCurrentTab={setCurrentTab} toggleAdminMenu={toggleAdminMenu}>
            {getCurrentModal()}
            {!generalError && getStepContent()}
        </AgentScreenWrapper>
    );
};

export default AgentScreen;
