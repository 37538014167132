import {MutableRefObject, useCallback, useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import get from 'lodash/get';

import {RootReducer} from 'appRedux/reducers';
import {GET_CHAT_MESSAGES, GET_NEW_CHAT_MESSAGES, GET_PREV_CHAT_MESSAGES} from 'appRedux/actions/requestChat';
import {ChatMessageTypes} from 'appRedux/actions/requestChat/types';
import {GET_REQUESTER_CASE} from 'appRedux/actions/requestCase';

import {CHAT_MESSAGES_UPDATE_INTERVAL} from 'config/index';

interface UseLoadMessagesListType {
    wrapperRef: MutableRefObject<HTMLDivElement | null>;
}

const useLoadMessagesList = ({wrapperRef}: UseLoadMessagesListType) => {
    const dispatch = useDispatch();
    const {requestCase} = useParams();

    const getChatMessages = useCallback(data => dispatch({type: GET_CHAT_MESSAGES.REQUEST, payload: data}), [dispatch]);
    const getRequesterCaseInfo = useCallback(
        data => dispatch({type: GET_REQUESTER_CASE.REQUEST, payload: data}),
        [dispatch],
    );

    const getNewChatMessages = useCallback(
        data => dispatch({type: GET_NEW_CHAT_MESSAGES.REQUEST, payload: data}),
        [dispatch],
    );
    const getPrevChatMessages = useCallback(
        data => dispatch({type: GET_PREV_CHAT_MESSAGES.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        requestChat,
        requestCase: {
            currentCase: {id: caseId},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const messages: ChatMessageTypes = get(requestChat, 'messages', []);

    const newestMessageId: string = get(requestChat, 'newestMessageId', '');
    const oldestMessageId: string = get(requestChat, 'oldestMessageId', '');
    const loadingRef = useRef<boolean>();

    const lastScrollTop = useRef<number>();

    useEffect(() => {
        if (requestChat.allMessagesLoaded || !oldestMessageId) return;
        loadingRef.current = false;

        const scrollHandler = (e: any) => {
            if (loadingRef.current) {
                e.preventDefault();
                return;
            }

            const bondRect = wrapperRef.current?.getBoundingClientRect();

            const loadOnScrollTop =
                lastScrollTop.current && bondRect && bondRect.y > lastScrollTop.current && bondRect.y > 0;

            const loadOnAnyScroll = lastScrollTop.current && bondRect && bondRect.y > 100;

            if (loadOnScrollTop || loadOnAnyScroll) {
                loadingRef.current = true;

                getPrevChatMessages({
                    id: requestCase || caseId,
                    lastMessageUuid: oldestMessageId,
                });
            }

            lastScrollTop.current = bondRect ? bondRect.y : 0;
        };

        window.addEventListener('scroll', scrollHandler, true);

        return () => {
            window.removeEventListener('scroll', scrollHandler, true);
        };
    }, [requestChat.allMessagesLoaded, oldestMessageId, requestCase, caseId]);

    useEffect(() => {
        if (!requestCase && !caseId) return;

        getChatMessages({
            id: requestCase || caseId,
        });
        getRequesterCaseInfo({
            id: requestCase || caseId,
        });
    }, [requestCase, caseId]);

    useEffect(() => {
        if (!requestCase || !newestMessageId) return;

        const intervalId = setInterval(() => {
            newestMessageId &&
                getNewChatMessages({
                    id: requestCase,
                    lastMessageUuid: newestMessageId,
                });
        }, CHAT_MESSAGES_UPDATE_INTERVAL);

        return () => {
            clearInterval(intervalId);
        };
    }, [requestCase, newestMessageId]);

    return {messages};
};

export default useLoadMessagesList;
