import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import SetLanguage from 'components/SetLanguage/SetLanguage';

interface LanguageSwitcherType {
    isFormRegistrationPage?: boolean;
    isFormInviteRegistration?: boolean;
}

const LanguageSwitcher: FC<LanguageSwitcherType> = ({isFormRegistrationPage, isFormInviteRegistration}) => {
    const [t] = useTranslation();
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
                width: '100%',
                mt: 2,
                mb: 1,
            }}
        >
            <Typography sx={{mr: 1}}>{t('common.translateTo')}</Typography>
            <SetLanguage
                isFormRegistrationPage={isFormRegistrationPage}
                isFormInviteRegistration={isFormInviteRegistration}
            />
        </Box>
    );
};

export default LanguageSwitcher;
