import React, {FC, useContext, useEffect, useRef, useState} from 'react';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';

import {FieldOptionType} from 'appRedux/actions/forms/types';

import {ClientFormContext} from 'contexts/clientForm/context';

import {OPTIONS_SEPARATOR} from 'pages/admin/updateForm/partials/FormStructure/helper';
import FormSelectorWrapper from 'pages/client/form/wrappers/FormSelectorWrapper';
import {FormFieldPropsType} from 'pages/client/form/partials/types';
import {getInitialFieldValue} from 'pages/client/form/helper';
import ImageOption from 'pages/client/form/partials/ImageOption';

const FormImageCheck: FC<FormFieldPropsType> = ({
    formId,
    isPopup,
    pageId,
    sectionId,
    field,
    clientInformation,
    handleChangeInformation,
    onPopupFieldChange,
    disabled,
    popupContent,
    previewMode,
    onSaveClicked,
}) => {
    const ref = useRef<HTMLDivElement | undefined>();

    const {errorField, setErrorField} = useContext(ClientFormContext);

    const initialFileOptions = field.fieldOptions || [];

    const initialValue = getInitialFieldValue(pageId, sectionId, field.id, clientInformation, isPopup, popupContent);

    const [isInitialRender, setIsInitialRender] = useState<boolean>(true);
    const [isUpdated, setIsUpdated] = useState<boolean>(false);
    const [selectedOptions, setSelectedOptions] = useState<string[]>(
        initialValue ? initialValue.split(OPTIONS_SEPARATOR) : [],
    );

    const onCheckboxSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        const option = event.target.value;
        if (selectedOptions.includes(option)) {
            for (let i = 0, n = selectedOptions.length; i < n; i++) {
                if (selectedOptions[i] === option) {
                    selectedOptions.splice(i, 1);
                }
            }
        } else {
            setErrorField && setErrorField(null);
            selectedOptions.push(option);
        }
        const value = selectedOptions.join(OPTIONS_SEPARATOR);
        if (isPopup && onPopupFieldChange) {
            onPopupFieldChange(field.id, value);
        } else if (handleChangeInformation) {
            handleChangeInformation(pageId, sectionId, field.id, value);
        }
        setSelectedOptions(selectedOptions);
        setIsInitialRender(false);
        setIsUpdated(previous => !previous);
    };

    useEffect(() => {
        setSelectedOptions(initialValue ? initialValue.split(OPTIONS_SEPARATOR) : []);
    }, [initialValue]);

    useEffect(() => {
        if (field.id === errorField && ref && ref.current) {
            ref.current.scrollIntoView({behavior: 'smooth'});
        }
    }, [errorField]);

    useEffect(() => {
        if (!isInitialRender) {
            onSaveClicked();
        }
    }, [isUpdated]);

    return (
        <FormSelectorWrapper
            data-id={`input#form-selector-${formId}-${pageId}-${sectionId}-${field.id}`}
            wrapperRef={ref}
            field={field}
            formId={formId}
            previewMode={previewMode}
        >
            <FormGroup defaultValue={initialValue} onChange={onCheckboxSelect}>
                <Box sx={{display: 'flex', direction: 'row', gap: 0.5, flexWrap: 'wrap'}}>
                    {initialFileOptions.map((file: FieldOptionType, index: number) => {
                        const isChecked = selectedOptions.includes(String(file.uuid));
                        return (
                            <FormControlLabel
                                data-id={`input#form-selector-${formId}-${pageId}-${sectionId}-${field.id}-checkbox-${index}`}
                                key={`checkbox-${index}`}
                                disabled={disabled}
                                control={<Checkbox sx={{display: 'none'}} checked={isChecked} value={file.uuid} />}
                                label={<ImageOption file={file} isChecked={isChecked} />}
                            />
                        );
                    })}
                </Box>
            </FormGroup>
        </FormSelectorWrapper>
    );
};

export default FormImageCheck;
