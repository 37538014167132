import {
    PublicKeyOrganizationRemoveRequestTypes,
    PublicKeysForOtherAgentRequestTypes,
    PublicKeysOrganizationListRequestTypes,
    PublicKeyOrganizationTypes,
    PublicKeysForCaseKeysRequestTypes,
    PublicKeysForCaseKeysTypes,
    PublicKeyTypes,
    PublicKeyCreateRequestTypes,
    PublicKeyRemoveRequestTypes,
    PublicKeyUpdateRequestTypes,
    CaseKeysRequestTypes,
    CaseAccessRequestCreateType,
    CaseAccessRequestListType,
    CaseAccessRequestHandleType,
    CaseAccessRequestDeleteType,
    CaseAccessRequestTypes,
    AgentsWithCaseAccessRequestType,
    AgentsWithCaseAccessTypes,
    RegenerateCaseKeysType,
} from 'appRedux/actions/crypto/types';

import {requestTypeCreator} from 'helpers/index';

export const GET_ORGANIZATION_PUBLIC_KEYS = requestTypeCreator('GET_ORGANIZATION_PUBLIC_KEYS');
export const REMOVE_ORGANIZATION_PUBLIC_KEYS = requestTypeCreator('REMOVE_ORGANIZATION_PUBLIC_KEYS');

export const GET_PUBLIC_KEYS_FOR_CASE_KEYS_GENERATION = requestTypeCreator('GET_PUBLIC_KEYS_FOR_CASE_KEYS_GENERATION');
export const GET_PUBLIC_KEYS_FOR_OTHER_AGENT = requestTypeCreator('GET_PUBLIC_KEYS_FOR_OTHER_AGENT');

export const GET_CASE_KEYS = requestTypeCreator('GET_CASE_KEYS');
export const CREATE_CASE_KEY = requestTypeCreator('CREATE_CASE_KEY');

export const CREATE_PUBLIC_KEY = requestTypeCreator('CREATE_PUBLIC_KEY');
export const UPDATE_PUBLIC_KEY = requestTypeCreator('UPDATE_PUBLIC_KEY');
export const GET_USER_PUBLIC_KEYS = requestTypeCreator('GET_USER_PUBLIC_KEYS');
export const REMOVE_PUBLIC_KEY = requestTypeCreator('REMOVE_PUBLIC_KEY');

export const GET_AGENTS_WITH_CASE_ACCESS = requestTypeCreator('GET_AGENTS_WITH_CASE_ACCESS');
export const CREATE_CASE_ACCESS_REQUEST = requestTypeCreator('CREATE_CASE_ACCESS_REQUEST');
export const GET_CASE_ACCESS_REQUESTS = requestTypeCreator('GET_CASE_ACCESS_REQUESTS');
export const HANDLE_CASE_ACCESS_REQUEST = requestTypeCreator('HANDLE_CASE_ACCESS_REQUEST');
export const DELETE_CASE_ACCESS_REQUEST = requestTypeCreator('DELETE_CASE_ACCESS_REQUEST');
export const REGENERATE_CASE_KEYS = requestTypeCreator('REGENERATE_CASE_KEYS');

export const getOrganizationPublicKeys = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GET_ORGANIZATION_PUBLIC_KEYS.ERROR,
    }),
    request: (
        payload: PublicKeysOrganizationListRequestTypes,
    ): IActionType<PublicKeysOrganizationListRequestTypes> => ({
        payload,
        type: GET_ORGANIZATION_PUBLIC_KEYS.REQUEST,
    }),
    success: (payload: PublicKeyOrganizationTypes[]): IActionType<PublicKeyOrganizationTypes[]> => ({
        payload,
        type: GET_ORGANIZATION_PUBLIC_KEYS.SUCCESS,
    }),
};

export const removeOrganizationPublicKey = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: REMOVE_ORGANIZATION_PUBLIC_KEYS.ERROR,
    }),
    request: (
        payload: PublicKeyOrganizationRemoveRequestTypes,
    ): IActionType<PublicKeyOrganizationRemoveRequestTypes> => ({
        payload,
        type: REMOVE_ORGANIZATION_PUBLIC_KEYS.REQUEST,
    }),
    success: (payload: PublicKeyOrganizationTypes[]): IActionType<PublicKeyOrganizationTypes[]> => ({
        payload,
        type: REMOVE_ORGANIZATION_PUBLIC_KEYS.SUCCESS,
    }),
};

export const getAllPublicKeysForCaseKeysGeneration = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GET_PUBLIC_KEYS_FOR_CASE_KEYS_GENERATION.ERROR,
    }),
    request: (payload: PublicKeysForCaseKeysRequestTypes): IActionType<PublicKeysForCaseKeysRequestTypes> => ({
        payload,
        type: GET_PUBLIC_KEYS_FOR_CASE_KEYS_GENERATION.REQUEST,
    }),
    success: (payload: PublicKeysForCaseKeysTypes[]): IActionType<PublicKeysForCaseKeysTypes[]> => ({
        payload,
        type: GET_PUBLIC_KEYS_FOR_CASE_KEYS_GENERATION.SUCCESS,
    }),
};

export const getAllPublicKeysForOtherAgent = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GET_PUBLIC_KEYS_FOR_OTHER_AGENT.ERROR,
    }),
    request: (payload: PublicKeysForOtherAgentRequestTypes): IActionType<PublicKeysForOtherAgentRequestTypes> => ({
        payload,
        type: GET_PUBLIC_KEYS_FOR_OTHER_AGENT.REQUEST,
    }),
    success: (payload: PublicKeysForCaseKeysTypes[]): IActionType<PublicKeysForCaseKeysTypes[]> => ({
        payload,
        type: GET_PUBLIC_KEYS_FOR_OTHER_AGENT.SUCCESS,
    }),
};

export const getCaseKeys = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GET_CASE_KEYS.ERROR,
    }),
    request: (payload: CaseKeysRequestTypes): IActionType<CaseKeysRequestTypes> => ({
        payload,
        type: GET_CASE_KEYS.REQUEST,
    }),
    success: (payload: string[]): IActionType<string[]> => ({
        payload,
        type: GET_CASE_KEYS.SUCCESS,
    }),
};

export const createCaseKey = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: CREATE_CASE_KEY.ERROR,
    }),
    request: (payload: PublicKeysForCaseKeysRequestTypes): IActionType<PublicKeysForCaseKeysRequestTypes> => ({
        payload,
        type: CREATE_CASE_KEY.REQUEST,
    }),
    success: (payload: string[]): IActionType<string[]> => ({
        payload,
        type: CREATE_CASE_KEY.SUCCESS,
    }),
};

export const createPublicKey = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: CREATE_PUBLIC_KEY.ERROR,
    }),
    request: (payload: PublicKeyCreateRequestTypes): IActionType<PublicKeyCreateRequestTypes> => ({
        payload,
        type: CREATE_PUBLIC_KEY.REQUEST,
    }),
    success: (payload: PublicKeyTypes[]): IActionType<PublicKeyTypes[]> => ({
        payload,
        type: CREATE_PUBLIC_KEY.SUCCESS,
    }),
};

export const updatePublicKey = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: UPDATE_PUBLIC_KEY.ERROR,
    }),
    request: (payload: PublicKeyUpdateRequestTypes): IActionType<PublicKeyUpdateRequestTypes> => ({
        payload,
        type: UPDATE_PUBLIC_KEY.REQUEST,
    }),
    success: (payload: PublicKeyTypes[]): IActionType<PublicKeyTypes[]> => ({
        payload,
        type: UPDATE_PUBLIC_KEY.SUCCESS,
    }),
};

export const getUserPublicKeys = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GET_USER_PUBLIC_KEYS.ERROR,
    }),
    request: () => ({
        type: GET_USER_PUBLIC_KEYS.REQUEST,
    }),
    success: (payload: PublicKeyTypes[]): IActionType<PublicKeyTypes[]> => ({
        payload,
        type: GET_USER_PUBLIC_KEYS.SUCCESS,
    }),
};

export const removePublicKey = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: REMOVE_PUBLIC_KEY.ERROR,
    }),
    request: (payload: PublicKeyRemoveRequestTypes): IActionType<PublicKeyRemoveRequestTypes> => ({
        payload,
        type: REMOVE_PUBLIC_KEY.REQUEST,
    }),
    success: (payload: PublicKeyTypes[]): IActionType<PublicKeyTypes[]> => ({
        payload,
        type: REMOVE_PUBLIC_KEY.SUCCESS,
    }),
};

export const getAgentsWithCaseAccess = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GET_AGENTS_WITH_CASE_ACCESS.ERROR,
    }),
    request: (payload: AgentsWithCaseAccessRequestType): IActionType<AgentsWithCaseAccessRequestType> => ({
        payload,
        type: GET_AGENTS_WITH_CASE_ACCESS.REQUEST,
    }),
    success: (payload: AgentsWithCaseAccessTypes[]): IActionType<AgentsWithCaseAccessTypes[]> => ({
        payload,
        type: GET_AGENTS_WITH_CASE_ACCESS.SUCCESS,
    }),
};

export const createCaseAccessRequest = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: CREATE_CASE_ACCESS_REQUEST.ERROR,
    }),
    request: (payload: CaseAccessRequestCreateType): IActionType<CaseAccessRequestCreateType> => ({
        payload,
        type: CREATE_CASE_ACCESS_REQUEST.REQUEST,
    }),
    success: () => ({
        type: CREATE_CASE_ACCESS_REQUEST.SUCCESS,
    }),
};

export const getCaseAccessRequests = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GET_CASE_ACCESS_REQUESTS.ERROR,
    }),
    request: (payload: CaseAccessRequestListType): IActionType<CaseAccessRequestListType> => ({
        payload,
        type: GET_CASE_ACCESS_REQUESTS.REQUEST,
    }),
    success: (payload: CaseAccessRequestTypes[]): IActionType<CaseAccessRequestTypes[]> => ({
        payload,
        type: GET_CASE_ACCESS_REQUESTS.SUCCESS,
    }),
};

export const handleCaseAccessRequest = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: HANDLE_CASE_ACCESS_REQUEST.ERROR,
    }),
    request: (payload: CaseAccessRequestHandleType): IActionType<CaseAccessRequestHandleType> => ({
        payload,
        type: HANDLE_CASE_ACCESS_REQUEST.REQUEST,
    }),
    success: (payload: CaseAccessRequestTypes[]): IActionType<CaseAccessRequestTypes[]> => ({
        payload,
        type: HANDLE_CASE_ACCESS_REQUEST.SUCCESS,
    }),
};

export const deleteCaseAccessRequest = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: DELETE_CASE_ACCESS_REQUEST.ERROR,
    }),
    request: (payload: CaseAccessRequestDeleteType): IActionType<CaseAccessRequestDeleteType> => ({
        payload,
        type: DELETE_CASE_ACCESS_REQUEST.REQUEST,
    }),
    success: (payload: CaseAccessRequestTypes[]): IActionType<CaseAccessRequestTypes[]> => ({
        payload,
        type: DELETE_CASE_ACCESS_REQUEST.SUCCESS,
    }),
};

export const regenerateCaseKeys = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: REGENERATE_CASE_KEYS.ERROR,
    }),
    request: (payload: RegenerateCaseKeysType): IActionType<RegenerateCaseKeysType> => ({
        payload,
        type: REGENERATE_CASE_KEYS.REQUEST,
    }),
    success: (payload: PublicKeyTypes[]): IActionType<PublicKeyTypes[]> => ({
        payload,
        type: REGENERATE_CASE_KEYS.SUCCESS,
    }),
};
