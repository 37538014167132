import React, {FC} from 'react';
import {useSelector} from 'react-redux';
import get from 'lodash/get';

import CircularProgress from '@mui/material/CircularProgress';

import {RootReducer} from 'appRedux/reducers';

import mobileLogo from 'assets/images/mobileLogo.png';

import CustomImage from 'components/CustomImage/CustomImage';

interface MainLogoType {
    withBorderRadius?: boolean;
    navBar?: boolean;
    isMobileMenu?: boolean;
    maxWidth?: number;
    maxHeight?: number;
}

const MainLogo: FC<MainLogoType> = ({withBorderRadius, maxWidth, maxHeight, navBar, isMobileMenu}) => {
    const {
        auth: {isOrganizationInfoLoading, organization},
        instance: {files},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    if (isOrganizationInfoLoading) {
        return <CircularProgress />;
    }

    const instanceLogo = get(files, 'logo', null);
    const organizationNavBarLogo = get(organization, navBar ? 'navBarLogo' : 'mainLogo', null);

    const logo = organizationNavBarLogo ? organizationNavBarLogo : instanceLogo;

    if (logo) {
        return (
            <CustomImage
                imageSrc={logo}
                snippetImage={withBorderRadius}
                isMobileMenu={isMobileMenu}
                maxWidth={maxWidth}
                maxHeight={maxHeight}
            />
        );
    }
    return <img src={mobileLogo} style={{maxWidth: '100%'}} alt={'Herupu logo'} />;
};

export default MainLogo;
