import React, {FC, useContext, useEffect, useState} from 'react';
import get from 'lodash/get';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import {RootReducer} from 'appRedux/reducers';
import {FormSectionTypes} from 'appRedux/actions/forms/types';
import {ClientTranslationUpdatedType} from 'appRedux/actions/clientTranslation/types';
import {RequesterCaseItemType} from 'appRedux/actions/requestCase/types';
import {RequesterSignatureResponseType} from 'appRedux/actions/profile/types';

import {CaseKeyContext} from 'contexts/caseKey/context';

import DescriptionPopover from 'components/DescriptionPopover/DescriptionPopover';

import {
    CONTENT_FIELD,
    CONTENT_POPUP,
    FORM_FIELD_CHECKBOXES,
    FORM_FIELD_DATE_RANGE,
    FORM_FIELD_DROPDOWN,
    FORM_FIELD_IMAGE_CHECK,
    FORM_FIELD_IMAGE_RADIO,
    FORM_FIELD_RADIOBUTTONS,
    FORM_FIELD_SIGNATURE,
    FORM_FIELD_UPLOADER,
    SectionContentType,
} from 'pages/admin/updateForm/partials/FormStructure/helper';
import InformationWrapper from 'pages/agent/requesterPage/wrappers/InformationWrapper';
import UploadedFilesContent from 'pages/agent/requesterPage/sections/UploadedFilesContent';
import PopupInformation from 'pages/agent/requesterPage/sections/PopupInformation';
import InformationWrapperWithTranslation from 'pages/agent/requesterPage/wrappers/InformationWrapperWithTranslation';
import {ContentItemType} from 'pages/client/form/partials/types';

import {decryptStringWithKey} from 'helpers/cryptoApiHelper';
import {getFieldKeyword, getPopupKeyword, getFormTranslatedLabel} from 'helpers/translationsHelper';
import getFieldMaxValue from 'helpers/getFieldMaxValue';

import {EMPTY_DATA} from 'config/index';

interface SectionContentItemType {
    formId: number;
    showTranslation: boolean;
    editTranslation: boolean;
    clientTranslations?: ClientTranslationUpdatedType;
    setClientTranslations?: (value: ClientTranslationUpdatedType) => void;
    content: RequesterCaseItemType;
    item: SectionContentType;
    section: FormSectionTypes;
}

const SectionContentItem: FC<SectionContentItemType> = ({
    formId,
    item,
    content,
    clientTranslations,
    setClientTranslations,
    showTranslation,
    editTranslation,
    section,
}) => {
    const [t] = useTranslation();

    const {unwrappedCaseKey} = useContext(CaseKeyContext);

    const [text, setText] = useState<string>('-');

    const {
        admin: {
            formInfo: {translations},
        },
        requestCase: {
            currentCase: {files, signatures, isEncryptInfo},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const {fields, popups, id} = section;

    const field = get(fields, item.index, null);
    const popup = get(popups, item.index, null);

    useEffect(() => {
        const timer = setTimeout(async () => {
            if (content && field) {
                let value = String(get(content, `field-${field.id}`, EMPTY_DATA));
                if (isEncryptInfo && unwrappedCaseKey) {
                    value = await decryptStringWithKey(value, unwrappedCaseKey);
                }
                setText(value);
            }
        }, 200);
        return () => clearTimeout(timer);
    }, [content]);

    if (item.type === CONTENT_FIELD) {
        if (!field) return null;
        const keywordTitle = getFieldKeyword(formId, field.id, 'label');
        const keywordDescription = getFieldKeyword(formId, field.id, 'description');
        if (field.type === FORM_FIELD_UPLOADER) {
            return (
                <UploadedFilesContent
                    formId={formId}
                    field={field}
                    keywordTitle={keywordTitle}
                    keywordDescription={keywordDescription}
                    files={files}
                    sectionId={id}
                />
            );
        }
        if ([FORM_FIELD_DROPDOWN, FORM_FIELD_CHECKBOXES, FORM_FIELD_RADIOBUTTONS].includes(fields[item.index]?.type)) {
            const selectedOptions = field.fieldOptions
                ? field.fieldOptions?.filter(file => text.includes(file.title))
                : [];
            return (
                <InformationWrapper
                    title={getFormTranslatedLabel(translations, keywordTitle, field.label)}
                    description={getFormTranslatedLabel(translations, keywordDescription, field.label)}
                >
                    {selectedOptions.map(option => (
                        <Box sx={{width: '100%'}} key={option.id}>
                            - {option.title}
                            {option.text && <DescriptionPopover text={option.text} />}
                        </Box>
                    ))}
                </InformationWrapper>
            );
        }
        if ([FORM_FIELD_IMAGE_CHECK, FORM_FIELD_IMAGE_RADIO].includes(fields[item.index]?.type)) {
            const selectedFiles = field.fieldOptions
                ? field.fieldOptions?.filter(file => text.includes(file.uuid))
                : [];
            return (
                <InformationWrapper
                    title={getFormTranslatedLabel(translations, keywordTitle, field.label)}
                    description={getFormTranslatedLabel(translations, keywordDescription, field.label)}
                >
                    {selectedFiles.map(option => (
                        <Box sx={{width: '100%'}} key={option.id}>
                            - {option.title}
                        </Box>
                    ))}
                </InformationWrapper>
            );
        }
        if (field.type === FORM_FIELD_SIGNATURE) {
            const fieldSignatures = signatures.filter(
                (signature: RequesterSignatureResponseType) => signature.field === field.id && signature.section === id,
            );
            return (
                <InformationWrapper
                    title={getFormTranslatedLabel(translations, keywordTitle, field.label)}
                    description={getFormTranslatedLabel(translations, keywordDescription, field.description)}
                >
                    {fieldSignatures.map(signature => (
                        <Box sx={{mb: 1, height: '50px'}} key={signature.uuid}>
                            <img src={signature.fileLink || ''} style={{maxHeight: '100%', maxWidth: '100%'}} />
                        </Box>
                    ))}
                </InformationWrapper>
            );
        }
        const maxValue = getFieldMaxValue(field.type, field.options);

        let formattedText = text.replace(/<[^>]*>/g, '') + (maxValue || '');

        if (field.type === FORM_FIELD_DATE_RANGE) {
            formattedText = formattedText.replace('&', ' - ');
        }

        return (
            <InformationWrapperWithTranslation
                sectionId={id}
                field={field}
                showTranslation={showTranslation}
                editTranslation={editTranslation}
                clientTranslations={clientTranslations}
                setClientTranslations={setClientTranslations}
                title={getFormTranslatedLabel(translations, keywordTitle, field.label)}
                description={getFormTranslatedLabel(translations, keywordDescription, field.description)}
                currentValue={text}
            >
                <Typography>{formattedText}</Typography>
            </InformationWrapperWithTranslation>
        );
    }
    if (item.type === CONTENT_POPUP) {
        if (!popup) return null;
        const keywordTitle = getPopupKeyword(formId, popup.id, 'title');
        const keywordDescription = getFieldKeyword(formId, popup.id, 'description');
        const popupContent = get(content, `popup-${popup.id}`, []);
        const items: ContentItemType[] = Array.isArray(popupContent) ? popupContent : [];
        return (
            <InformationWrapper
                title={getFormTranslatedLabel(translations, keywordTitle, popup.title)}
                description={getFormTranslatedLabel(translations, keywordDescription, popup.description)}
            >
                <PopupInformation
                    formId={formId}
                    sectionId={id}
                    popup={popup}
                    items={items}
                    showTranslation={showTranslation}
                    editTranslation={editTranslation}
                    clientTranslations={clientTranslations}
                    setClientTranslations={setClientTranslations}
                />
            </InformationWrapper>
        );
    }
    return null;
};

export default SectionContentItem;
