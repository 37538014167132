import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import {GridColDef} from '@mui/x-data-grid';

import {GET_RESOURCE_FIELDS_LIST} from 'appRedux/actions/resourceFields';
import {GET_WORKFLOWS_LIST} from 'appRedux/actions/workflow';
import {RootReducer} from 'appRedux/reducers';

import {AdminContext} from 'contexts/admin/context';
import {AlertContext} from 'contexts/alert/context';
import {RouteContext} from 'contexts/route/context';

import DataGridWrapper from 'components/AdminScreenComponents/DataGridWrapper';
import {CustomDataGrid} from 'components/AdminScreenComponents/CustomDataGrid';

import getResourceFieldsColumns from 'pages/admin/resourceFields/getResourceFieldsColumns';
import CreateResourceField from 'pages/admin/resourceFields/CreateResourceField';

const ResourceFieldsList: FC = () => {
    const dispatch = useDispatch();

    const {myOrganization} = useContext(AdminContext);
    const {showAlert} = useContext(AlertContext);
    const {refreshPageInformation} = useContext(RouteContext);

    const {
        resourceFields: {resourceFields},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const getResourceFieldsList = useCallback(
        data => dispatch({type: GET_RESOURCE_FIELDS_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    const getWorkflowsList = useCallback(
        data => dispatch({type: GET_WORKFLOWS_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    const columns: GridColDef[] | null = getResourceFieldsColumns();

    useEffect(() => {
        refreshPageInformation();
        if (myOrganization) {
            getResourceFieldsList({
                id: myOrganization.id,
                showAlert,
            });
            getWorkflowsList({
                organizationId: myOrganization.id,
                showAlert,
            });
        }
    }, []);

    if (!myOrganization) return null;

    return (
        <Box>
            <CreateResourceField organizationId={myOrganization.id} />
            <DataGridWrapper itemsList={resourceFields} itemsTotalNumber={resourceFields ? resourceFields.length : 0}>
                {resourceFields && columns && (
                    <CustomDataGrid
                        rows={resourceFields}
                        columns={columns}
                        getRowId={row => row.uuid}
                        checkboxSelection={false}
                        enablePaging
                    />
                )}
            </DataGridWrapper>
        </Box>
    );
};

export default ResourceFieldsList;
