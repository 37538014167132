import * as yup from 'yup';

import {DEFAULT_LANGUAGE_ID} from 'config/index';

export const MINUTES_LOGOUT_IDLE_TIME = 15;

export const createOrganizationInitialValues = {
    logo: '',
    name: '',
    email: '',
    address: '',
    description: '',
    logoutIdleTime: MINUTES_LOGOUT_IDLE_TIME,
    isActive: false,
    isClosed: false,
    isEncryptionPossible: false,
    isInviteRegistration: false,
    isExportAllowed: false,
    isGoogleTranslationAllowed: false,
    isMultipleRequestPossible: false,
    isForcedTwoFactor: false,
    isRequesterEmailDisplayingAllowed: false,
    isRequesterModeEnabled: false,
    isAutoLoginSso: false,
    isShowFormsList: false,
    language: DEFAULT_LANGUAGE_ID,
};

export const CreateOrganizationValidationSchema = yup.object({
    name: yup.string().required('messages.validation.organizationNameIsRequired'),
    email: yup.string().email().required('messages.validation.emailRequired'),
    logoutIdleTime: yup
        .number()
        .required()
        .min(10, 'messages.validation.organizationLogoutIdleTimeMinimal')
        .max(60, 'messages.validation.organizationLogoutIdleTimeMaximal'),
});

export const organizationTranslationsValidationSchema = yup.object({
    name: yup.string().required('messages.validation.isRequired'),
    description: yup.string().required('messages.validation.isRequired'),
});
