import React, {FC, useContext, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';

import {PublicKeyTypes} from 'appRedux/actions/crypto/types';
import {RootReducer} from 'appRedux/reducers';

import {CryptoContext} from 'contexts/crypto/context';

import UpdatePublicKeyButton from 'components/PublicKeysList/UpdatePublicKeyButton';
import DeletePublicKeyButton from 'components/PublicKeysList/DeletePublicKeyButton';
import ShareAccessToKeyButton from 'components/PublicKeysList/ShareAccessToKeyButton';
import ExportKeyButton from 'components/PublicKeysList/ExportKeyButton';

import {exportPublicKey} from 'helpers/cryptoApiHelper';

interface PublicKeysRowActionsType {
    item: PublicKeyTypes;
}

const PublicKeysRowActions: FC<PublicKeysRowActionsType> = ({item}) => {
    const {keys} = useContext(CryptoContext);

    const {
        auth: {organization: currentOrganization},
        crypto: {currentUserPublicKeys},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const [isCurrentBrowserKey, setIsCurrentBrowserKey] = useState<boolean>(true);
    const [currentBrowserKey, setCurrentBrowserKey] = useState<string>('');

    const {data, caseKeys} = item;

    useEffect(() => {
        if (keys) {
            const fetchData = async () => {
                const browserPublicKey = await exportPublicKey(keys.publicKey);
                setCurrentBrowserKey(browserPublicKey);
                setIsCurrentBrowserKey(browserPublicKey === data);
            };
            fetchData().catch(console.error);
        }
    }, [keys]);

    const isCurrentOrgKey = item.organization === currentOrganization?.name;

    const hasAllCases = useMemo(() => {
        if (!currentBrowserKey || !isCurrentOrgKey) return true;
        const caseKeysIds = caseKeys?.map(key => key.caseId);
        const current = currentUserPublicKeys?.find(pubKey => pubKey.data === currentBrowserKey);
        return !current?.caseKeys?.find(key => !caseKeysIds?.includes(key.caseId));
    }, [caseKeys, currentUserPublicKeys, currentBrowserKey, isCurrentOrgKey]);

    return (
        <>
            {!isCurrentBrowserKey && !hasAllCases && (
                <ShareAccessToKeyButton item={item} currentBrowserKey={currentBrowserKey} />
            )}
            {isCurrentBrowserKey && <ExportKeyButton item={item} />}
            <UpdatePublicKeyButton item={item} />
            <DeletePublicKeyButton item={item} isCurrentBrowserKey={isCurrentBrowserKey} />
        </>
    );
};

export default PublicKeysRowActions;
