import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {Document, Page, View, Text} from '@react-pdf/renderer';

import {FormPageTypes, FormTranslationItemTypes} from 'appRedux/actions/forms/types';
import {RequesterCaseItemType, RequesterCaseTypes} from 'appRedux/actions/requestCase/types';

import PagePdfContent from 'pages/agent/pdfExport/pdfPartials/PagePdfContent';
import AttachmentsPagePdfContent from 'pages/agent/pdfExport/pdfPartials/AttachmentsPagePdfContent';
import {getRequesterCaseTitle} from 'pages/agent/print/helper';
import {pdfStyles} from 'pages/agent/pdfExport/pdfPartials/PdfDocumentStyles';
import PageHeader from 'pages/agent/pdfExport/pdfPartials/PageHeader';
import PageFooter from 'pages/agent/pdfExport/pdfPartials/PageFooter';
import {PrintSectionType} from 'pages/agent/pdfExport/PdfEditor';

import {OrganizationItemBasic} from 'appRedux/actions/organization/types';
import {PdfDocumentContext} from './pdfDocumentContext';

interface PdfDocumentType {
    pages: FormPageTypes[];
    currentCase: RequesterCaseTypes;
    fileImages: Record<string, string | string[]>;
    printSections: Record<string, Record<string, PrintSectionType>>;
    printAttachments: Record<string, boolean>;
    organization: OrganizationItemBasic | null;
    result: RequesterCaseItemType;
    formId?: string;
    preview?: boolean;
    translations: FormTranslationItemTypes[];
}

const PdfDocument: FC<PdfDocumentType> = ({
    pages,
    currentCase,
    formId,
    fileImages,
    printSections,
    preview,
    organization,
    printAttachments,
    result,
    translations,
}) => {
    const [t] = useTranslation();

    const {userName, userEmail, status, createdAt, updatedAt, agentName, formTitle, caseLabel} = currentCase;

    return (
        <PdfDocumentContext.Provider value={{translations, preview, currentCase}}>
            <Document title={getRequesterCaseTitle(t, userName, userEmail)}>
                <Page size="A4" style={pdfStyles.page}>
                    <PageHeader text={organization?.pdfExportHeader || ''} />
                    <View style={pdfStyles.title}>
                        <Text>{caseLabel}</Text>
                    </View>
                    <View style={pdfStyles.field}>
                        <View style={pdfStyles.fieldTitle}>
                            <Text>{`${t('orguser.requesterCase.form')}:`}</Text>
                        </View>
                        <View style={pdfStyles.fieldValue}>
                            <Text>{formTitle}</Text>
                        </View>
                    </View>
                    <View style={pdfStyles.field}>
                        <View style={pdfStyles.fieldTitle}>
                            <Text>{`${t('orguser.requesterCase.agent')}:`}</Text>
                        </View>
                        <View style={pdfStyles.fieldValue}>
                            <Text>{agentName || '-'}</Text>
                        </View>
                    </View>
                    <View style={pdfStyles.field}>
                        <View style={pdfStyles.fieldTitle}>
                            <Text>{`${t('orguser.requesterCase.user')}:`}</Text>
                        </View>
                        <View style={pdfStyles.fieldValue}>
                            <Text>{userName}</Text>
                        </View>
                    </View>
                    <View style={pdfStyles.field}>
                        <View style={pdfStyles.fieldTitle}>
                            <Text>{`${t('orguser.requesterCase.created')}:`}</Text>
                        </View>
                        <View style={pdfStyles.fieldValue}>
                            <Text>{createdAt}</Text>
                        </View>
                    </View>
                    <View style={pdfStyles.field}>
                        <View style={pdfStyles.fieldTitle}>
                            <Text>{`${t('orguser.requesterCase.updated')}:`}</Text>
                        </View>
                        <View style={pdfStyles.fieldValue}>
                            <Text>{updatedAt}</Text>
                        </View>
                    </View>
                    <View style={pdfStyles.field}>
                        <View style={pdfStyles.fieldTitle}>
                            <Text>{`${t('orguser.requesterCase.status')}:`}</Text>
                        </View>
                        <View style={pdfStyles.fieldValue}>
                            <Text>{status}</Text>
                        </View>
                    </View>
                    <PageFooter text={organization?.pdfExportFooter || ''} />
                </Page>
                {pages.map((page: FormPageTypes, index: number) => {
                    if (!printSections[page.id] || Object.values(printSections[page.id]).every(section => !section))
                        return <></>;

                    return (
                        <Page size="A4" style={pdfStyles.page} key={`page-${index}`}>
                            <PageHeader text={organization?.pdfExportHeader || ''} />
                            <PagePdfContent
                                pageNumber={index + 1}
                                page={page}
                                result={result}
                                formId={formId}
                                printSections={printSections[page.id]}
                            />
                            <PageFooter text={organization?.pdfExportFooter || ''} />
                        </Page>
                    );
                })}
                {printAttachments && !Object.values(printAttachments).every(att => !att) && (
                    <AttachmentsPagePdfContent fileImages={fileImages} printAttachments={printAttachments} />
                )}
            </Document>
        </PdfDocumentContext.Provider>
    );
};

export default PdfDocument;
