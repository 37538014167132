import React, {FC, ReactNode} from 'react';
import {useSelector} from 'react-redux';
import get from 'lodash/get';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';

import {RootReducer} from 'appRedux/reducers';

import TopBar from 'components/ClientScreenComponents/TopBar';

import {CLIENT_BACKGROUND_COLOR} from 'config/theme';

interface ClientFormWrapperType {
    children: ReactNode;
    chat?: boolean;
}

const ClientFormWrapper: FC<ClientFormWrapperType> = ({children, chat}) => {
    const {profile, requestCase} = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const profileLoading = get(profile, 'isLoading', false);
    const requestCaseLoading = get(requestCase, 'isLoading', false);

    return (
        <Box
            sx={{
                backgroundColor: CLIENT_BACKGROUND_COLOR,
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
                ...(chat ? {height: 'auto', minHeight: 0, flexGrow: 1} : {}),
            }}
        >
            <TopBar />
            {chat ? (
                children
            ) : (
                <Grid container sx={{flexGrow: 1, overflowY: 'auto'}}>
                    <Grid item sm={2}></Grid>
                    <Grid item sm={8}>
                        {children}
                    </Grid>
                    <Grid item sm={2}></Grid>
                </Grid>
            )}
            {(profileLoading || requestCaseLoading) && (
                <Modal
                    open={true}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        overflow: 'scroll',
                    }}
                >
                    <CircularProgress />
                </Modal>
            )}
        </Box>
    );
};

export default ClientFormWrapper;
