import React, {FC, ReactNode, useState} from 'react';
import {useSelector} from 'react-redux';
import get from 'lodash/get';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';

import {RootReducer} from 'appRedux/reducers';

import TopBarMobile from 'components/ClientScreenComponents/_mobile/TopBarMobile';
import FooterMobile from 'components/ClientScreenComponents/_mobile/FooterMobile';
import ClientMobileMenu from 'components/ClientScreenComponents/_mobile/ClientMobileMenu';

import {CLIENT_BACKGROUND_COLOR} from 'config/theme';

interface ClientFormMobileWrapperType {
    children: ReactNode;
    chat?: boolean;
}

const ClientFormMobileWrapper: FC<ClientFormMobileWrapperType> = ({children, chat}) => {
    const {profile, requestCase} = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);

    const toggleMenu = () => {
        setIsMenuOpened(previous => !previous);
    };

    const profileLoading = get(profile, 'isLoading', false);
    const requestCaseLoading = get(requestCase, 'isLoading', false);

    if (isMenuOpened) {
        return <ClientMobileMenu toggleMenu={toggleMenu} />;
    }
    return (
        <Box
            sx={{
                height: '100vh',
                overflowY: 'auto',
                overflowX: 'hidden',
                width: '100vw',
                ...(chat ? {height: 'auto', minHeight: 0, flexGrow: 1} : {}),
            }}
        >
            <TopBarMobile isMenuOpened={isMenuOpened} toggleMenu={toggleMenu} />
            <Box
                sx={{
                    backgroundColor: CLIENT_BACKGROUND_COLOR,
                    p: chat ? 0 : 2,
                    minHeight: chat ? 0 : '100vh',
                }}
            >
                {children}
                {(profileLoading || requestCaseLoading) && (
                    <Modal
                        open={true}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            overflow: 'scroll',
                        }}
                    >
                        <CircularProgress />
                    </Modal>
                )}
            </Box>
            {!chat && <FooterMobile />}
        </Box>
    );
};

export default ClientFormMobileWrapper;
