import React, {FC, useCallback, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import {GET_PUBLIC_KEYS_FOR_CASE_KEYS_GENERATION, CREATE_CASE_KEY} from 'appRedux/actions/crypto';
import {PublicKeysForCaseKeysTypes, CaseKeysGeneratedTypes} from 'appRedux/actions/crypto/types';

import {AlertContext} from 'contexts/alert/context';

import {generateCryptoKey, importPublicKey, wrapKey} from 'helpers/cryptoApiHelper';

import {routes} from 'config/index';

const CaseKeysGeneration: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {requestCase, form} = useParams();

    const {showAlert} = useContext(AlertContext);

    const [encodedKeys, setEncodedKey] = useState<CaseKeysGeneratedTypes[]>([]);

    const getPublicKeys = useCallback(
        data => dispatch({type: GET_PUBLIC_KEYS_FOR_CASE_KEYS_GENERATION.REQUEST, payload: data}),
        [dispatch],
    );

    const createCaseKeys = useCallback(data => dispatch({type: CREATE_CASE_KEY.REQUEST, payload: data}), [dispatch]);

    const generateItemKey = async (caseKey: CryptoKey, publicKey: PublicKeysForCaseKeysTypes) => {
        try {
            const {id, data} = publicKey;

            const importedPublicKey = await importPublicKey(data);
            const wrappedCaseKey = await wrapKey(caseKey, importedPublicKey);

            encodedKeys.push({
                public: id,
                data: wrappedCaseKey,
            });

            setEncodedKey(encodedKeys);
        } catch (e) {
            console.log(e);
        }
    };

    const generateCaseKeys = async (publicKeys: PublicKeysForCaseKeysTypes[]) => {
        const caseKey = await generateCryptoKey();
        for (const publicKey of publicKeys) {
            await generateItemKey(caseKey, publicKey);
        }

        createCaseKeys({
            id: Number(requestCase),
            caseKeys: encodedKeys,
            showAlert,
            callback: () => {
                navigate(`${routes.REQUEST}/${requestCase}/form/${form}`);
            },
        });
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            getPublicKeys({
                showAlert,
                callback: generateCaseKeys,
            });
        }, 2000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '100vh',
            }}
        >
            <CircularProgress />
            <Typography sx={{mt: 2, fontWeight: 600}}>{t('common.registrationForm.caseKeysGenerating')}</Typography>
        </Box>
    );
};

export default CaseKeysGeneration;
