import React, {FC, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';

import {RequesterAllCaseItemTypes} from 'appRedux/actions/requestCase/types';

import {CaseKeyContext} from 'contexts/caseKey/context';

import {getAllUserFiles} from 'components/FilesListWithGallery/helper';
import FilesListWithGallery from 'components/FilesListWithGallery/FilesListWithGallery';

import {getFilesLabel} from 'pages/client/requests/helper';
import {CommonFileType} from 'pages/agent/requesterPage/partials/helper';

interface FilesListType {
    isCurrentOrganizationCase: boolean;
    currentCase: RequesterAllCaseItemTypes;
}

const FilesList: FC<FilesListType> = ({isCurrentOrganizationCase, currentCase}) => {
    const [t] = useTranslation();
    const {unwrappedCaseKey} = useContext(CaseKeyContext);

    const {files, chatFiles} = currentCase;

    const [isOpened, setIsOpened] = useState<boolean>(false);
    const [filesList, setFilesList] = useState<CommonFileType[]>([]);

    const toggleIsOpened = () => {
        if (isCurrentOrganizationCase) {
            setIsOpened(previous => !previous);
        }
    };

    const filesLabel = getFilesLabel(t, files, chatFiles);

    useEffect(() => {
        const timer = setTimeout(async () => {
            const allFiles: CommonFileType[] = await getAllUserFiles(currentCase, unwrappedCaseKey);
            setFilesList(allFiles);
        }, 200);
        return () => clearTimeout(timer);
    }, [currentCase]);

    return (
        <Box>
            <Box
                data-id={`button#requests-files-toggle`}
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    cursor: 'pointer',
                    mt: 2,
                }}
                onClick={toggleIsOpened}
            >
                <Typography sx={{fontSize: 11}}>{filesLabel}</Typography>
                {isOpened ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </Box>
            {isOpened && (
                <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: 2, flexWrap: 'wrap'}}>
                    <FilesListWithGallery
                        caseId={Number(currentCase.requestCase.id)}
                        allFiles={filesList}
                        deleteNotPossible={true}
                    />
                </Box>
            )}
        </Box>
    );
};

export default FilesList;
