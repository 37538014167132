import React, {FC, useCallback, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useSearchParams} from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';

import {LanguageItemTypes} from 'appRedux/actions/admin/types';
import {WorkflowStatusItemTypes, WorkflowItemTypes} from 'appRedux/actions/workflow/types';
import {RootReducer} from 'appRedux/reducers';
import {AGENT_LIST} from 'appRedux/actions/admin';
import {GET_WORKFLOWS_STATUS_LIST} from 'appRedux/actions/workflow';

import {AlertContext} from 'contexts/alert/context';

import WorkflowStatusesListRow from 'pages/admin/updateWorkflow/WorkflowStatusesListRow';
import AddWorkflowStatus from 'pages/admin/updateWorkflow/partials/AddWorkflowStatus';
import WorkflowGraph from 'pages/admin/updateWorkflow/partials/WorkflowGraph';

import {PARAMETER_LANGUAGE} from 'config/index';

interface WorkflowStatusesListType {
    currentWorkflow: WorkflowItemTypes;
    currentOrganizationDefaultLanguage: LanguageItemTypes;
}

const WorkflowStatusesList: FC<WorkflowStatusesListType> = ({currentWorkflow, currentOrganizationDefaultLanguage}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();

    const {uuid} = currentWorkflow;

    const {showAlert} = useContext(AlertContext);

    const [dragItem, setDragItem] = useState<string>('');
    const [dropTo, setDropTo] = useState<number>(0);

    const {
        workflow: {workflowStatusesList},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const getAgents = useCallback(() => dispatch({type: AGENT_LIST.REQUEST}), [dispatch]);

    const getWorkflowStatuses = useCallback(
        data => dispatch({type: GET_WORKFLOWS_STATUS_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    const currentLanguage = searchParams.get(PARAMETER_LANGUAGE)
        ? searchParams.get(PARAMETER_LANGUAGE)
        : currentOrganizationDefaultLanguage.name;

    useEffect(() => {
        getWorkflowStatuses({
            uuid,
            showAlert,
        });
    }, [uuid]);

    useEffect(() => {
        getAgents();
    }, []);

    return (
        <Box>
            <WorkflowGraph uuid={uuid} currentLanguage={String(currentLanguage)} />
            <Table sx={{mt: 2, mb: 2}}>
                <TableHead>
                    <TableCell></TableCell>
                    <TableCell>
                        <Typography sx={{fontWeight: 600}}>
                            {`${t('orguser.workflows.workflowStatusParameters.title')} / ${t(
                                'orguser.workflows.workflowStatusParameters.titleRequester',
                            )}`}
                        </Typography>
                    </TableCell>
                    <TableCell width={70}></TableCell>
                    <TableCell width={200}>
                        <Typography sx={{fontWeight: 600}}>
                            {t('orguser.workflows.workflowStatusParameters.action')}
                        </Typography>
                    </TableCell>
                </TableHead>
                <TableBody>
                    {workflowStatusesList &&
                        workflowStatusesList.map((item: WorkflowStatusItemTypes) => {
                            return (
                                <WorkflowStatusesListRow
                                    item={item}
                                    key={`workflow-status-${item.uuid}`}
                                    currentLanguage={String(currentLanguage)}
                                    dragItem={dragItem}
                                    setDragItem={setDragItem}
                                    dropTo={dropTo}
                                    setDropTo={setDropTo}
                                    currentOrganizationDefaultLanguage={currentOrganizationDefaultLanguage}
                                />
                            );
                        })}
                </TableBody>
            </Table>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    pt: 3,
                }}
            >
                <AddWorkflowStatus uuid={uuid} currentLanguage={String(currentLanguage)} />
            </Box>
        </Box>
    );
};

export default WorkflowStatusesList;
