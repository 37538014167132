import {OPTIONS_SEPARATOR} from 'pages/admin/updateForm/partials/FormStructure/helper';

export const OPTION_ERROR_SEMICOLON = 1;
export const OPTION_ERROR_MIN_TWO_OPTIONS = 2;
export const OPTION_ERROR_MIN_ONE_OPTION = 3;
export const OPTION_ERROR_REPEAT = 4;
export const OPTION_ERROR_HTML_TAG = 5;
export const OPTION_ERROR_MIN_LESS_MAX = 6;
export const OPTION_ERROR_BOTH_RANGES = 7;

export const getOptionsListError = (optionsErrorType: number | null): string => {
    switch (optionsErrorType) {
        case OPTION_ERROR_SEMICOLON:
            return 'orguser.forms.formField.validation.semicolonValidation';
        case OPTION_ERROR_MIN_TWO_OPTIONS:
            return 'orguser.forms.formField.validation.minTwoOptionsValidation';
        case OPTION_ERROR_MIN_ONE_OPTION:
            return 'orguser.forms.formField.validation.minOneOptionValidation';
        case OPTION_ERROR_REPEAT:
            return 'orguser.forms.formField.validation.optionsRepeatValidation';
        case OPTION_ERROR_HTML_TAG:
            return 'orguser.forms.formField.validation.htmlTagsDenied';
        case OPTION_ERROR_MIN_LESS_MAX:
            return 'orguser.forms.formField.validation.minLessThanMax';
        case OPTION_ERROR_BOTH_RANGES:
            return 'orguser.forms.formField.validation.bothRangesRequired';
    }
    return '';
};

export const getOptionsArray = (optionsString: string): string[] => {
    if (!optionsString) {
        return [];
    }
    if (optionsString.includes(OPTIONS_SEPARATOR)) {
        return optionsString.split(OPTIONS_SEPARATOR);
    }
    return [optionsString];
};
