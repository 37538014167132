import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';

import EditSvgIcon from 'assets/icons/buttons/EditSvgIcon';

import {BoardItemTypes} from 'appRedux/actions/workflow/types';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import EditBoardForm from 'components/Forms/BoardForm/EditBoardForm';
import EditBoardListStatusForm from 'components/Forms/BoardForm/EditBoardListStatusForm';
import {BOARD_TYPE_BOARD, BOARD_TYPE_LIST, BOARD_TYPE_OVERVIEW} from 'components/Forms/BoardForm/helper';
import BoardTypeBoardStatusesList from 'components/Forms/BoardForm/BoardTypeBoardStatusesList';
import UpdateOverviewBoardForm from 'components/Forms/BoardForm/UpdateOverviewBoardForm';

interface EditBoardButtonType {
    item: BoardItemTypes;
}

const EditBoardButton: FC<EditBoardButtonType> = ({item}) => {
    const [t] = useTranslation();

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    const {type} = item;

    return (
        <>
            <IconButton title={t('common.buttons.update')} onClick={toggleIsOpened}>
                <EditSvgIcon />
            </IconButton>
            <ModalWrapper
                title={t('orguser.boards.updateBoard')}
                isShowModal={isOpened}
                toggleModal={toggleIsOpened}
                isWideModal={type === BOARD_TYPE_OVERVIEW}
            >
                {type === BOARD_TYPE_OVERVIEW ? (
                    <UpdateOverviewBoardForm item={item} closeModal={() => setIsOpened(false)} />
                ) : (
                    <>
                        <EditBoardForm item={item} />
                        <Divider sx={{mt: 2, mb: 1, borderWidth: 1}} />
                        {type === BOARD_TYPE_BOARD && (
                            <BoardTypeBoardStatusesList item={item} toggleIsOpened={toggleIsOpened} />
                        )}
                        {type === BOARD_TYPE_LIST && <EditBoardListStatusForm item={item} />}
                    </>
                )}
            </ModalWrapper>
        </>
    );
};

export default EditBoardButton;
