import React, {FC, Ref, useCallback, useContext, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import {CREATE_WORKFLOW_STATUS_EVENTS} from 'appRedux/actions/workflow';
import {WorkflowStatusEventFormTypes} from 'appRedux/actions/workflow/types';

import {AdminContext} from 'contexts/admin/context';
import {AlertContext} from 'contexts/alert/context';

import WorkflowStatusEventForm from 'components/Forms/Workflow/workflowStatusEvent/WorkflowStatusEventForm';

import {DEFAULT_LANGUAGE_ID} from 'config/index';

interface CreateWorkflowStatusEventFormType {
    formRef: Ref<any> | null;
    type: number;
    workflowStatusUuid: string;
    closeModal: () => void;
    forEmailTemplate?: boolean;
    forWebhookTemplate?: boolean;
}

const CreateWorkflowStatusEventForm: FC<CreateWorkflowStatusEventFormType> = ({
    formRef,
    type,
    workflowStatusUuid,
    closeModal,
    forEmailTemplate,
    forWebhookTemplate,
}) => {
    const dispatch = useDispatch();

    const {myOrganization} = useContext(AdminContext);
    const {showAlert} = useContext(AlertContext);

    const [language, setLanguage] = useState<number>(DEFAULT_LANGUAGE_ID);
    const [codeMirrorText, setCodeMirrorText] = useState<string>('');

    const createWorkflowStatusEvents = useCallback(
        data => dispatch({type: CREATE_WORKFLOW_STATUS_EVENTS.REQUEST, payload: data}),
        [dispatch],
    );

    const onSubmitClicked = (values: WorkflowStatusEventFormTypes) => {
        createWorkflowStatusEvents({
            ...values,
            text: forWebhookTemplate ? codeMirrorText : values.text,
            language,
            uuid: workflowStatusUuid,
            showAlert,
            callback: closeModal,
        });
    };

    useEffect(() => {
        if (myOrganization) {
            setLanguage(myOrganization.language);
        }
    }, [myOrganization]);

    return (
        <WorkflowStatusEventForm
            formRef={formRef}
            initialValues={{
                type,
                language,
                subject: '',
                text: '',
                url: '',
            }}
            onSubmitClicked={onSubmitClicked}
            language={language}
            setLanguage={setLanguage}
            translations={[]}
            forEmailTemplate={forEmailTemplate}
            forWebhookTemplate={forWebhookTemplate}
            codeMirrorText={codeMirrorText}
            setCodeMirrorText={setCodeMirrorText}
        />
    );
};

export default CreateWorkflowStatusEventForm;
