import React, {FC, useContext, useEffect, useRef, useState} from 'react';
import get from 'lodash/get';
import {useSelector} from 'react-redux';

import Select, {SelectChangeEvent} from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import {RootReducer} from 'appRedux/reducers';

import {ClientFormContext} from 'contexts/clientForm/context';

import {FormFieldPropsType} from 'pages/client/form/partials/types';
import {getInitialFieldValue} from 'pages/client/form/helper';
import FormSelectorWrapper from 'pages/client/form/wrappers/FormSelectorWrapper';

import {getFormTranslatedLabel, getOptionKeyword, ignoreHtmlInString} from 'helpers/translationsHelper';

const FormSelector: FC<FormFieldPropsType> = ({
    formId,
    isPopup,
    pageId,
    sectionId,
    field,
    clientInformation,
    handleChangeInformation,
    popupContent,
    onPopupFieldChange,
    disabled,
    previewMode,
    onSaveClicked,
}) => {
    const {
        admin: {
            formInfo: {translations},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const ref = useRef<HTMLDivElement | undefined>();

    const {errorField, setErrorField} = useContext(ClientFormContext);

    const [isInitialRender, setIsInitialRender] = useState<boolean>(true);
    const [isUpdated, setIsUpdated] = useState<boolean>(false);

    const options = field.fieldOptions || [];
    const initialOptions = options.map(opt => opt.title);
    const keywordsOptions = options.map(opt => getOptionKeyword(formId, opt.id, opt.text ? 'text' : 'title'));
    const translatedOptions = keywordsOptions.map((keyword, i) =>
        getFormTranslatedLabel(translations, keyword, options[i].title),
    );

    const handleChange = (e: SelectChangeEvent) => {
        setErrorField && setErrorField(null);
        if (handleChangeInformation) {
            handleChangeInformation(pageId, sectionId, field.id, e.target.value);
            setIsInitialRender(false);
            setIsUpdated(previous => !previous);
        }
    };

    const handlePopupFieldChange = (e: SelectChangeEvent) => {
        setErrorField && setErrorField(null);
        if (onPopupFieldChange) {
            onPopupFieldChange(field.id, e.target.value);
            setIsInitialRender(false);
            setIsUpdated(previous => !previous);
        }
    };

    useEffect(() => {
        if (field.id === errorField && ref && ref.current) {
            ref.current.scrollIntoView({behavior: 'smooth'});
        }
    }, [errorField]);

    useEffect(() => {
        if (!isInitialRender) {
            onSaveClicked();
        }
    }, [isUpdated]);

    return (
        <FormSelectorWrapper
            data-id={`input#form-selector-${formId}-${pageId}-${sectionId}-${field.id}`}
            wrapperRef={ref}
            field={field}
            formId={formId}
            previewMode={previewMode}
        >
            <Select
                sx={{mb: 2}}
                disabled={disabled}
                variant="standard"
                value={getInitialFieldValue(pageId, sectionId, field.id, clientInformation, isPopup, popupContent)}
                fullWidth
                onChange={isPopup ? handlePopupFieldChange : handleChange}
            >
                {initialOptions.map((option: string, index: number) => {
                    const translatedValue = get(translatedOptions, index, null);
                    const value = !previewMode && translatedValue ? ignoreHtmlInString(translatedValue) : option;
                    return (
                        <MenuItem
                            key={`menuItem-${index}`}
                            value={option}
                            data-id={`input#form-selector-${formId}-${pageId}-${sectionId}-${field.id}-${index}`}
                        >
                            <Typography>{value}</Typography>
                        </MenuItem>
                    );
                })}
            </Select>
        </FormSelectorWrapper>
    );
};

export default FormSelector;
