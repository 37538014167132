import React, {FC} from 'react';
import get from 'lodash/get';

import {BarChart, BarChartProps} from '@mui/x-charts/BarChart';
import Box from '@mui/material/Box';

import {PanelItemsTypes} from 'appRedux/actions/analytics/types';

import CustomItemTooltip from 'pages/admin/analytics/charts/CustomCheckboxStatisticTooltip';
import {getCheckboxAnalyticSeriesArray} from 'pages/admin/analytics/charts/helper';

interface BarCheckboxAnalysisChartType {
    item: PanelItemsTypes;
}

const BarCheckboxAnalysisChart: FC<BarCheckboxAnalysisChartType> = ({item}) => {
    const {cases} = item;

    const groupsArray: string[] = [];
    let maximalSeriesLength = 0;

    cases.forEach((caseItem: any) => {
        const fieldLabel = get(caseItem, 'field', null);
        const options = get(caseItem, 'options', []);
        if (fieldLabel) {
            groupsArray.push(fieldLabel);
        }
        if (options.length > maximalSeriesLength) {
            maximalSeriesLength = options.length;
        }
    });

    const seriesArray = getCheckboxAnalyticSeriesArray(cases, maximalSeriesLength);

    const chartSettingsV: Partial<BarChartProps> = {
        margin: {top: 15, bottom: 30, left: 70},
        slotProps: {
            legend: {
                hidden: true,
            },
        },
    };

    return (
        <Box style={{height: '600px', width: '100%'}}>
            <BarChart
                xAxis={[{scaleType: 'band', data: groupsArray}]}
                series={seriesArray}
                {...chartSettingsV}
                tooltip={{
                    trigger: 'axis',
                }}
                slots={{
                    axisContent: values => <CustomItemTooltip cases={cases} {...values} />,
                }}
            />
        </Box>
    );
};

export default BarCheckboxAnalysisChart;
